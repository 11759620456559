import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Order } from 'projects/common/src/lib/order';
import { Merchant } from 'projects/common/src/lib/merchant';
import { LocalStorage, StorageMap } from '@ngx-pwa/local-storage';
import { ShoppingCartService } from 'projects/services/src/lib/shopping-cart.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  @Input() order: Order;
  @Input() merchant: Merchant;
  @Input() brandname: string;

  public paymentDetailsTitle = 'Show Payment Details';

  constructor(public router: Router, public shoppingCartService: ShoppingCartService, public localStorage: StorageMap) {
    console.log('receipt application');
  }

  ngOnInit() {
    // console.log(this.merchant);
  }

  public clearOrder(): void {
    try {
      this.localStorage.delete('cart').subscribe(async () => {
        await this.shoppingCartService.clearItems();
      });
    } catch (error) {

    }
  }

  public paymentDetailToggle(): void {
    if (this.paymentDetailsTitle === 'Show Payment Details') {
      this.paymentDetailsTitle = 'Hide Payment Details';
    } else {
      this.paymentDetailsTitle = 'Show Payment Details';
    }
  }

  public displayMap(): void {
    try {
      this.router.navigate(['/mobile-item-detail']);
    } catch (error) {
      console.log(error);
    }
  }

  public goHome(): void {
    this.clearOrder();
    this.router.navigate(['/' + this.merchant.routeName]);
  }

}
