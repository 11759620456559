import { Component, OnDestroy, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subscription } from 'rxjs';
import { finalize, first, map } from 'rxjs/operators';
import { Category, CatItem } from 'projects/common/src/lib/category';
import { Item } from 'projects/common/src/lib/item';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Option } from 'projects/common/src/lib/option';
import { ISchedule, ScheduleType, StoreCartModel } from 'projects/common/src/lib/store-cart.model';
import { LogIn } from 'projects/common/src/lib/login';
import { User } from 'projects/common/src/lib/user';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { ItemService } from 'projects/services/src/lib/item.service';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { StoreCartService } from 'projects/services/src/lib/store-cart.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from '../registration-dialog/registration-dialog.component';
import { ScheduleDialogComponent, IScheduleDialogData } from '../schedule-dialog/schedule-dialog.component';
import { DateTimeFormatOptions } from 'luxon';
import { Location } from '@angular/common';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { OrderType } from 'projects/common/src/lib/ordertype';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { ItemDetailsModalComponent } from '../item-details-modal/item-details-modal.component';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';
import moment from 'moment';
import { CategoryService } from 'projects/services/src/lib/category.service';
import { ConfigService } from 'projects/services/src/lib/config.service';
import * as $ from "jquery";

interface ItemCacheRecord {
  categoryid: string;
  category: string;
  items: Item[];
}

interface ItemCache {
  list: CatItem;
  expires: number;
}

const WINDOWBREAKPOINT = 960;
const KEYS = {
  CATITEMKEY: 'CATEGORYITEMS',
};

@Component({
  selector: 'app-store-cart',
  templateUrl: './store-cart.component.html',
  styleUrls: ['./store-cart.component.scss'],
})
export class StoreCartComponent implements OnInit, OnDestroy {

  private itemCache: ItemCacheRecord[] = [];
  private itemCountSub: Subscription;
  private userSub: Subscription;
  private merchantSubscription: Subscription;
  screenHeight = 0;
  screenWidth = 0;
  displayCart = true;
  settingsLink = '';
  feeType = 'Checkout Fee';

  sidenavObservable: Observable<boolean>;
  sidenavSubscription: Subscription;
  @Output() sidenavState = false;
  @Output() hideCart = new EventEmitter<boolean>();

  orderOptions: OrderType[] = [];

  public sidenavOptions = {
    sidenavMode: 'side',
    disableClose: true,
    backdrop: false,
  };
  public user: User;
  public merchantInfo: Merchant;
  public categories: Category[];
  public selectedCategory: string;
  public selectedSubCategory: Category;
  public categoryItems: ItemCacheRecord[];
  public searchText: string;
  public merchantAddress: string;
  public CategoryButtonClicked = true;
  public cartModel: StoreCartModel;
  public authCheck: boolean;
  public resizeSidenav = true;
  public showToolbar = false;
  public windowWidth: number;
  public confirmRef: any;
  public item: Item;
  public itemIdx: number;
  public updateFlag = false;
  public scheduleText: string;
  public modalItem: Item = new Item();
  public closeTime: number;

  private showDialog = false;
  public defaultOperationTime = [];
  public waittime = 0;

  public timer: any;
  public maxAmount = 0;

  // new for checkout page:
  public isCheckout = false;
  public orderTypes = [];
  public defaultOrderType = {
    text: '',
    value: null,
  };

  public totalWithoutTip = 0;
  public stringTotalWithoutTip = "";

  disableCheckoutButton = false;

  locations: Merchant[] = [];
  authConfig: any;

  constructor(
    private _aRoute: ActivatedRoute,
    private router: Router,
    private _merchantSvc: MerchantService,
    private _categorySvc: CategoryService,
    private _itemSvc: ItemService,
    private _cartSvc: StoreCartService,
    public authSvc: AuthenticationService,
    private _loginDialog: MatDialog,
    private passwordRestRequestDialog: MatDialog,
    private _registrationDialog: MatDialog,
    private _scheduleDialog: MatDialog,
    private _confirmDialog: MatDialog,
    private localStorage: StorageMap,
    private dialog: MatDialog,
    public sideNavService: SideNavService,
    private location: Location,
    private configService: ConfigService,
  ) {
    this.isCheckout = this.router.url.endsWith('/checkout');

    this.merchantSubscription = this._merchantSvc.merchantEmitter.subscribe((merchant: Merchant) => {
      this.selectedMerchant(merchant);
    });

    this.sidenavSubscription = this.sideNavService.state.subscribe((state: boolean) => {
      this.sidenavState = state;

      if (!state) {
        const sidebar = document.getElementById('sidebar');
        if (sidebar && sidebar.classList) {
          sidebar.classList.remove('active');
        }
      }
    });
  }

  @HostListener('window:resize', ['event'])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    const storename = this._aRoute.snapshot.params.storename;
    if (storename) {
      this.settingsLink = '/' + storename + '/merchant-settings';

      this.localStorage.get('merchant').subscribe(async (merchant: any) => {
        this.merchantInfo = merchant;
        this.localStorage.get(this.merchantInfo._id).subscribe(async (model: StoreCartModel) => {
          this.cartModel = model;
          // if (merchant) {
          //   this.merchantInfo = merchant;
          // } else {
          //   this._LoadMerchant(storename);
          // }
          this._LoadMerchant(storename);

          this.userSub = this.authSvc.CurrentUserEmitter.subscribe(async (u: User) => {
            this.user = u;
            if (!u.isGuestLogin) {
              this.authCheck = await this.authSvc.isAuthenticated(); // (u.access_token) ? true : false;
            }
          });

          this.itemCountSub = this._cartSvc.StoreCartModelEmitter.subscribe((model: StoreCartModel) => {
            const selectedOrderType = this.orderOptions.find((o) => o.value === model.deliverytype);
            if(!selectedOrderType){
              model.deliverytype = this.defaultOrderType.value;
              model.deliverytypetext = this.defaultOrderType.text;
              this._cartSvc.UpdateDeliveryType(model.deliverytype);
            }
            this.cartModel = model;

            this.cartModel.itemList.forEach(item => {
              if (item.ratioGraphic && item.ratioGraphic.toLocaleLowerCase() !== 'none') {
                item.wholeMods = [];
                item.wholeMods = item.wholeMods.concat(item.sides.filter(s => s.ratio === 0 || s.ratio === -99 || s.ratio === null));
                item.wholeMods = item.wholeMods.concat(item.options.filter(o => o.ratio === 0 || o.ratio === -99 || o.ratio === null));
                item.wholeMods = item.wholeMods.concat(item.modifiers.filter(m => m.ratio === 0 || m.ratio === -99 || m.ratio === null));

                item.rightMods = [];
                item.rightMods = item.rightMods.concat(item.sides.filter(s => s.ratio === 1));
                item.rightMods = item.rightMods.concat(item.options.filter(o => o.ratio === 1));
                item.rightMods = item.rightMods.concat(item.modifiers.filter(m => m.ratio === 1));

                item.leftMods = [];
                item.leftMods = item.leftMods.concat(item.sides.filter(s => s.ratio === -1));
                item.leftMods = item.leftMods.concat(item.options.filter(o => o.ratio === -1));
                item.leftMods = item.leftMods.concat(item.modifiers.filter(m => m.ratio === -1));
                // console.log(item);
              }
            });

            if(this.cartModel && !this.cartModel.deliverytype) {
              this.cartModel.deliverytype = this.defaultOrderType.value;
              this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);
            }

            if (!this.isCheckout) {
              this.totalWithoutTip = parseFloat(this.cartModel.subtotal.Text.replace("$", "")) +
                parseFloat(this.cartModel.deliveryfee.Text.replace("$", "")) +
                parseFloat(this.cartModel.conveniencefee.Text.replace("$", "")) +
                parseFloat(this.cartModel.servicefee.Text.replace("$", "")) +
                parseFloat(this.cartModel.tax.Text.replace("$", ""));

              this.stringTotalWithoutTip = this.totalWithoutTip.toString();
              /*             if (this.merchantInfo.rmsType === 3) {
                            this.stringTotalWithoutTip = '$' + this.totalWithoutTip.toFixed(2);
                          } else if (this.stringTotalWithoutTip.length - this.stringTotalWithoutTip.indexOf(".") - 1 !== 2) {
                            this.stringTotalWithoutTip = '$' + this.totalWithoutTip.toFixed(2);
                          } */
            }

            // this.stringTotalWithoutTip = this.totalWithoutTip.toString();
            if (this.merchantInfo.rmsType === 3) {
              this.stringTotalWithoutTip = this.totalWithoutTip.toFixed(2);
            } else if (this.stringTotalWithoutTip.length - this.stringTotalWithoutTip.indexOf(".") - 1 !== 2) {
              this.stringTotalWithoutTip = this.totalWithoutTip.toFixed(2);
            }

            if (this.merchantInfo && this.cartModel.subtotal.Value < this.merchantInfo.settings.minorderamt && this.showDialog) {
              const confirmRef2 = this._confirmDialog.open(ConfirmDialogComponent, {
                data: { message: `Your order is below the Minimum Order Amount. Please add more item to continue!`, text: '', yesno: false, okonly: true },
              });

              confirmRef2.afterClosed().subscribe(() => {
                this.router.navigate([this.merchantInfo.routeName]).catch((e) => { });
              });
            }

            this.showDialog = false;

            this._formatSchedule();
          });

          this.user = this.authSvc.CurrentUser;
          this.authCheck = await this.authSvc.isAuthenticated();

          if (!this.authCheck) {
            await this.GuestLogin().catch((error: any) => {
              console.log(error);
            });

            this.user = this.authSvc.CurrentUser;
            this.authCheck = await this.authSvc.isAuthenticated();
          }

          if (this.authCheck) {
            this.configService.getConfig('authentication').subscribe(async (config: any) => {
              if (config) {
                this.authConfig = config;
              }
            }, (error: any) => {
              console.log(error);
            });
          }
        });
      });
    } else {
      this.router.navigate(['/pagenotfound']);
      return;
    }
  }

  ngOnDestroy() {
    this.itemCountSub.unsubscribe();
    this.userSub.unsubscribe();
    this.sidenavSubscription.unsubscribe();

    if (this.merchantSubscription) {
      this.merchantSubscription.unsubscribe();
    }

    clearInterval(this.timer);
    // window.removeEventListener('resize', this.WindowResizeCheck);
  }

  public async GuestLogin(): Promise<User> {
    return new Promise((resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authSvc.guestLogin(credentials).subscribe(async (data) => {

          if (data && data.access_token) {
            this.user = data;
            // this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            this.localStorage.set('token', data.access_token).subscribe(() => { });
            await this.authSvc.SetAuthStateAsync(this.user);
            resolve(this.user);
          } else {
            // this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  public closeShoppingCart(): void {
    try {
      this.sideNavService.close();
    } catch (error) {
      console.log(error);
    }
  }

  public openMerchantAdmin(): void {
    try {
      console.log('openMerchantAdmin: ' + this.settingsLink);
      this.router.navigate([this.settingsLink]).catch((e) => {
        console.log(e);
      });
    } catch (error) {
      console.log('openMerchantAdmin: ' + error.message);
    }
  }

  selectedMerchant(merchant: Merchant): void {
    try {
      // if (merchant && merchant._id) {
      // create the cart for this merchant if it doesn't already exist, and resolve the tips!
      // this._cartSvc.CreateCart(merchant, this.isCheckout);

      this.merchantInfo = merchant;
      this.maxAmount = parseFloat(merchant.settings.tipSettings.maxAmount);

      if (this.merchantInfo.settings.CustomerServiceFeeOn) {
        this.feeType = 'Checkout Fee';
      } else {
        this.feeType = 'Convenience Fee';
      }
      this.settingsLink = '/' + merchant.routeName + '/merchant-settings';
      // this.loadDefaultOrderType();
      this.orderOptions = merchant.orderOptions.filter((o) => o.enabled === true && o.suspended !== true);
      this.orderOptions.sort((a, b) => a.name > b.name ? 1 : -1)
      this.orderTypes = merchant.orderOptions.filter((o) => {
        if (o.enabled && !o.suspended) {
          if (o.value === OrderTypeEnum.Delivery) {
            this.defaultOrderType.text = o.desc;
            this.defaultOrderType.value = o.value;

            this.defaultOperationTime = merchant.settings.deliveryHours;
            this.waittime = merchant.settings.delWaittime ? merchant.settings.delWaittime : 0;
          } else {
            this.defaultOrderType.text = o.desc;
            this.defaultOrderType.value = o.value;

            this.defaultOperationTime = merchant.settings.pickupHours;
            this.waittime = merchant.settings.pickupWaittime ? merchant.settings.pickupWaittime : 0;
          }
          return o;
        }
      });

      if(this.cartModel && !this.cartModel.deliverytype) {
        this.cartModel.deliverytype = this.defaultOrderType.value;
        this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);
      }

      // format address:
      this.merchantAddress = ([
        this.merchantInfo.address,
        this.merchantInfo.city,
        this.merchantInfo.state].filter((s) => {
          return s ? true : false;
        }).join(', ') + ` ${this.merchantInfo.zip}`).toUpperCase();

      this._cartSvc.GetCart();
      // this._LoadAllItems();
      // } else {
      // no merchant found, redirect to... 404?
      /*       this.router.navigate(['/pagenotfound']).catch(e => {

            }); */
      // }

    } catch (error) {
      console.log(error);
    }
  }

  private _LoadMerchant(storename: string) {
    this._merchantSvc.getMerchantByRoute(storename).pipe(first()).subscribe((r: Merchant) => {
      if (r && r[0]._id) {
        // create the cart for this merchant if it doesn't already exist, and resolve the tips!
        this._cartSvc.CreateCart(r[0], this.isCheckout);

        this.merchantInfo = r[0];
        this.maxAmount = parseFloat(this.merchantInfo.settings.tipSettings.maxAmount);

        if (this.merchantInfo.settings.CustomerServiceFeeOn) {
          this.feeType = 'Checkout Fee';
        } else {
          this.feeType = 'Convenience Fee';
        }

        this.settingsLink = '/' + r[0].routeName + '/merchant-settings';

        this.orderOptions = r[0].orderOptions.filter((o) => o.enabled === true && o.suspended !== true);
        this.orderTypes = r[0].orderOptions.filter((o) => {
          if (o.enabled && !o.suspended) {
            if (o.value === OrderTypeEnum.Delivery) {
              this.defaultOrderType.text = o.desc;
              this.defaultOrderType.value = o.value;

              this.defaultOperationTime = r[0].settings.deliveryHours;
              this.waittime = r[0].settings.delWaittime ? r[0].settings.delWaittime : 0;
            } else {
              this.defaultOrderType.text = o.desc;
              this.defaultOrderType.value = o.value;

              this.defaultOperationTime = r[0].settings.pickupHours;
              this.waittime = r[0].settings.pickupWaittime ? r[0].settings.pickupWaittime : 0;
            }
            return o;
          }
        });

        this.cartModel.deliverytype = this.defaultOrderType.value;
        this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);

        // format address:
        this.merchantAddress = ([
          this.merchantInfo.address,
          this.merchantInfo.city,
          this.merchantInfo.state].filter((s) => {
            return s ? true : false;
          }).join(', ') + ` ${this.merchantInfo.zip}`).toUpperCase();

        this._cartSvc.GetCart();
        this._LoadAllItems();
      } else {
        // no merchant found, redirect to... 404?
        this.router.navigate(['/pagenotfound']).catch((e) => {

        });
      }
    }, (e) => {
      // error?
    }, () => {
      // completed - do nothing?
    });
  }

  private loadDefaultOrderType() : void {
    this.orderOptions = this.merchantInfo.orderOptions.filter((o) => o.enabled === true && o.suspended !== true);
    this.orderTypes = this.merchantInfo.orderOptions.filter((o) => {
      if (o.enabled && !o.suspended) {
        if (o.value === OrderTypeEnum.Delivery) {
          this.defaultOrderType.text = o.desc;
          this.defaultOrderType.value = o.value;

          this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
          this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
        } else {
          this.defaultOrderType.text = o.desc;
          this.defaultOrderType.value = o.value;

          this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
          this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
        }
        return o;
      }
    });

    this.cartModel.deliverytype = this.defaultOrderType.value;
    this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);
  }

  private _LoadAllItems() {
    this.localStorage.get(`${this.merchantInfo._id}.${KEYS.CATITEMKEY}`).subscribe((r: ItemCache) => {
      this._LoadFromServer();
    });
  }

  private clearCache(): void {
    try {
      this.localStorage.get('selected-admin-component').subscribe((flag: string) => {
        this.cartModel.itemList = [];
        this.localStorage.delete(this.merchantInfo._id).subscribe(() => { });
        this.localStorage.delete('order').subscribe(() => { });
        this.localStorage.delete('schedule').subscribe(() => { });
        this.localStorage.delete('deliveryinfo').subscribe(() => { });
        this.localStorage.delete('paymethod').subscribe(() => { });
        this.localStorage.delete('tip').subscribe(() => { });
        this.localStorage.delete('cart').subscribe((value) => { });
        this.localStorage.delete('user').subscribe(() => { });
        this.localStorage.clear().subscribe(() => {
          this.localStorage.set('merchant', this.merchantInfo).subscribe(async () => { });
          if (flag) {
            this.localStorage.set('selected-admin-component', flag).subscribe(() => { });
          } else {
            this.localStorage.set('selected-admin-component', 'd').subscribe(() => { });
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  private _LoadFromServer() {
    this._itemSvc.getAllCategoriesItems(this.merchantInfo._id).subscribe((r) => {
      let now = new Date().getTime();
      now = now + 15 * 60 * 1000; // expires in an hour
      // now = now + 2 * 60 * 1000; // expires in an hour
      const iCache = { expires: now } as ItemCache;
      this.localStorage.set(`${this.merchantInfo._id}.${KEYS.CATITEMKEY}`, iCache).subscribe();
      this._SetUpCatItems(r);
    });
  }

  private _SetUpCatItems(r: CatItem) {
    r.categories.sort((a, b) => a.title > b.title ? 1 : -1);
    /* r.items.forEach(i => { if (i.image) { i.image = `https://epionlineorders.s3.us-east-2.amazonaws.com/${i.image.replace('../', '')}`; } }); */
    r.categories.forEach((c) => {
      const ilist = r.items.filter((i) => i.categoryid === c.categoryid);
      this.itemCache.push({
        category: c.title,
        categoryid: c._id,
        items: ilist,
      });
    });
    this.categories = r.categories;
    this.CategoryClicked('');
  }

  private _formatSchedule() {
    const dateFormatOptions = { dateStyle: 'medium' } as DateTimeFormatOptions;
    const timeFormatOptions = { hour: 'numeric', hour12: true, minute: '2-digit' } as DateTimeFormatOptions;
    if (this.cartModel.schedule.value === 0) {
      this.scheduleText = null;
    } else {
      try {
        // console.log(this.cartModel);
        if (this.cartModel.schedule.asap) {
          this.scheduleText = 'ASAP';
        } else {
          const readyTime = new Date(this.cartModel.schedule.value);

          this.scheduleText = (this.cartModel.schedule.text === ScheduleType.ASAP)
            ? 'Today - ' + moment(readyTime).format('h:mm a')
            : moment(readyTime).format('ddd, MMMM Do - h:mm a');
        }
        // this.scheduleText = (this.cartModel.schedule.text === ScheduleType.ASAP)
        //   ? `ASAP - Approximately ${readyTime.toLocaleTimeString('default', timeFormatOptions)}`
        //   : `${readyTime.toLocaleDateString('default', dateFormatOptions)} at ${readyTime.toLocaleTimeString('default', timeFormatOptions)}`;
      } catch (e) {
        this.scheduleText = 'ASAP - Approximately 30 minutes';
      }
    }

    // this._cartSvc.UpdateSchedule(this.cartModel.schedule);
  }

  private _SetItemExtras(item: Item = null) {
    if (item) {
      // only grab the selected items for the cart to display:
      if (item.sides) { item.sides = item.sides.filter((i) => i.selected); }
      if (item.options) { item.options = item.options.filter((i) => i.selected); }
      if (item.modifiers) { item.modifiers = item.modifiers.filter((i) => i.selected); }
    }
  }

  public CategoryClicked(cid: string) {
    this.CategoryButtonClicked = true;
    this.selectedCategory = cid;
    this.categoryItems = null;
    const catItems = this.itemCache.filter((icr) => {
      return cid === '' || icr.categoryid === cid;
    });
    if (catItems.length > 0) {
      this.categoryItems = catItems;
    }
  }

  public SubCategoryClicked(c: Category) {
    this.selectedSubCategory = c;
    const catItems = this.itemCache.filter((icr) => {
      return icr.categoryid === c._id;
    });
    if (catItems[0]) {
      this.categoryItems = catItems;
      // this._ScrollToCategory();
    } else {
      this._itemSvc.getCategoryItems('s', `${c.categoryid}`, this.merchantInfo._id).subscribe((r: Item[]) => {
        r = r.sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        });
        const ic = {
          category: c.title || c.desc,
          categoryid: c._id,
          items: r,
        };
        this.itemCache.push(ic);
        this.categoryItems = [ic];
        // this._ScrollToCategory();
      }, (e) => {
        // error
        console.warn(e);
      }, () => {
        // complete - do nothing
      });
    }
  }

  public setNextAvailableTime() {
    // console.log('CALLED');
    // this.timer = setInterval(() => {
    // if (this.scheduleText && this.scheduleText !== null) {
    // console.log('checked ' + this.cartModel.schedule.value);
    if (this.cartModel && this.cartModel.schedule.asap) {
      // check if store closed -> move to next available date
      // const now = new Date().getTime();
      // const scheduledTime = new Date(this.cartModel.schedule.value).getTime();
      const submitTime = new Date().getTime() + (this.waittime * 60 * 1000);

      if (submitTime >= this.cartModel.schedule.close) {
        const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
          data: { message: 'The store is already closed for the day! Your order will be schedule for the next available date and time!', text: '' },
        });

        confirmRef.afterClosed().pipe(first()).subscribe((r) => {
          this.getNextAvailableDate();
          this._cartSvc.UpdateSchedule(this.cartModel.schedule);

          this._formatSchedule();
        });
      }
    } else {
      // check if passed time -> change to asap
      if (this.cartModel && this.cartModel.schedule.value > 0) {
        // const now = new Date().getTime();
        const scheduledTime = new Date(this.cartModel.schedule.value).getTime();
        const submitTime = new Date().getTime() + (this.waittime * 60 * 1000);
        // use < to test, use > for prod
        if (submitTime > scheduledTime) {
          this.cartModel.schedule.value += 5 * 60 * 1000;
          // this.cartModel.schedule.asap = true;
          if (this.cartModel.schedule.value > this.cartModel.schedule.close) {
            const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
              data: { message: 'The store is already closed for the day!<br />Your order will be schedule for the next available date and time!', text: '' },
            });

            confirmRef.afterClosed().pipe(first()).subscribe((r) => {
              this.getNextAvailableDate();
              this._cartSvc.UpdateSchedule(this.cartModel.schedule);

              this._formatSchedule();
            });
          } else {
            const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
              data: { message: 'It\'s already passed the time that you want to schedule your order for!<br />Your order will be schedule for ASAP!', text: '' },
            });

            confirmRef.afterClosed().pipe(first()).subscribe((r) => {
              this.cartModel.schedule.asap = true;
              this._cartSvc.UpdateSchedule(this.cartModel.schedule);

              this._formatSchedule();
            });
          }
          this._cartSvc.UpdateSchedule(this.cartModel.schedule);

          this._formatSchedule();
        }
      }
    }
    // }, 300000);
  }

  public getNextAvailableDate() {
    let open: any;
    let invalidDay = true;
    const t = new Date();

    while (invalidDay) {
      t.setDate(t.getDate() + 1);
      const openTime = this.defaultOperationTime[t.getDay()].openHour.split(':');
      const closeTime = this.defaultOperationTime[t.getDay()].closeHour.split(':');

      if (openTime.length > 1 && closeTime.length > 1) {
        if (openTime[1].indexOf('PM') !== -1) {
          open = t.setHours(parseInt(openTime[0].trim(), 10) + 12, parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
        } else {
          open = t.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
        }

        this.cartModel.schedule.text = ScheduleType.SCHEDULED;
        this.cartModel.schedule.value = open + (this.waittime * 60 * 1000);
        this.cartModel.schedule.asap = false;

        invalidDay = false;
      }

      // if (openTime.length === 1 || closeTime.length === 1 || d.getTime() > close) {
      //   d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
      // } else {
      //   invalidDay = false;
      // }
    }
  }

  public SearchAllItems() {
    this.CategoryButtonClicked = false;
    const stlower = this.searchText.toLowerCase();
    const results: ItemCacheRecord[] = [];
    this.itemCache.forEach((ic) => {
      const catResults = ic.items.filter((i) => {
        return i.title.toLowerCase().indexOf(stlower) !== -1 || i.desc.toLowerCase().indexOf(stlower) !== -1;
      });
      if (catResults.length > 0) {
        results.push({
          category: ic.category,
          categoryid: ic.categoryid,
          items: catResults,
        });
      }
    });
    this.categoryItems = results;
  }

  public GetItemDetails(item: Item, idx: number = -1) {
    this.itemIdx = idx;
    this.updateFlag = idx !== -1;
    this.item = item;

    // this._categorySvc.getCategory(this.merchantInfo.merchantid.toString(), item.categoryid).subscribe((cat: MenuCategory) => {
    const data = {
      instruction: item.specialInstruction ? item.specialInstruction : this.merchantInfo.settings.instruction,
      instructionEnabled: item.allowSpecialInstructions,
      isRetail: this.merchantInfo.industryType === 'retail',
      isUpdate: true,
      item: JSON.parse(JSON.stringify(item)) as Item,
      xtMerchant: this.merchantInfo.apiUrl && this.merchantInfo.apiUrl.trim() !== '',
      noneRMS: this.merchantInfo.rmsType === 3,
    };

    const dref = this.dialog.open(ItemDetailsModalComponent, {
      data,
      maxWidth: 650,
      // minHeight: 'calc(100vh - 50px)',
      panelClass: 'item-dialog-container',
    });

    dref.afterClosed().subscribe((i: Item) => {
      if (i && i !== undefined) {
        this.item = i;
        this.UpdateItem(i, idx);
      }
      return;
    });
    // });
  }

  public SetDeliveryType(value: OrderTypeEnum) {
    this._cartSvc.UpdateDeliveryType(value);
  }

  public UpdateItem(item: Item, idx: number) {
    item.qty = parseInt(item.qty.toString(), 10);
    this.cartModel.itemList[idx].qty = item.qty;
    if (item.qty < item.minqty && !this.confirmRef) {
      this.confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `Minimum quantity required is ${item.minqty}`, text: '', yesno: false, okonly: true },
      });

      this.confirmRef.afterClosed().pipe(first()).pipe(
        finalize(() => this.confirmRef = undefined),
      ).subscribe((r) => {
        item.qty = item.minqty;
      });
    }
    if (item.qty > item.maxqty && !this.confirmRef) {
      this.confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `Maximum quantity available is ${item.maxqty}`, text: '', yesno: false, okonly: true },
      });

      this.confirmRef.afterClosed().pipe(first()).pipe(
        finalize(() => this.confirmRef = undefined),
      ).subscribe((r) => {
        item.qty = item.maxqty;
      });
    }

    if (item.sides && item.sides.length > 0) {
      item.sides.forEach(s => {
        s.qty = item.qty;
      });
    }

    if (item.options && item.options.length > 0) {
      item.options.forEach(s => {
        s.qty = item.qty;
      });
    }

    if (item.modifiers && item.modifiers.length > 0) {
      item.modifiers.forEach(s => {
        s.qty = item.qty;
      });
    }

    if (item.qty === 0) {
      this.RemoveItem(idx);
    } else {
      this._SetItemExtras(item);
      this._cartSvc.UpdateItem(item, idx);
    }

    this.showDialog = true;
  }

  public RemoveItem(idx: number) {
    const i = this.cartModel.itemList[idx];

    const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
      data: { message: `Are you sure you want to remove ${i.title} from your cart?`, text: '', yesno: true },
    });

    confirmRef.afterClosed().pipe(first()).subscribe((r) => {
      if (r) {
        this._cartSvc.RemoveItem(idx);
      }
    });

    this.showDialog = true;
  }

  public OpenLoginDialog() {
    // open popup with item details?
    const dialogRef = this._loginDialog.open(LoginDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().pipe(first()).subscribe((r) => {

      if (r === 'register') {
        this.OpenRegistrationDialog();
      }

      if (r === 'password-reset-request') {
        this.openPasswordResetRequestDialog();
      }
    });
  }

  public OpenRegistrationDialog() {

    const dialogRef = this._registrationDialog.open(RegistrationDialogComponent, {
      height: '800px',
      maxWidth: 600,
      minWidth: 'calc(30vw)',
      width: '700px',
      data: {
        config: this.authConfig
      } as any
    });

    this.hideCart.emit(true);

    dialogRef.afterClosed().pipe(first()).subscribe((r: any) => {
      this.hideCart.emit(false);
    });
  }

  public openPasswordResetRequestDialog() {
    const pwdDialogRef = this.passwordRestRequestDialog.open(PasswordResetComponent, {
      data: this.merchantInfo,
      width: '400px',
    });

    pwdDialogRef.afterClosed().pipe(first()).subscribe((r: any) => {
      if (r && r.token) {
        const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
          data: { message: 'Password reset link has been sent to your email and phone', text: '', okonly: true },
        });

        confirmRef.afterClosed().pipe(first()).subscribe(() => {

        });
      }
    });

  }

  public Logout() {
    this.authSvc.logout();
    this.clearCache();
    this.sideNavService.close();
    this.router.navigate([this.merchantInfo.routeName]).catch((e) => { });
  }

  public ScheduleDialog(ot: OrderTypeEnum) {
    let orderTypeDescValue: string;

    // this.cartModel.deliverytype = ot;
    const previousSelected = this.cartModel.deliverytype;
    if (this.merchantInfo.orderOptions.length > 0) {
      const oo = this.merchantInfo.orderOptions.find((o) => o.value === ot);
      orderTypeDescValue = (oo) ? oo.desc : 'Delivery';
    } else {
      orderTypeDescValue = 'Delivery';
      this.cartModel.deliverytype = OrderTypeEnum.Delivery;
      this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);
    }

    if (!this.merchantInfo.settings.Active) {
      const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
        data: { message: 'Online Ordering is currently not available.', text: '' },
      });
    } else if (this._scheduleDialog.openDialogs.length === 0) {
      const dRef = this._scheduleDialog.open(ScheduleDialogComponent, {
        data: {
          isCheckout: false,
          merchant: this.merchantInfo,
          orderType: ot,
          orderTypeDesc: orderTypeDescValue,
          schedule: this.cartModel.schedule,
        } as IScheduleDialogData,
        width: '350px',
      });

      dRef.afterClosed().pipe(first()).subscribe((r: ISchedule) => {
        if (r) {
          this.cartModel.deliverytype = ot;
          if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
            this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
            this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
          } else {
            this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
            this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
          }
          this.cartModel.schedule = r;

          this.localStorage.set('schedule', this.cartModel.schedule).subscribe(() => { });

          this._cartSvc.UpdateSchedule(this.cartModel.schedule);
          this._formatSchedule();
        } else {
          this.cartModel.deliverytype = previousSelected;
          this._cartSvc.UpdateDeliveryType(this.cartModel.deliverytype);
        }
      });
    }
  }

  public ValidateItemQty(change: number = 0) {
    if (change !== 0) { this.item.qty += change; }
    this.item.qty = this.item.qty > this.item.maxqty ? this.item.maxqty : this.item.qty;
    this.item.qty = this.item.qty < 1 ? 1 : this.item.qty;
  }

  public Add() {
    this._SetItemExtras();
    // add the item:
    this._cartSvc.AddItem(this.item);
  }

  get sidesChosen() {
    return this.modalItem.sides.filter((s) => s.selected).length;
  }

  get sidesPrice() {
    // i.sides.map(s => s.price).reduce((p, c) => { return p + (c || 0); }, 0);
    return this.modalItem.sides.filter((s) => s.selected).map((s) => s.price).reduce((p, c) => p + (c || 0), 0);
  }

  public continueShopping() {
    // this.location.back();
    this.router.navigate([this.merchantInfo.routeName]).catch((e) => { });
  }

  public checkoutClicked() {
    this.localStorage.set('schedule', this.cartModel.schedule).subscribe(() => { });
    if (this.cartModel.subtotal.Value < this.merchantInfo.settings.minorderamt) {
      const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
        data: { message: 'Subtotal has to exceed $' + this.merchantInfo.settings.minorderamt.toFixed(2) + ' to continue!', text: '', okonly: true },
      });
    } else {
      this._merchantSvc.selectedMerchant(this.merchantInfo);
      this.router.navigate([this.merchantInfo.routeName + '/checkout']).catch((e) => { });
    }
  }
}
