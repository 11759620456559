import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Order } from 'projects/common/src/lib/order';
import { Transaction } from 'projects/common/src/lib/transaction';
import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FavoriteOrder } from 'projects/common/src/lib/favorite-order';
import { bool } from 'aws-sdk/clients/redshiftdata';

/* const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}; */

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  // public buffer: Buffer;

  constructor(public http: HttpClient) { }

  public submitTransaction(transaction: Transaction, access_token: string = undefined): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': transaction.applicationKey,
        'authorization': access_token,
        'merchantnumber': transaction.merchantNumber
      })
    };

    return this.http.post<EtcPaymentResponse>('/api/transaction', transaction, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public completeOrder(order: Order): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': order.applicationKey,
        'merchantnumber': order.merchantNumber
      })
    };

    return this.http.put<any>('/api/orders/complete', order, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public processApplePayment(payment: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': payment.order.applicationKey,
        'merchantnumber': payment.order.merchantNumber
      })
    };

    return this.http.post<any>('/api/applepay', payment, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public submitOrder(order: Order): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': order.applicationKey,
        'merchantnumber': order.merchantNumber
      })
    };

    return this.http.post<any>('/api/orders', order, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public voidOrder(order: Order): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': order.applicationKey,
        'merchantnumber': order.merchantNumber
      })
    };

    return this.http.post<any>('/api/orders/void', order, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public refundOrder(order: Order): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': order.applicationKey,
        'merchantnumber': order.merchantNumber
      })
    };

    return this.http.post<any>('/api/orders/refund', order, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public printOrder(data: any): Observable<any> {
    return this.http.put<any>('/api/printing/status', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public search(mode: string, searchvalue: string, merchantid?: string): Observable<any> {
    if (mode && searchvalue) {

      if (merchantid) {
        const parameters = new HttpParams();
        parameters.append('merchantid', merchantid);

        return this.http.get<any>('/api/orders/' + mode + '/' + searchvalue, { params: parameters })
          .pipe(
            catchError(this.handleError)
          );

      } else {
        return this.http.get<any>('/api/orders/' + mode + '/' + searchvalue)
          .pipe(
            catchError(this.handleError)
          );
      }

    } else {
      return throwError(new Error('mode and searchvalue are required'));
    }
  }

  public getReceipt(searchvalue: string): Observable<any> {
    if (searchvalue) {
      return this.http.get<any>('/api/receipt/' + searchvalue)
        .pipe(
          catchError(this.handleError)
        );
    } else {
      return throwError(new Error('mode and searchvalue are required'));
    }
  }

  public getOrders(user_id: string, id: string): Observable<any> {
    if (!user_id) {
      return this.http.get<any>('/api/ordersByMerchantid/' + id)
        .pipe(
          catchError(this.handleError)
        );
    } else {
      /*       return this.http.get<any>('/api/ordersByUserid/' + user_id + '/' + id)
              .pipe(
                catchError(this.handleError)
              ); */
      return this.http.get<any>('/api/orders/m/' + id)
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  public delete(order: Order): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: order
    };

    return this.http.delete<any>('/api/orders', options)
      .pipe(
        catchError(this.handleError)
      );
  }

  public addFavOrder(favOrder: FavoriteOrder): Observable<any> {
    return this.http.post<any>('/api/favOrders', favOrder)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getFavOrders(user_id: string, id: string): Observable<any> {
    return this.http.get<any>('/api/favOrders/' + user_id + '/' + id)
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteFavOrder(favOrder: FavoriteOrder): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: favOrder
    };

    return this.http.delete<any>('/api/favOrders', options)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getScanToPayOrder(mid: string, sid: string, access_token: string = undefined): Observable<any> {
    let httpOptions: any;

    if (access_token) {
      httpOptions = {
        headers: new HttpHeaders({
          'authorization': access_token
        })
      };
    }

    if (mid && sid) {
      return this.http.get<any>('/api/scantopay/' + mid + '/' + sid, httpOptions)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('mode and searchvalue are required'));
    }
  }

  public getExternalOrder(mid: string, sid: string): Observable<any> {
    if (mid && sid) {

      return this.http.get<any>('/api/external/order/' + mid + '/' + sid)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('mid and sid are required'));
    }
  }

  public getXTDeliveryOrder(mid: string, sid: string): Observable<any> {
    if (mid && sid) {
      return this.http.get<any>('/api/xt/deliveryorder/' + mid + '/' + sid)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('mid and sid are required'));
    }
  }

  public getGatewayBatchDetails(mid: string, batchid: string): Observable<any> {
    if (batchid) {

      let httpOptions = {
        headers: new HttpHeaders({
          'merchantnumber': mid
        })
      };

      return this.http.get<any>('/api/batch/details/' + batchid, httpOptions)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('batchid required'));
    }
  }

  public orderHistory(mid: string, startdate: string, enddate: string, pageno: number, pagecount: number, lastid?: string): Observable<any> {
    // lastid is the last mongo objectid returned from the last record from the previous page

    if (!pageno) {
      pageno = 1;
    }

    if (startdate && enddate) {

      let query: any = '';

      if (!lastid) {
        query = '/api/orders/m/' + mid + '/' + startdate + '/' + enddate + '/' + pageno + '/' + pagecount;
      } else {
        query = '/api/orders/m/' + mid + '/' + startdate + '/' + enddate + '/' + pageno + '/' + pagecount + '/' + lastid;
      }

      return this.http.get<any>(query)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('mode and searchvalue are required'));
    }
  }

  public orderSummary(mid: string, startdate: string, enddate: string, pageno: number, pagecount: number, lastid?: string): Observable<any> {
    // lastid is the last mongo objectid returned from the last record from the previous page

    if (!pageno) {
      pageno = 1;
    }

    if (mid && startdate && enddate) {

      let query: any = '';

      if (!lastid) {
        query = '/api/orders/m/' + mid + '/' + startdate + '/' + enddate + '/' + pageno + '/' + pagecount;
      } else {
        query = '/api/orders/m/' + mid + '/' + startdate + '/' + enddate + '/' + pageno + '/' + pagecount + '/' + lastid;
      }

      return this.http.get<any>(query)
        .pipe(
          catchError(this.handleError)
        );

    } else {
      return throwError(new Error('mode and searchvalue are required'));
    }
  }

  public handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(err);
  }
}
