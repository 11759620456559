import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, ChildActivationEnd } from '@angular/router';
import { OrderService } from 'projects/services/src/lib/order.service';
import { Order } from 'projects/common/src/lib/order';
import { Observable, Subscription, Subject } from 'rxjs';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { Merchant } from 'projects/common/src/lib/merchant';
import { filter, takeUntil, map } from 'rxjs/operators';
import { User } from 'projects/common/src/lib/user';
import { LogIn } from 'projects/common/src/lib/login';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { isMainThread } from 'worker_threads';

declare var window: Window;

@Component({
  selector: 'app-sms-receipt',
  templateUrl: './sms-receipt.component.html',
  styleUrls: ['./sms-receipt.component.scss']
})
export class SmsReceiptComponent implements OnInit, OnDestroy {
  order: Order;
  merchant: Merchant;
  brandname = 'pw';
  user: User;
  isMerchant = false;
  public orderSubscription: Subscription;
  public routeSubscription: Subscription;
  public receiptno = '';
  public destroyed = new Subject<any>();

  constructor(
    private _localStorage: StorageMap,
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
    public merchantService: MerchantService,
    private authService: AuthenticationService) {
    try {

      // console.log('SMS Receipt Component');

      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }

      this.routeSubscription = this.router.events.pipe(
        filter((event: RouterEvent) => event instanceof ChildActivationEnd),
        takeUntil(this.destroyed)
      ).subscribe(async (e: any) => {
        // If it is a NavigationEnd event re-initalise the component

        if (e instanceof ChildActivationEnd) {

          // console.log(e.snapshot);
          if (e.snapshot.url && e.snapshot.url.length > 1) {
            this.receiptno = e.snapshot.url[1].path;

            // console.log('SmsReceiptComponent.constructor(): this.receiptno = ' + this.receiptno);

          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {

      // console.log('SmsReceiptComponent.ngOnInit(): ' + params.get('receiptno'));
      this.receiptno = params.get('receiptno');
      const pathname = window.location.pathname.split('/');
      if (pathname[0] === 'receipt' || pathname[1] === 'receipt') {
        this.isMerchant = false;
      } else {
        this.isMerchant = true;
      }

      if (this.receiptno) {
        this.order = await this.getOrder(this.receiptno);

        // console.log('SmsReceiptComponent.ngOnInit(): this.order = ' + this.order);

        if (this.order) {
          this.merchant = await this.getMerchant(this.order.merchantNumber);
          if (this.order.paymethods && this.order.paymethods.length > 0) {
            if (this.order.paymethods[0].cc_type) {
              this.brandname = 'pw pw-' + this.order.paymethods[0].cc_type;
            }
          }

          this._localStorage.get('user').subscribe(async (user: User) => {
            if (!user || (user && (!user.access_token || user.isGuestLogin))) {
              this.user = await this.GuestLogin();
            }
          });
        }
      }

    });
  }

  public async GuestLogin(): Promise<User> {
    return new Promise((resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authService.guestLogin(credentials).subscribe((data) => {

          if (data && data.access_token) {
            this.user = data;
            // this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            // this.localStorage.set('token', data.access_token).subscribe(() => { });
            resolve(data);
          } else {
            // this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });

  }

  public async getOrder(receiptno: string): Promise<Order> {
    return new Promise((resolve, reject) => {
      try {
        if (this.orderSubscription) {
          this.orderSubscription.unsubscribe();
        }

        this.orderSubscription = this.orderService.getReceipt(receiptno).subscribe((orders: any) => {
          // this.merchant = await this.getMerchant(this.order.merchantid);
          resolve(orders[0]);
        }, (error) => {
          console.log(error);
          reject(error);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async getMerchant(merchantNumber: string): Promise<Merchant> {
    return new Promise((resolve, reject) => {
      try {
        this.merchantService.search('m', merchantNumber).subscribe((result) => {
          resolve(result[0]);
        }, (error) => {
          console.log(error);
          reject(error);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  ngOnDestroy() {
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
  }

}
