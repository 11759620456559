import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

export interface Image {
  url: string;
  base64: any;
  enabled: boolean;
}

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA) public image: Image
  ) { }

  ngOnInit() {

  }

  close(): void {
    this.dialogRef.close(this.image);
  }

}
