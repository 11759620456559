import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// import { enableBodyScroll } from 'body-scroll-lock';
import { Item } from 'projects/common/src/lib/item';
import { Merchant } from 'projects/common/src/lib/merchant';
import { ExtraAmount, OptionalGroup } from 'projects/common/src/lib/optional-group';
import { User } from 'projects/common/src/lib/user';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { ItemService } from 'projects/services/src/lib/item.service';
import { MenuBuilderService } from 'projects/services/src/lib/menu-builder.service';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { enableBodyScroll } from 'body-scroll-lock';
import { finalize, first } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-modifier-groups-modal',
  templateUrl: './modifier-groups-modal.component.html',
  styleUrls: ['./modifier-groups-modal.component.scss']
})
export class ModifierGroupsModalComponent implements OnInit {
  public merchant: Merchant;
  public user: User;
  public modifierGroups: OptionalGroup[] = [];

  public searchText: string;
  public searchModifierGroups: OptionalGroup[] = [];

  public modalElement: any;

  constructor(
    public dialogRef: MatDialogRef<ModifierGroupsModalComponent>,
    private menuService: MenuBuilderService,
    private itemSvc: ItemService,
    private merchantService: MerchantService,
    public authSvc: AuthenticationService,
    private confirmDialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.user = this.authSvc.CurrentUser;
    // this.merchant = this.merchantService.CurrentMerchant;
    this.merchant = this.data.merchant

    this.loadModiferGroups();
  }

  public Search() {
    const stlower = this.searchText.toLowerCase();
    this.searchModifierGroups = this.modifierGroups.filter(g => {
      return (g.title && g.title.toLowerCase().indexOf(stlower) !== -1 || g.remoteName && g.remoteName.toLowerCase().indexOf(stlower) !== -1);
    });
  }

  public selectSearchResult(g: OptionalGroup) {
    this.searchText = g.title;
    const stlower = this.searchText.toLowerCase();
    this.searchModifierGroups = this.modifierGroups.filter(g => {
      return (g.title && g.title.toLowerCase().indexOf(stlower) !== -1 || g.remoteName && g.remoteName.toLowerCase().indexOf(stlower) !== -1);
    });
  }

  public selectModGroup(idx: number) {
    console.log(this.modifierGroups);
  }

  public loadModiferGroups() {
    // this.categoriesSubscription = this.menuService.getSpecific(this.merchant._id, 3).subscribe((options) => {
    //   // console.log(options);

    //   this.allModGroups = this.allModGroups.concat(options.menu_result.option_groups.filter(g => g.items.length > 0));

    //   this.menuService.getSpecific(this.merchant._id, 4).subscribe((sides) => {
    //     // console.log(sides);

    //     this.allModGroups = this.allModGroups.concat(sides.menu_result.side_groups.filter(g => g.items.length > 0));

    this.menuService.getSpecific(this.merchant._id, 5).subscribe((modifiers) => {
      // console.log(modifiers);

      this.modifierGroups = modifiers.menu_result.modifier_groups.filter(g => g.active && g.items.length > 0);
      this.searchModifierGroups = this.modifierGroups;

      this.data.modGroups.forEach(g => {
        this.searchModifierGroups.forEach(mg => {
          if (mg.group_id === g.group_id) {
            // mg.selected = g.selected;
            mg.selected = true;
            return;
          }
        });
      });

      this.authSvc.updatedProgressBarStatus(false);
      // this.applyFilter();
    });
    //   });
    // });
  }

  public close() {
    const selectedModGroups = this.modifierGroups.filter(m => m.selected);

    enableBodyScroll(this.modalElement);
    this.dialogRef.close(selectedModGroups);
  }

}
