import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';

export class Payment {
  public id: string;
  public byPassBatchCheck: boolean;
  public transactionCode: string;
  public industryType: string;
  public name: string;
  public firstname: string;
  public lastname: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public postalcode: string;
  public country: string;
  public email: string;
  public phone: string;
  public cell: string;
  public companyname: string;
  public cardnumber: string;
  public cc_last_four: string;
  public cc_type: string;
  public cc_exp_month: string;
  public cc_exp_year: string;
  public cc_exp: string;
  public cvv: string;
  public tipAmount: number;
  public amount: number;
  public taxAmount: number;
  public sandbox: string;
  public target: string;
  public responseCode: number;
  public approvalCode: string;
  public approvalAmount: number;
  public transactionIdentifer: string;
  public transactionIdentifier: string;
  public responseText: string = "";
  public transactionItemNumber: string = "";
  public paymentId: number;
  public token: string;
  public batchid: string;
  public batchNumber: string;
  public itemNumber: string;
  public revisionNumber: string;
  public eci: string;
  public aci: string;
  public invoiceId: number;
  public invoiceNum: string;
  public datetime: Date;
  public response: EtcPaymentResponse[] = [];

  constructor() {
    this.id = ''; // uuidv5('deliverme' + window.location.hostname, uuidv5.DNS);
    this.byPassBatchCheck = false;
    this.transactionCode = "4";
    this.industryType = "6";
    this.cardnumber = '';
    this.name = '';
    this.firstname = '';
    this.lastname = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.postalcode = '';
    this.country = 'USA';
    this.cc_last_four = '';
    this.cc_type = '';
    this.cc_exp_month = '';
    this.cc_exp_year = '';
    this.cc_exp = '';
    this.cvv = '';
    this.amount = 0.00;
    this.taxAmount = 0.00;
    this.sandbox = 'n';
    this.target = '';
    this.responseCode = 0;
    this.approvalCode = '';
    this.approvalAmount = 0.00;
    this.transactionIdentifer = '';
    this.responseText = '';
    this.transactionItemNumber = '';
    this.paymentId = 0;
    this.token = '';
    this.batchNumber = '1';
    this.itemNumber = '001';
    this.revisionNumber = '0';
    this.datetime = new Date(); // moment(new Date()).format('LLL');
    this.response = [];
  }
}
