import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Regex } from 'projects/common/src/lib/regex';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { Address } from 'projects/common/src/lib/address';
import { UserService } from 'projects/services/src/lib/user.service';
import { User } from 'projects/common/src/lib/user';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { getLocaleDateFormat } from '@angular/common';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Merchant } from 'projects/common/src/lib/merchant';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { OrderType, OrderTypeEnum } from 'projects/common/src/lib/ordertype';

@Component({
  selector: 'app-store-info-dialog',
  templateUrl: './store-info-dialog.component.html',
  styleUrls: ['./store-info-dialog.component.scss']
})
export class StoreInfoDialogComponent implements OnInit, OnDestroy {

  public merchantInfo: Merchant;
  public DaysOfWeek: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public orderTypes: OrderType[] = [];
  public waitTimes: number[] = [];
  public deliveryWaitTimes: number[] = [];
  public pickupWaitTimes: number[] = [];
  public operationHours = [];
  public deliveryHours = [];
  public pickupHours = [];

  scrollPosition = window.pageYOffset;

  bodyElement: any;
  modalElement: any;

  constructor(
    public dialogRef: MatDialogRef<StoreInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Merchant) {
    this.merchantInfo = data;
    this.orderTypes = this.merchantInfo.orderOptions.filter(o => o.enabled).sort((o1, o2) => o1.value - o2.value);

    this.orderTypes.forEach(o => {
      switch (o.value) {
        case OrderTypeEnum.DineIn:
          break;
        case OrderTypeEnum.ToGo:
          break;
        case OrderTypeEnum.PickUp:
          o.waitTimes = 0;
          o.operationHours = [];
          o.waitTimes = this.merchantInfo.settings.pickupWaittime;
          o.operationHours = this.merchantInfo.settings.pickupHours;
          break;

        case OrderTypeEnum.Delivery:
          o.waitTimes = 0;
          o.operationHours = [];
          o.waitTimes = this.merchantInfo.settings.delWaittime;
          o.operationHours = this.merchantInfo.settings.deliveryHours;
          break;
      }
/*       if (o.value === OrderTypeEnum.Delivery) {
        this.waitTimes.push(this.merchantInfo.settings.delWaittime);
        this.operationHours.push(this.merchantInfo.settings.deliveryHours);
      } else {
        this.waitTimes.push(this.merchantInfo.settings.pickupWaittime);
        this.operationHours.push(this.merchantInfo.settings.pickupHours);

        this.pickupWaitTimes.push(this.merchantInfo.settings.delWaittime);
        this.pickupHours.push(this.merchantInfo.settings.deliveryHours);
      } */
    });
  }

  ngOnInit(): void {
    this.scrollPosition = window.pageYOffset;

    this.bodyElement = document.querySelector('#main-body');

    this.bodyElement.style.overflow = 'hidden';
    this.bodyElement.style.position  = 'fixed';
    this.bodyElement.style.top = `-${this.scrollPosition}px`;
    this.bodyElement.style.width = '100%';

    this.modalElement = document.querySelector('#body-content');
    disableBodyScroll(this.modalElement);
  }

  ngOnDestroy(): void {
    this.bodyElement.style.removeProperty('overflow');
    this.bodyElement.style.removeProperty('position');
    this.bodyElement.style.removeProperty('top');
    this.bodyElement.style.removeProperty('width');

    enableBodyScroll(this.modalElement);
    window.scrollTo(0, this.scrollPosition);
  }

  public close(): void {
    this.bodyElement.style.removeProperty('overflow');

    this.bodyElement.style.removeProperty('position');
    this.bodyElement.style.removeProperty('top');
    this.bodyElement.style.removeProperty('width');

    enableBodyScroll(this.modalElement);
    window.scrollTo(0, this.scrollPosition);

    this.dialogRef.close();
  }

}
