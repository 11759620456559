<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div [innerHTML]="data.text"></div>
  <!--   <mat-form-field>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()" cdkFocusInitial data-test="DialogOkBUtton">Ok</button>
</div>
