<div class="body" id="item-modal" style="padding: 0 !important;">
    <div class="modal-dialog modal-lg" role="document" style="margin: 0px !important;">
        <div class="modal-content">
            <div id="divItemDetailClose" fxLayoutAlign="start center">
                <a class="nav-link float-right" data-dismiss="modal" [routerLink]="[]" (click)="close()">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
            <div class="modal-header">
                <div class="row">
                    <h1 class="mods-item-title col-12">Modifier Groups Selection</h1>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid px-0">
                    <div class="row" style="width: 80%; border: 1px solid; margin-bottom: 20px; margin-left: 10px;">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-transparent border-0"><i class="fas fa-search"></i></span>
                        </div>
                        <input type="text" #search class="form-control border-0 bg-transparent" placeholder="Search Item" name="searchText" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="form" aria-describedby="form" (input)="Search()" [matAutocomplete]="autocomplete"
                            style="font-weight: bold; width: 85%;" data-test="Modifier-Groups-ModalSearchItemInput"/>
                        <button type="submit" hidden data-test="Modifier-Groups-ModalSubmitButton">Submit</button>

                        <mat-autocomplete #autocomplete="matAutocomplete">
                            <mat-option *ngIf="searchModifierGroups && searchModifierGroups.length <= 0">
                                <label>NO MODIFIER GROUP FOUND</label>
                            </mat-option>
                            <mat-option *ngFor="let g of searchModifierGroups" (click)="selectSearchResult(g)">
                                <label>{{g.title}}</label>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="row" style="margin-left: 30px;" *ngFor="let group of searchModifierGroups;let gi = index;">
                        <input class="form-check-input" type="checkbox" name="side_{{gi}}" [(ngModel)]="group.selected" id="side_{{gi}}" data-test="Modifier-Groups-ModalGroupSelectedInput"/>
                        <!-- (ngModelChange)="selectModGroup(gi)" [disabled]="group.max_limit > 1 && sidesChosen[gi] >= group.max_limit && !s.selected" /> -->
                        <label class="form-check-label" for="side_{{gi}}">{{group.title}}</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="container-fluid">
                    <div class="row" style="justify-content: center;">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style="align-self: center;">
                            <button type="button" class="btn btn-success w-100" (click)="close()" data-dismiss="modal" autofocus data-test="Modifier-Groups-ModalSaveModifierGroupButton">
                Save Modifier Group(s)
              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>