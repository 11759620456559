export class EmailOrderConfirmation {
    public Source: string;
    public Template: string;
    public Destination: {
        ToAddresses: string[],
        CcAddresses: string[]
    };
    TemplateData: string;

    constructor() {
        this.Source = '';
        this.Template = '';
        this.Destination = {
            ToAddresses: [],
            CcAddresses: []
        };
        this.TemplateData = '';
    }
}
