<div id="login-dialog" mat-dialog-content style="padding: 24px">
  <div fxLayout="column" fxLayoutGap="20px">
    <div class="content-header">
      <h2>Sign In</h2>
      <p>Don't have an account? <a href="javascript:void(0)" (click)="GoToRegistration()"
          style="font-weight: bold;"><u>Sign Up!</u></a></p>
    </div>
    <!--    <button mat-raised-button (click)="signInWithFB()" class="loginbtn fb">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon svgIcon="facebook"></mat-icon>
        <span fxFlex="100%" style="text-align:center">Continue with Facebook</span>
      </div>
    </button> -->
    <button mat-raised-button (click)="signInWithGoogle()" class="loginbtn google" data-test="Login-DialogContinueWithGoogleButton">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon svgIcon="google"></mat-icon>
        <span fxFlex="100%" style="text-align:center">Continue with Google</span>
      </div>
    </button>
    <form [formGroup]="form" (ngSubmit)="login()">
      <div fxLayout="row wrap">
        <mat-form-field fxFlex="100">
          <input matInput type="email" formControlName="email" placeholder="Email" data-test="Login-DialogEmailInput"/>
          <mat-error *ngIf="email.invalid" class="help-block">Username is required</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100" style="position: relative;">
          <input matInput [type]="show ? 'text' : 'password'" formControlName="password" placeholder="Password" data-test="Login-DialogPasswordInput"/>
          <button class="showpw" type="button" (click)="show = !show" data-test="Login-DialogShowButton">
            <!-- {{show ? 'Hide' : 'Show'}} -->
            <i *ngIf="!show" class="fas fa-eye"></i>
            <i *ngIf="show" class="fas fa-eye-slash"></i>
          </button>
          <mat-error *ngIf="password.invalid" class="help-block">Password is required</mat-error>
        </mat-form-field>
        <div fxFlex="100" class="forgot-password">
          <a style="cursor: pointer" (click)="passwordResetRequest()">Forgot your password?</a>
        </div>
        <div fxFlex="100%" style="text-align:center;color: #f33;" *ngIf="loginError">
          {{loginError}}
        </div>
        <button [class.submitActive]="!form.invalid" fxFlex="100" type="submit" mat-raised-button
          [disabled]="form.invalid || loggingIn" class="loginbtn login" [class.spinner]="loggingIn" data-test="Login-DialogSignInButton">
          Sign In
        </button>
        <a style="margin: auto;cursor: pointer" (click)="Cancel()" data-test="Login-DialogCancelA">
          Cancel
        </a>
      </div>
    </form>
  </div>
  <mat-divider></mat-divider>
  <label style="font-weight: 400;">Version: {{version}}</label>
</div>
