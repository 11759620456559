import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent, ActivationEnd, ChildActivationEnd } from '@angular/router';
import { filter, takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-confirmed',
  templateUrl: './confirmed.component.html',
  styleUrls: ['./confirmed.component.scss']
})
export class ConfirmedComponent implements OnInit {
  public routeSubscription: Subscription;
  public destroyed = new Subject<any>();
  public email: string = '';

  constructor(public route: ActivatedRoute, public router: Router) {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }

      this.route.paramMap.subscribe((params) => {
        this.email = params.get("email");
      });

    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit(): void {

  }

}
