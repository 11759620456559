import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  HostListener,
  Output,
  InjectionToken,
  Injector,
  ComponentRef,
  ViewEncapsulation
} from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, ChildActivationEnd } from '@angular/router';
import { Observable, Subscription, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter, takeUntil } from 'rxjs/operators';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { GiftCardService } from 'projects/services/src/lib/giftcard.service';
import { GiftCard } from 'projects/common/src/lib/giftcard';
import { Merchant } from 'projects/common/src/lib/merchant';
import { User } from 'projects/common/src/lib/user';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LogIn } from 'projects/common/src/lib/login';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-egift',
  templateUrl: './egift.component.html',
  styleUrls: ['./egift.component.scss']
})
export class EgiftComponent implements OnInit {
  routeSubscription: Subscription;
  destroyed = new Subject<any>();
  activationCode = '';
  user: User;
  activatedText = '';
  errorText = '';

  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private merchantService: MerchantService,
    private giftCardService: GiftCardService,
    private authenticationService: AuthenticationService,
    private localStorage: StorageMap) {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    this.routeSubscription = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof ChildActivationEnd),
      takeUntil(this.destroyed)
    ).subscribe(async (e: any) => {
      // If it is a NavigationEnd event re-initalise the component

      if (e instanceof ChildActivationEnd) {

        if (e.snapshot.url && e.snapshot.url.length > 1) {
          if (e.snapshot.url[0].path === 'egift' && e.snapshot.url[1].path === 'activate') {
            this.activationCode = e.snapshot.url[2].path;

            /*             let giftCard: GiftCard = new GiftCard(undefined);
                        giftCard.action = 'a';
                        giftCard.activationCode */


            // this.authToken = e.snapshot.url[2].path;
            // console.log('this.authToken: ' + this.authToken);

            this.user = await this.GuestLogin().catch((error: any) => {
              console.log(error);
            }) as User;

            if (this.user && this.user.access_token) {
              this.localStorage.set('user', this.user).subscribe(() => { });

              await this.giftCardService.getGiftCard('ac', this.activationCode).subscribe(async (giftCard: GiftCard) => {
                giftCard.action = 'a';

                await this.giftCardService.activateGiftCard(giftCard).subscribe((result: any) => {
                  this.activatedText = 'Congratulations your egift card has been activated and is ready to be redeemed';
                }, (error: any) => {
                  this.errorText = error.error ? error.error.message : error.message;
                });
              }, (error: any) => {
                this.errorText = error.error ? error.error.message : error.message;
              });
            }

          } else {

          }
        }

      }
    });
  }

  ngOnInit(): void {
  }

  public async GuestLogin(): Promise<User> {
    return new Promise((resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authenticationService.guestLogin(credentials).subscribe((data) => {

          if (data && data.access_token) {
            this.user = data;
            // this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            this.localStorage.set('token', data.access_token).subscribe(() => { });
            resolve(data);
          } else {
            // this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            this.localStorage.delete('token').subscribe(() => { });
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

}
