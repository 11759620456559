import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Pipe, PipeTransform } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter, OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { FlexLayoutModule, LAYOUT_CONFIG, BREAKPOINT } from '@angular/flex-layout';
import { MaterialDesignModule } from 'projects/common/src/lib/material-design/material-design.module';
import { AgmCoreModule } from '@agm/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthIntercept } from 'projects/common/src/lib/auth-intercept';
import { HttpGlobalInterceptor } from 'projects/common/src/lib/http-interceptor';
import { LogIn } from 'projects/common/src/lib/login';
import { User } from 'projects/common/src/lib/user';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCommonModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
// import { ButtonsModule } from "@progress/kendo-angular-buttons";

import { TextMaskModule } from 'angular2-text-mask';

import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { AddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddressSelectionComponent } from './components/address-dialog/address-selection/address-selection.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { Http404Component } from './components/http404/http404.component';
import { ItemDetailsModalComponent } from './components/item-details-modal/item-details-modal.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { ReceiptDialogComponent } from './components/receipt-dialog/receipt-dialog.component';
import { RegistrationDialogComponent } from './components/registration-dialog/registration-dialog.component';
import { ScheduleDialogComponent } from './components/schedule-dialog/schedule-dialog.component';
import { StoreCartComponent } from './components/store-cart/store-cart.component';
import { StoreCheckoutComponent } from './components/store-checkout/store-checkout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReceiptsModule } from 'projects/receipts/src/app/app.module';
import { ReceiptComponent } from 'projects/receipts/src/app/components/receipt/receipt.component';
import { Receipt2Component } from 'projects/receipts/src/app/components/receipt2/receipt2.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider, AmazonLoginProvider } from 'angularx-social-login';
import { StoreComponent } from './components/store/store.component';
import { StoreInfoDialogComponent } from './components/store-info-dialog/store-info-dialog.component';
import { NgRatingBarModule } from 'ng-rating-bar';
import { ApplePayVerificationComponent } from './components/apple-pay-verification/apple-pay-verification.component';
import { ScanToPayComponent } from './components/scan-to-pay/scan-to-pay.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { ModifierGroupsModalComponent } from './components/modifier-groups-modal/modifier-groups-modal.component';
import { MerchantPromptDialogComponent } from './components/merchant-prompt-dialog/merchant-prompt-dialog.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { MapModule } from './components/map/map.module';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { EgiftComponent } from './components/egift/egift.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { ConfirmedComponent } from './components/confirmed/confirmed.component';

const EXTRA_BREAKPOINTS = [{
  alias: 'xs.landscape',
  suffix: 'XsLandscape',
  mediaQuery: 'screen and (orientation: landscape) and (max-width: 559px)',
  priority: 1000,
  overlapping: false
}];

const MY_CUSTOM_FORMATS = {
  parseInput: 'LLL',
  fullPickerInput: 'LLL',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LLL',
  monthYearA11yLabel: 'MMMM YYYY',
};


export function getBaseHref() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
  declarations: [
    AppComponent,
    AddressDialogComponent,
    ConfirmDialogComponent,
    AddressSelectionComponent,
    DialogComponent,
    Http404Component,
    PasswordResetComponent,
    ItemDetailsModalComponent,
    LoginDialogComponent,
    PaymentDialogComponent,
    ReceiptDialogComponent,
    RegistrationDialogComponent,
    ScheduleDialogComponent,
    StoreComponent,
    StoreCartComponent,
    StoreCheckoutComponent,
    StoreInfoDialogComponent,
    ApplePayVerificationComponent,
    ScanToPayComponent,
    PasswordChangeComponent,
    ImageDialogComponent,
    ModifierGroupsModalComponent,
    MerchantPromptDialogComponent,
    RegistrationComponent,
    EgiftComponent,
    HealthcheckComponent,
    ConfirmedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'deliverme' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RecaptchaModule,
    MapModule,
    RecaptchaFormsModule,
    MaterialDesignModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    TextMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    NgxSpinnerModule,
    AppRoutingModule,
    ReceiptsModule,
    SocialLoginModule,
    NgRatingBarModule,
    MatSidenavModule,
    TelerikReportingModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBqTbkSC9_LkMyM_JrDkPYp9AX-ejoAWmo', libraries: ['geometry', 'places'] }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    AppRoutingModule,
    RecaptchaModule,
    MapModule
  ],
  providers: [
    HttpClientModule,
    AuthenticationService,
    AuthIntercept,
    LogIn,
    User,
    SideNavService,
    DeviceDetectorService,
    MatIconRegistry,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpGlobalInterceptor,
      multi: true
    },
    { provide: 'BASE_URL', useFactory: getBaseHref },
    {
      provide: BREAKPOINT,
      useValue: EXTRA_BREAKPOINTS,
      multi: true
    },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1012903490271-kct1279j8bvl9f4ai13d4p9dfuk32hcd.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2515961195309358'),
          },
        ],
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class AppModule { }
