import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Item } from 'projects/common/src/lib/item';
import { Modifier } from 'projects/common/src/lib/modifier';
import { OptionalItem } from 'projects/common/src/lib/optional-item';
import { SideItem } from 'projects/common/src/lib/side-item';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CatItem } from '../../../common/src/lib/category';
import { GlobalService } from './global.service';
import { OptionalGroup } from 'projects/common/src/lib/optional-group';
import { User } from 'projects/common/src/lib/user';

const API_STORAGE_KEY = 'items';
const API_URL = 'https://localhost/api';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  public item: Item;
  public optionalGroup: OptionalGroup;
  public itemList: Item[];

  constructor(
    public http: HttpClient,
    public storage: StorageMap,
    public globalService: GlobalService) {

      // this.storage.get('current_item').subscribe((item: Item) => {
      //   if (item) {
      //     this.item = item;
      //   }
      // });
  }

  public search(merchantid: string, itemname: string): Observable<Item[]> {
    try {
      return this.http.get<Item[]>('/api/item/search/' + merchantid + '/' + itemname)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public addItem(data: Item): Observable<any> {
    return this.http.post<any>('/api/manage/item', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateItem(data: Item): Observable<any> {
    return this.http.put<any>('/api/manage/item', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateInstructions(data: any): Observable<any> {
    return this.http.post<any>('/api/manage/item/instructions', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getAllCategoriesItems(merchantid: string): Observable<CatItem> {
    try {
      return this.http.get<CatItem>('/api/items/' + merchantid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getItem(id: string, merchantid: string): Observable<Item> {
    try {
      return this.http.get<Item>('/api/item/' + id + '/' + merchantid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getItemsInventory(itemid: string, merchantid: string): Observable<Item[]> {
    try {
      return this.http.get<Item[]>('/api/item/stock/' + merchantid + '/' + itemid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getCategoryItems(flag: string, id: string, merchantid: string): Observable<Item[]> {
    try {
      return this.http.get<Item[]>('/api/items/category/' + flag + '/' + merchantid + '/' + id)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getSides(merchantid: string, id: number): Observable<any> {
    try {
      return this.http.get<any>('/api/item/sides/' + merchantid + '/' + id)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getOptions(merchantid: string, optiongroup: number): Observable<OptionalItem[]> {
    try {
      return this.http.get<OptionalItem[]>('/api/item/options/' + merchantid + '/' + optiongroup)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getModifiers(merchantid: string, modifiergroups: string): Observable<any> {
    try {
      return this.http.get<any>('/api/item/modifiers/' + merchantid + '/' + modifiergroups)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public deleteItem(item: Item): Observable<any> {
    try {
      return this.http.delete<any>('/api/manage/item/' + item._id)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  // public getModifiers(merchantid: string, modifiergroups: number[]): Observable<any> {
  //   try {
  //     let params = new HttpParams();
  //     params = params.append('merchantid', merchantid);
  //     params = params.append('optiongroups', modifiergroups.join(','));
  //     return this.http.get<any>('/api/item/modifiers/', { params: params })
  //       .pipe(
  //         catchError(this.handleError)
  //       );
  //   } catch (error) {
  //     return throwError(new Error(error));
  //   }
  // }

  public getBeverages(merchantid: string): Observable<Item[]> {
    try {
      return this.http.get<Item[]>('/api/beverages/' + merchantid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public setLocalStorage(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data).subscribe(() => { });
  }

  // Get cached API result
  public getLocalStorage(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`).subscribe(() => { });
  }

  public setCurrentItem(item: Item) {
    // this.storage.set('current_item', item).subscribe(() => { });
    this.item = item;
  }

  public setModGroup(group: OptionalGroup) {
    // this.storage.set('current_item', item).subscribe(() => { });
    this.optionalGroup = group;
  }

  public setCurrentItemList(items: Item[]) {
    // this.storage.set('current_item', item).subscribe(() => { });
    this.itemList = items;
  }

  public get CurrentItem() {
    return this.item;
  }

  public get CurrentGroup() {
    return this.optionalGroup;
  }

  public get CurrentItemList() {
    return this.itemList;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError((error.error) ? error.error : error);
  }
}
