import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { enableBodyScroll } from 'body-scroll-lock';
import { Item } from 'projects/common/src/lib/item';
import { ExtraAmount, OptionalGroup } from 'projects/common/src/lib/optional-group';
import { ItemService } from 'projects/services/src/lib/item.service';
import { finalize, first } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-item-details-modal',
  styleUrls: ['./item-details-modal.component.scss'],
  templateUrl: './item-details-modal.component.html',
})
export class ItemDetailsModalComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup = new UntypedFormGroup({
    qty: new UntypedFormControl('', [Validators.required])
  });

  get qty() { return this.form.controls.qty; }

  constructor(
    public dialogRef: MatDialogRef<ItemDetailsModalComponent>,
    private itemSvc: ItemService,
    private confirmDialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  // get sidesChosen() {
  //   if (this.modalItem.sides && this.modalItem.sides.length > 0) {
  //     console.log(this.modalItem.sides.filter((s) => s.selected).length);
  //     return this.modalItem.sides.filter((s) => s.selected).length;
  //   }
  // }

  get optionsChosen() {
    if (this.modalItem.options && this.modalItem.options.length > 0) {
      return this.modalItem.options.filter((s) => s.selected).length;
    }
  }

  get modifiersPrice() {
    if (this.modalItem.modifier_groups && this.modalItem.modifier_groups.length > 0) {
      let p = 0;
      this.modalItem.modifier_groups.forEach((g) => {
        g.items.forEach((i) => {
          if (i.selected) {
            const selectedExtra = i.extra.find((e: ExtraAmount) => e.selected);
            if (selectedExtra) {
              if (i.ratio === 0 || i.ratio === -99) {
                p += selectedExtra.fee;
              } else {
                p += (selectedExtra.fee / 2);
              }
            } else {
              if (i.ratio === 0 || i.ratio === -99) {
                p += i.price;
              } else {
                p += i.price / 2;
              }
            }
            // console.log(p);
          }
        });
      });
      return p;
    } else {
      return 0;
    }
  }

  get sidesPrice() {
    if (this.modalItem.side_groups && this.modalItem.side_groups.length > 0) {
      let p = 0;
      this.modalItem.side_groups.forEach((g) => {
        g.items.forEach((i) => {
          if (i.selected) {
            const selectedExtra = i.extra.find((e: ExtraAmount) => e.selected);
            if (selectedExtra) {
              if (i.ratio === 0 || i.ratio === -99) {
                p += selectedExtra.fee;
              } else {
                p += (selectedExtra.fee / 2);
              }
            } else {
              if (i.ratio === 0 || i.ratio === -99) {
                p += i.price;
              } else {
                p += i.price / 2;
              }
            }
            // console.log(p);
          }
        });
      });
      return p;
    } else {
      return 0;
    }
  }

  get optionsPrice() {
    if (this.modalItem.option_groups && this.modalItem.option_groups.length > 0) {
      // i.sides.map(s => s.price).reduce((p, c) => { return p + (c || 0); }, 0);
      // return this.modalItem.options.filter((s) => s.selected).map(s => s.price).reduce((p, c) => p + (c || 0), 0);

      let p = 0;
      this.modalItem.option_groups.forEach((g) => {
        g.items.forEach((i) => {
          if (i.selected) {
            const selectedExtra = i.extra.find((e: ExtraAmount) => e.selected);
            if (selectedExtra) {
              if (i.ratio === 0 || i.ratio === -99) {
                p += selectedExtra.fee;
              } else {
                p += (selectedExtra.fee / 2);
              }
            } else {
              if (i.ratio === 0 || i.ratio === -99) {
                p += i.price;
              } else {
                p += i.price / 2;
              }
            }
            // console.log(p);
          }
        });
      });
      return p;
    } else {
      return 0;
    }
  }
  public item: Item;
  public modalItem: Item;
  public isUpdate: boolean;
  public xtMerchant: boolean;
  public noneRMS: boolean;
  public isRetail: boolean;
  public confirmRef: any;
  public selectedIndex = -1;
  public showError = false;
  public selected = 0;
  public modifiersSelectedIndex = [];
  public sidesSelectedIndex = [];
  public optionSelectedIndex = [];
  public modifierError = [];
  public optionError = [];
  public instructionEnabled: boolean;
  public instruction: '';

  public excludingDefault = '';
  public bareItemPrice = 0;
  public modalElement: any;
  public additionalPlaceHolderText = 'Hold the mayo? Extra bacon? Customize your meal here. (Optional)';

  public sidesChosen = [];
  public modifiersChosen = [];

  /**
   * name
   */
  public name() {

  }

  public ngOnInit() {
    this.item = this.data.item;
    this.modalItem = this.data.item;
    this.modalItem.side_groups = [];
    this.isUpdate = this.data.isUpdate;
    this.xtMerchant = this.data.xtMerchant;
    this.noneRMS = this.data.noneRMS;
    this.isRetail = this.data.isRetail;
    this.instructionEnabled = this.item.allowSpecialInstructions;
    // this.instructionEnabled = this.data.instructionEnabled || this.item.allowSpecialInstructions;
    this.instruction = this.item.specialInstruction ? this.item.specialInstruction : this.data.instruction ? this.data.instruction : '';
    this.optionError = [];

    // if (this.isRetail) {
    //   this.additionalPlaceHolderText = 'Full case only? Cold/Hot? Individually wrapped? 50/50 split? Customize your item here. (Optional)';
    // }

    this.iconRegistry.addSvgIcon('pizza_left_ratio', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_left.svg'));
    this.iconRegistry.addSvgIcon('pizza_full_ratio', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_full.svg'));
    this.iconRegistry.addSvgIcon('pizza_right_ratio', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_right.svg'));
    this.iconRegistry.addSvgIcon('pizza_left_ratio_selected', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_left_selected.svg'));
    this.iconRegistry.addSvgIcon('pizza_full_ratio_selected', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_full_selected.svg'));
    this.iconRegistry.addSvgIcon('pizza_right_ratio_selected', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pizza_right_selected.svg'));

    if (this.item.qty < 1) {
      this.item.qty = 1;
    }

    if (this.item.sides.length > 0) {
      this.item.sides = this.item.sides.filter((s) => s.selected);
    }

    if (this.item.options.length > 0) {
      this.item.options = this.item.options.filter((o) => o.selected);
    }

    this.form = new UntypedFormGroup({
      qty: new UntypedFormControl(this.item.qty >= this.item.minqty && this.item.qty <= this.item.maxqty ? this.item.qty : this.item.minqty,
        [Validators.required, Validators.min(this.item.minqty > 0 ? this.item.minqty : 1), Validators.max(this.item.maxqty > 0 ? this.item.maxqty : 99)])
    });

    if (this.noneRMS) {
      const data = {
        merchantid: this.item.merchantid,
        modifiergroups: this.item.modifierGroupsNumber
      }
      this.itemSvc
        .getModifiers(this.item.merchantid, this.item.modifierGroupsNumber.toString())
        .subscribe((r) => {
          const selectedMods = this.item.modifiers;

          if (r && r.modifierGroups.length > 0 && r.modifiers.length > 0) {
            console.log(r);
            console.log(this.item.modifierGroupsNumber.toString());
            r.modifierGroups.forEach((g: OptionalGroup, idx: number) => {
              if(g.active){
                // Should change API for this -> Thi's is TEMP Solution
                g.items = r.modifiers.filter((s) => s.group_number === g.group_number || s.group_id === g.group_number);
                // TEMP
                // g.ratio = 'Pizza';

                // this.iconRegistry.addSvgIcon('side_left_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_left.svg'));
                // this.iconRegistry.addSvgIcon('side_full_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_full.svg'));
                // this.iconRegistry.addSvgIcon('side_right_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_right.svg'));

                // const ext_display = []
                if (g.items.length > 0 && g.min_limit <= g.max_limit) {
                  g.items.forEach((i) => {
                    if (g.allowRatio) {
                      i.ratio = 0;
                    }

                    g.extra.forEach((e) => {
                      if (e.enabled) {
                        const ext = Object.assign(new ExtraAmount(), e);
                        ext.fee += i.price;
                        i.extra.push(ext);
                      }
                    });

                    // i.extra = g.extra.map(x => Object.assign({}, x));
                  });

                  // const ext_display = Array(g.items.length).fill(false);
                  // this.displayExtraSides.push(ext_display);

                  g.items = g.items.sort((a, b) => {
                    return a.title > b.title ? 1 : -1;
                  });
                  // this.modalItem.side_groups.push(g);
                  // this.sidesChosen.push(0);
                  // this.optionError.push(false);
                }
              }
            });

            this.modalItem.modifier_groups = [];
            this.modalItem.modifierGroupsNumber.forEach((num) => {
              const group = r.modifierGroups.find((x: OptionalGroup) => x.group_id === num && x.active);
              if(group) {
                this.modalItem.modifier_groups.push(group);
              }
            });

            this.modalItem.modifiers = r.modifiers.sort((a, b) => {
              return a.title > b.title ? 1 : -1;
            });

            // console.log(selectedMods);

            selectedMods.forEach(m => {
              if (m.selected) {
                this.modalItem.modifiers.find(i => {
                  if (i._id === m._id) {
                    i.selected = m.selected;
                  }
                });
              }
            });

            this.item.modifier_groups.forEach(g => {
              g.items = this.item.modifiers.filter(m => m.group_id === g.group_id);
            });
            // this.modalItem.modifiers = r.map((s) => {
            //   const filtered = this.item.modifiers.filter((is) => {
            //     return is._id === s._id && is.selected;
            //   });
            //   if (filtered[0]) {
            //     s.selected = true;
            //   }
            //   return s;
            // }).sort((a, b) => {
            //   return a.title > b.title ? 1 : -1;
            // });
          }
        });
    } else {
      this.itemSvc
        .getSides(this.item.merchantid, this.item.sides_group)
        .subscribe((r) => {
          if (r) {
            // console.log(r);
            if (r.groups && r.groups.length > 0) {
              /*            this.modalItem.side_groups.push(new OptionalGroup());
                        this.modalItem.side_groups[0].max_limit = this.item.maxsides;
                        this.modalItem.side_groups[0].min_limit = this.item.maxsides;
                        this.modalItem.side_groups[0].items = r.options.sort((a, b) => {
                          return a.title > b.title ? 1 : -1;
                        });
                      } else { */
              r.groups.forEach((g: OptionalGroup, idx: number) => {
                // Should change API for this -> Thi's is TEMP Solution
                g.items = r.sides.filter((s) => s.group_number === g.group_number);
                // TEMP
                // g.ratio = 'Pizza';

                // this.iconRegistry.addSvgIcon('side_left_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_left.svg'));
                // this.iconRegistry.addSvgIcon('side_full_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_full.svg'));
                // this.iconRegistry.addSvgIcon('side_right_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + g.ratio.toLowerCase() + '_right.svg'));

                // const ext_display = []
                if (g.items.length > 0 && g.min_limit <= g.max_limit) {
                  g.items.forEach((i) => {
                    if (g.allowRatio) {
                      i.ratio = 0;
                    }

                    g.extra.forEach((e) => {
                      if (e.enabled) {
                        const ext = Object.assign(new ExtraAmount(), e);
                        ext.fee += i.price;
                        i.extra.push(ext);
                      }
                    });

                    // i.extra = g.extra.map(x => Object.assign({}, x));
                  });

                  // const ext_display = Array(g.items.length).fill(false);
                  // this.displayExtraSides.push(ext_display);

                  g.items = g.items.sort((a, b) => {
                    return a.title > b.title ? 1 : -1;
                  });
                  this.modalItem.side_groups.push(g);
                  this.sidesChosen.push(0);
                  this.optionError.push(false);
                }
              });

              if (this.modalItem.side_groups.length > 1) {
                this.modalItem.side_groups = this.modalItem.side_groups.sort((n1, n2) => n1.group_number - n2.group_number);
              }
            }
            // else {
            //   const g = new OptionalGroup();
            //   g.group_id = this.modalItem.itemid;
            //   g.group_number = 1;
            //   g.max_limit = this.modalItem.maxsides;
            //   g.min_limit = this.modalItem.maxsides;
            //   g.items = r.sides.sort((a, b) => {
            //     return a.title > b.title ? 1 : -1;
            //   });

            //   g.items.forEach(i => {
            //     i.group_number = g.group_number;
            //   });

            //   this.modalItem.side_groups.push(g);
            // }

            this.modalItem.side_groups.forEach((g) => {
              let selected = -2;
              for (let i = 0; i < g.items.length; i++) {
                // g.items.forEach(i => {
                this.item.sides.find((o) => {
                  if (o._id === g.items[i]._id) {
                    g.items[i].selected = true;
                    // const extraIndex = o.extra.findIndex((e: ExtraAmount) => e.selected);
                    g.items[i].extra = o.extra;

                    if (g.max_limit > 1) {
                      selected = -1;
                    } else {
                      selected = i;
                    }
                  }
                });
              }
              this.sidesSelectedIndex.push(selected);

              // if (this.xtMerchant && this.item.maxsides === 1) {
              //   for (let i = 0; i < this.modalItem.options.length; i++) {
              //     if (this.modalItem.options[i].selected) {
              //       this.selectedIndex = i;
              //     }
              //   }
              // }
            });
          }
        });

      this.modalItem.option_groups = [];
      this.itemSvc
        .getOptions(this.item.merchantid, this.item.option_group)
        .subscribe((r: any) => {
          if (r && r.length > 0) {
            r.forEach((og: OptionalGroup, idx: number) => {
              // TEMP
              // og.ratio = 'Pizza';

              // this.iconRegistry.addSvgIcon('option_left_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + og.ratio.toLowerCase() + '_left.svg'));
              // this.iconRegistry.addSvgIcon('option_full_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + og.ratio.toLowerCase() + '_full.svg'));
              // this.iconRegistry.addSvgIcon('option_right_ratio_' + idx, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/' + og.ratio.toLowerCase() + '_right.svg'));

              if (og.items.length > 0) {
                this.modalItem.option_groups.push(og);
                og.items.forEach((i) => {
                  if (og.allowRatio) {
                    i.ratio = 0;
                  }

                  og.extra.forEach((e) => {
                    if (e.enabled) {
                      const ext = Object.assign(new ExtraAmount(), e);
                      ext.fee += i.price;
                      i.extra.push(ext);
                    }
                  });

                  // i.extra = og.extra.map(x => Object.assign({}, x));
                });
              }

              // const ext_display = Array(og.items.length).fill(false);
              // this.displayExtraOptions.push(ext_display);

              og.items = og.items.sort((a, b) => {
                return a.title > b.title ? 1 : -1;
              });
            });

            this.modalItem.option_groups.forEach((g) => {
              // let selected = -2;
              for (let i = 0; i < g.items.length; i++) {
                // g.items.forEach(i => {
                this.item.options.find((o) => {
                  if (o._id === g.items[i]._id) {
                    g.items[i].selected = true;

                    // const extraIndex = o.extra.findIndex((e: ExtraAmount) => e.selected);
                    g.items[i].extra = o.extra;

                    // if (g.max_limit > 1) {
                    //   selected = -1;
                    // } else {
                    //   selected = i;
                    // }
                  }
                });
              }
            });
          }
        });
    }

    this.calculateBareItemPrice();
    this.qtyChanged();
    // this.itemSvc
    //   .getOptions(this.item.merchantid, this.item.option_group)
    //   .subscribe((r: any) => {
    //     if (r && r.length > 0) {
    //       this.modalItem.options = r.map(s => {
    //         const filtered = this.item.options.filter(is => {
    //           return is._id === s._id && is.selected;
    //         });
    //         if (filtered[0]) {
    //           s.selected = true;
    //         }

    //         return s;
    //       }).sort((a, b) => {
    //         return a.title > b.title ? 1 : -1;
    //       });
    //       // if (!this.xtMerchant && this.item.maxsides === 1) {
    //       //   for (let i = 0; i < this.modalItem.sides.length; i++) {
    //       //     if (this.modalItem.sides[i].selected) {
    //       //       this.selectedIndex = i;
    //       //     }
    //       //   }
    //       // }
    //     }
    //   });

    /*    this.modalElement = document.querySelector('#body-content');
       disableBodyScroll(this.modalElement); */

  }

  public displayExtraAmount(extras: any[]): boolean {
    if(this.data && this.data.merchant) {
      if(this.data.merchant.settings.hasOwnProperty("displayExtraAmounts")) {
          return this.data.merchant.settings.displayExtraAmounts;
      } else {
        return true;
      }
    }
  }

  public calculateBareItemPrice() {
    this.bareItemPrice = this.item.price;

    this.item.side_groups.forEach((sg) => {
      sg.items.forEach((s) => {
        if (s.default) {
          this.bareItemPrice -= s.price;
        }
      });
    });

    this.item.side_groups.forEach((og) => {
      og.items.forEach((o) => {
        if (o.default) {
          this.bareItemPrice -= o.price;
        }
      });
    });
  }

  public ngOnDestroy() {
    enableBodyScroll(this.modalElement);
  }

  public qtyChanged() {
    (this.qty as UntypedFormControl).valueChanges.subscribe(value => {
      this.item.qty = parseInt(this.qty.value.toString(), 10);
      this.ValidateItemQty(0, true);
      // console.log(value);
    });
  }

  public ValidateItemQty(change: number = 0, keyed: boolean = false, event?) {
    if (!keyed) {
      this.item.qty += change;
      this.qty.patchValue(this.item.qty);
    } else {
      // this.item.qty = parseInt(this.qty.value.toString(), 10);
    }

    // this.item.qty = this.item.qty < 1 ? 1 : this.item.qty;

    if (this.item.qty > this.item.maxqty && !this.confirmRef) {
      this.confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `Maximum quantity available is ${this.item.maxqty}`, text: '', yesno: false, okonly: true },
      });

      this.confirmRef.afterClosed().pipe(first()).pipe(
        finalize(() => this.confirmRef = undefined),
      ).subscribe(() => {
        this.item.qty = this.item.maxqty;
        this.qty.patchValue(this.item.qty);
      });
    } else if (this.item.qty < this.item.minqty && !this.confirmRef) {
      this.confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `Minimum quantity required is ${this.item.minqty}`, text: '', yesno: false, okonly: true },
      });

      this.confirmRef.afterClosed().pipe(first()).pipe(
        finalize(() => this.confirmRef = undefined),
      ).subscribe(() => {
        this.item.qty = this.item.minqty;
        this.qty.patchValue(this.item.qty);
      });
    }
  }

  public setRatio(gi: number, idx: number, isOption: boolean, ratioValue: number) {
    if (this.noneRMS) {
      this.modalItem.modifier_groups[gi].items[idx].ratio = ratioValue;
      this.item.modifier_groups[gi].items[idx].ratio = ratioValue;
    } else {
      if (isOption) {
        this.modalItem.option_groups[gi].items[idx].ratio = ratioValue;
        this.item.option_groups[gi].items[idx].ratio = ratioValue;
      } else {
        this.modalItem.side_groups[gi].items[idx].ratio = ratioValue;
        this.item.side_groups[gi].items[idx].ratio = ratioValue;
      }
    }
  }

  public showExtra(group: OptionalGroup, idx: number) {
    group.items[idx].showExtra = !group.items[idx].showExtra;
  }

  public chooseModifier(i: number, gi?: number) {
    let selected = this.modalItem.modifier_groups[gi].items.filter((o) => o.selected).length;
    if (this.modalItem.modifier_groups[gi].max_limit === 1) {
      const idx = this.modifiersSelectedIndex[gi];

      for (let ind = 0; ind < this.modalItem.modifier_groups[gi].items.length; ind++) {
        this.modalItem.modifier_groups[gi].items[ind].selected = false;
      }

      this.modalItem.modifier_groups[gi].items[i].selected = true;
      selected = this.modalItem.modifier_groups[gi].items.filter((o) => o.selected).length;

      if (idx !== -1 && this.xtMerchant) {
        if (idx === -2) {
          this.modifiersSelectedIndex[gi] = i;
        } else if (idx === i) {
          this.modifiersSelectedIndex[gi] = -2;
        } else {
          /*  this.modalItem.modifier_groups[gi].items[this.modifiersSelectedIndex[gi]].selected = false; */
          this.modifiersSelectedIndex[gi] = i;
        }
      }
    } else {
      // const choosen = this.modalItem.modifier_groups[gi].items.filter(i => i.selected).length;
      if (selected <= this.modalItem.modifier_groups[gi].max_limit) {
        this.modalItem.modifier_groups[gi].items[i].selected = !this.modalItem.modifier_groups[gi].items[i].selected;
        if (this.modalItem.modifier_groups[gi].items[i].selected) {
          selected++;
        } else {
          selected--;
        }
      }
    }

    this.modifiersChosen[gi] = selected;
    if (this.modifierError.length === this.modalItem.modifier_groups.length) {
      /* this.modalItem.modifier_groups[gi].items[i].selected = !this.modalItem.modifier_groups[gi].items[i].selected; */
      // this.selected = this.modalItem.modifier_groups[gi].items.filter((o) => o.selected).length;
      // if (this.selected >= this.modalItem.modifier_groups[gi].min_limit && this.selected <= this.modalItem.modifier_groups[gi].max_limit) {
      if (selected >= this.modalItem.modifier_groups[gi].min_limit && selected <= this.modalItem.modifier_groups[gi].max_limit) {
        this.modifierError[gi] = false;

        if (this.modifierError.indexOf(true) === -1) {
          this.showError = false;
        }
      }
    }

    // this.modifiersChosen[gi] = this.modalItem.modifier_groups[gi].items.filter(i => i.selected).length;
    // this.modifiersChosen[gi] = selected;
  }

  public chooseSide(i: number, gi?: number) {
    let selected = this.modalItem.side_groups[gi].items.filter((o) => o.selected).length;
    if (this.modalItem.side_groups[gi].max_limit === 1) {
      const idx = this.sidesSelectedIndex[gi];

      for (let ind = 0; ind < this.modalItem.side_groups[gi].items.length; ind++) {
        this.modalItem.side_groups[gi].items[ind].selected = false;
      }

      this.modalItem.side_groups[gi].items[i].selected = true;
      selected = this.modalItem.side_groups[gi].items.filter((o) => o.selected).length;

      if (idx !== -1 && this.xtMerchant) {
        if (idx === -2) {
          this.sidesSelectedIndex[gi] = i;
        } else if (idx === i) {
          this.sidesSelectedIndex[gi] = -2;
        } else {
          /*  this.modalItem.side_groups[gi].items[this.sidesSelectedIndex[gi]].selected = false; */
          this.sidesSelectedIndex[gi] = i;
        }
      }
    } else {
      // const choosen = this.modalItem.side_groups[gi].items.filter(i => i.selected).length;
      if (selected <= this.modalItem.side_groups[gi].max_limit) {
        this.modalItem.side_groups[gi].items[i].selected = !this.modalItem.side_groups[gi].items[i].selected;
        if (this.modalItem.side_groups[gi].items[i].selected) {
          selected++;
        } else {
          selected--;
        }
      }
    }

    this.sidesChosen[gi] = selected;
    if (this.optionError.length === this.modalItem.side_groups.length) {
      /* this.modalItem.side_groups[gi].items[i].selected = !this.modalItem.side_groups[gi].items[i].selected; */
      // this.selected = this.modalItem.side_groups[gi].items.filter((o) => o.selected).length;
      // if (this.selected >= this.modalItem.side_groups[gi].min_limit && this.selected <= this.modalItem.side_groups[gi].max_limit) {
      if (selected >= this.modalItem.side_groups[gi].min_limit && selected <= this.modalItem.side_groups[gi].max_limit) {
        this.optionError[gi] = false;

        if (this.optionError.indexOf(true) === -1) {
          this.showError = false;
        }
      }
    }

    // this.sidesChosen[gi] = this.modalItem.side_groups[gi].items.filter(i => i.selected).length;
    // this.sidesChosen[gi] = selected;
  }

  // sideChange(i: number, gi?: number) {
  //   // this.modalItem.side_groups[gi].items[i].selected = !this.modalItem.side_groups[gi].items[i].selected;
  //   if (this.optionError.length === this.modalItem.side_groups.length) {
  //     this.selected = this.modalItem.side_groups[gi].items.filter(o => o.selected).length;
  //     if (this.selected >= this.modalItem.side_groups[gi].min_limit && this.selected <= this.modalItem.side_groups[gi].max_limit) {
  //       this.optionError[gi] = false;

  //       if (this.optionError.indexOf(true) === -1) {
  //         this.showError = false;
  //       }
  //     }
  //   }
  // }

  public chooseOption(i: number, gi?: number) {
    if (this.item.maxsides === 1 && !this.xtMerchant) {

      if (this.modalItem.option_groups[gi].max_limit === 1) {
        for (let ind = 0; ind < this.modalItem.option_groups[gi].items.length; ind++) {
          this.modalItem.option_groups[gi].items[ind].selected = false;
        }
      }

      this.modalItem.option_groups[gi].items[i].selected = true;

      if (this.selectedIndex === -1) {
        this.selectedIndex = i;
        this.selected++;
      } else if (this.selectedIndex === i) {
        this.selectedIndex = -1;
        this.selected--;
      } else {
        // this.modalItem.sides[this.selectedIndex].selected = false;
        this.selectedIndex = i;
      }
    }

    /* this.modalItem.option_groups[gi].items[i].selected = !this.modalItem.option_groups[gi].items[i].selected; */
  }

  public extraSelected(array: any[], index: number, itmIndex: number, groupIndex: number, isSideGroup: boolean) {
    array.forEach((e) => {
      if (e.selected) {
        e.selected = false;
      }
    });

    array[index].selected = true;

    if (isSideGroup) {
      this.modalItem.side_groups[groupIndex].items[itmIndex].selected = true;
      this.chooseSide(itmIndex, groupIndex);
    } else {
      this.modalItem.option_groups[groupIndex].items[itmIndex].selected = true;
      this.chooseOption(itmIndex, groupIndex);
    }
  }

  public optionChange() {
    if (!this.xtMerchant) {
      this.selected = this.modalItem.options.filter((o) => o.selected).length;
      if (this.selected === this.item.maxsides) {
        this.showError = false;
      }
    }
  }

  public submit() {
    // if (this.xtMerchant) {
    this.item.sides = [];
    this.item.options = [];
    // this.item.modifier_groups = [];
    this.item.modifiers = [];

    this.optionError = [];
    this.modifierError = [];

    this.showError = false;

    if (this.modalItem.option_groups && this.modalItem.option_groups.length > 0) {
      this.modalItem.option_groups.forEach((g) => {
        let l = 0;
        g.items.forEach((s) => {
          if (s.selected) {
            l++;
            s.title = s.title.trim();
            s.qty = this.item.qty;

            s.extra = s.extra.filter(e => e.selected);
            if (s.extra.length > 0) { s.price = s.extra[0].fee; }
            console.log(s.ratio);
            if (s.ratio !== 0 && s.ratio !== -99) {
              s.price = s.price / 2;
            }
            this.item.options.push(s);
          }
        });

        // this.showError = false;
        // if (l < g.min_limit || l > g.max_limit) {
        //   this.optionError.push(true);
        //   this.showError = true;
        // } else {
        //   this.optionError.push(false);
        // }
      });
      // } else {
      //   this.showError = false;
    }

    if (this.modalItem.side_groups && this.modalItem.side_groups.length > 0 && !this.noneRMS) {
      this.modalItem.side_groups.forEach((g) => {
        let l = 0;
        g.items.forEach((s) => {
          if (s.selected) {
            l++;
            s.title = s.title.trim();
            s.qty = this.item.qty;

            s.extra = s.extra.filter(e => e.selected);
            if (s.extra.length > 0) { s.price = s.extra[0].fee; }
            if (s.ratio !== 0 && s.ratio !== -99) {
              s.price = s.price / 2;
            }
            this.item.sides.push(s);
          }
        });

        if (l < g.min_limit || l > g.max_limit) {
          this.optionError.push(true);
          this.showError = true;
        } else {
          this.optionError.push(false);
        }
      });
    } else {
      // this.showError = false;
    }

    if (this.noneRMS && this.modalItem.modifier_groups && this.modalItem.modifier_groups.length > 0) {
      this.modalItem.modifier_groups.forEach((g) => {
        const l = g.items.filter(m => m.selected).length;

        if (g.max_limit > 0 && (l < g.min_limit || l > g.max_limit)) {
          this.modifierError.push(true);
          this.showError = true;
        } else {
          this.modifierError.push(false);
        }

        if (!this.showError) {
          g.items.forEach((s) => {
            if (s.selected) {
              // l++;
              s.title = s.title.trim();
              s.qty = this.item.qty;

              s.extra = s.extra.filter(e => e.selected);
              if (s.extra.length > 0) { s.price = s.extra[0].fee; }
              if (s.ratio !== 0 && s.ratio !== -99) {
                s.price = s.price / 2;
              }
              this.item.modifiers.push(s);
            }
          });
        }
      });
    } else {
      // this.showError = false;
    }

    // }
    // else {
    //   if (this.modalItem.sides.length === 0) {
    //     this.item.maxsides = 0;
    //   } else {
    //     this.selected = this.modalItem.sides.filter(o => o.selected).length;
    //   }
    // }
    console.log(this.item);

    if (!this.showError) {
      console.log(this.item);
      this.dialogRef.close(this.item);
    }

    enableBodyScroll(this.modalElement);
  }

  public close() {
    enableBodyScroll(this.modalElement);
    this.dialogRef.close();
  }

}
