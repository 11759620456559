<div class="body" id="item-modal" style="padding: 0 !important; overflow-x: hidden;">
    <!-- <div class="modal-dialog modal-lg" role="document"> -->
    <!-- <div class="modal-content"> -->
    <div class="row" style="margin-top: 15px;">
        <h3 class="col-10"><b style="padding-left: 25px;">Address Selection</b></h3>
        <div class="col-2">
            <a class="nav-link float-right" style="color: black;" data-dismiss="modal" [routerLink]="[]" (click)="dialogRef.close();">
                <i class="fas fa-times fa-lg"></i>
            </a>
        </div>
    </div>
    <!-- <div id="divItemDetailClose" fxLayoutAlign="start center">
                <a class="nav-link float-right" data-dismiss="modal" [routerLink]="[]" (click)="close()">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div> -->
    <!-- <div class="modal-header">
        <div class="row">
          <h1 class="mods-item-title col-12">Address Selection</h1>
        </div>
      </div> -->
    <div class="modal-body">
        <div class="container-fluid px-0">
            <div class="row" style="margin-top: 5px; margin-bottom: 5px; font-size: large;" *ngFor="let a of info.addresses;let idx=index">
                <div class="form-check mods-desc">
                    <div class="col-12">
                        <input class="form-check-input" type="checkbox" name="mod_{{idx}}" id="mod_{{idx}}" (click)="selectAddress(idx)" [checked]="a.isSelected" />
                        <label class="form-check-label" for="mod_{{idx}}">{{a.street1}} {{a.street2}} {{a.city}}, {{a.state}} -
              {{a.postalCode}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer"> -->
    <div class="container-fluid" style="margin-bottom: 15px;">
        <div class="row">
            <div class="col-6" style="align-self: center;">
                <button type="button" class="btn btn-success w-100" data-dismiss="modal" (click)="close()" autofocus>Cancel</button>
            </div>
            <div class="col-6" style="align-self: center;">
                <button type="button" class="btn btn-success w-100" data-dismiss="modal" [disabled]="selectedIndex === -1" (click)="updateAddress()" autofocus>Confirm</button>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
</div>
<!-- </div> -->