<div class="container center">
    <div class="row">
        <div class="col-12">
            <a class="nav-link float-right" style="color: black;" data-dismiss="modal" [routerLink]="[]" (click)="Cancel();">
                <i class="fas fa-times fa-lg"></i>
            </a>
        </div>
    </div>
    <form [formGroup]="passwordResetForm" class="shadow p-3 mb-5 bg-white rounded" (ngSubmit)="passwordResetForm.valid && resetPassword(passwordResetForm)">
        <input class="form-control form-control-sm" placeholder="Enter Email" type="text" id="email" formControlName="email" (ngModelChange)="formChange(passwordResetForm)" required data-test="Password-ResetEnterEmailInput"/>
        <mat-error *ngIf="!email.valid && email.hasError('required') && (email.dirty || email.touched)">
            Email Required
        </mat-error>
        <mat-error id="errorMsg" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
        </mat-error>
        <div id="successMsg" *ngIf="successMessage">
            <span>{{successMessage}}</span>
        </div>
        <button name="submit" type="submit" [disabled]="passwordResetForm.invalid" class="btn btn-success w-100" data-test="Password-ResetResetPasswordButton">Reset Password</button>
    </form>
</div>