import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Item } from 'projects/common/src/lib/item';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Order, OrderStatus } from 'projects/common/src/lib/order';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { OrderService } from 'projects/services/src/lib/order.service';

declare function connect(ip, order, ticket, barcode_url): any;

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReceiptDialogComponent implements OnInit {
  public order: Order;
  public merchant: Merchant;

  public ELEMENT_DATA: Item[] = [];
  public displayedColumns: string[] = ['qty', 'desc', 'price'];
  public dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  constructor(
    public google: GoogleApiService,
    private orderService: OrderService,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<ReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // console.log(this.data);
    this.order = this.data.order;
    this.merchant = this.data.merchant;

    this.order.items.forEach(i => {
      i.leftMods = [];
      i.rightMods = [];
      i.wholeMods = [];

      if (i.ratioGraphic !== 'none') {
        if (i.sides && i.sides.length > 0) {
          i.sides.forEach(s => {
            switch (s.ratio) {
              case -1:
                i.leftMods.push(s);
                break;
              case 1:
                i.rightMods.push(s);
                break;
              default:
                i.wholeMods.push(s);
                break;
            }
          });
        }

        if (i.options && i.options.length > 0) {
          i.options.forEach(s => {
            switch (s.ratio) {
              case -1:
                i.leftMods.push(s);
                break;
              case 1:
                i.rightMods.push(s);
                break;
              default:
                i.wholeMods.push(s);
                break;
            }
          });
        }

        if (i.modifiers && i.modifiers.length > 0) {
          i.modifiers.forEach(s => {
            switch (s.ratio) {
              case -1:
                i.leftMods.push(s);
                break;
              case 1:
                i.rightMods.push(s);
                break;
              default:
                i.wholeMods.push(s);
                break;
            }
          });
        }
      }
    });

    this.ELEMENT_DATA = this.order.items;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }


  public completeOrder(index) {
  }

  public voidOrder(index) {

  }

  print(ticket) {
    this.authService.updatedProgressBarStatus(true);

    const data = {
      merchantid: this.merchant.merchantNumber1,
      orderno: this.order.orderno,
      receipt: ticket
    };

    this.orderService.printOrder(data).subscribe((res: any) => {
      this.authService.updatedProgressBarStatus(false);
    });
  }

  complete() {
    this.orderService.completeOrder(this.order).subscribe(result => {
      console.log(result);
      this.order.orderstatus = OrderStatus.Completed;
    });
  }

  void() {
    this.orderService.voidOrder(this.order).subscribe(result => {
      // console.log(result);
    this.order.orderstatus = OrderStatus.Voided;
    });
  }

  close() {
    this.dialogRef.close();
  }

}
