import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from 'projects/common/src/lib/user';

@Injectable()
export class HttpGlobalInterceptor implements HttpInterceptor {

  user: User = new User();

  constructor(
    private authenticationservice: AuthenticationService, private localStorage: StorageMap
  ) {
    this.localStorage.get('user').subscribe(async (user: User) => {
      if (user) {
        this.user = user;
      }
    });

    this.authenticationservice.CurrentUserEmitter.subscribe(async (u: User) => {
      this.user = u;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
        let token = this.authenticationservice.AuthToken ? this.authenticationservice.AuthToken.trim() : undefined;

        if (this.user) {
          if (!token) {
            token = this.user.access_token;
          }
        }

        if (!token) {
          if (request.headers.has('authorization')) {
            token = request.headers.get('authorization');
          }
        }

        if (!request.url.includes('checkip.amazonaws.com/') && !request.url.includes('api.ipify.org')) {
          if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.append('Content-Type', 'application/json') });
            request = request.clone({ headers: request.headers.append('Accept', 'application/json') });
          }
        }

        if (!request.url.includes('checkip.amazonaws.com/') && !request.url.includes('api.ipify.org')) {
          if (token) {
            if (!request.headers.has('authorization')) {
              request = request.clone({ headers: request.headers.append('authorization', token) });
            } else {
              request = request.clone({ headers: request.headers.set('authorization', token) });
            }
          }
        }

      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            let data = {};
            data = {
              reason: error && error.error.reason ? error.error.reason : '',
              status: error.status
            };
            // this.errorDialogService.openDialog(data);
            return throwError(error);
          })
        );
    } catch (error) {

    }

  }

}
