export enum loginStep {
    login = 0,
    passwordResetRequest = 1,
    passwordReset = 2
}

export class LogIn {
    public email = '';
    public username = '';
    public password = '';
    public application = '';
}
