import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-apple-pay-verification-component',
  templateUrl: './apple-pay-verification.component.html',
  styleUrls: ['./apple-pay-verification.component.scss']
})
export class ApplePayVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
