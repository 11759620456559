<div class="body" id="item-modal" style="padding: 0 !important;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div id="divItemDetailClose" fxLayoutAlign="start center">
                <a class="nav-link float-right" data-dismiss="modal" [routerLink]="[]" (click)="close()">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
            <div class="modal-header">
                <div class="row">
                    <h1 class="mods-item-title col-12">Item Image</h1>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid px-0">
                    <div class="row" *ngIf="image.url !== ''">
                        <div class="col-12 mods-title">
                            Preview Image
                        </div>
                        <div fxFlex="100%" style="text-align: center;">
                            <img [src]="image.url.search('/') >= 0 ? image.url : image.base64" width="600" height="450" alt="">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mods-title">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Image URL</mat-label>
                                <input [ngModelOptions]="{standalone: true}" matInput type="text" name="ImageURL" placeholder="Image URL" [(ngModel)]="image.url" data-test="Image-DialogImageURLInput"/>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <button mat-button (click)="close()" cdkFocusInitial data-test="Image-DialogOkButton">Ok</button>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style="align-self: center;">
                            <div class="input-group">
                                <span class="input-group-btn input-group-prepend border">
                                    <button type="button" class="btn btn-default btn-number" [disabled]="item.qty <= 1"
                                        (click)="ValidateItemQty(-1)">
                                        <i class="fas fa-minus" aria-hidden="true"></i>
                                    </button>
                                </span>
                                <input type="number" name="quant" id="quant" class="form-control input-number text-center" value="{{item.qty}}" (blur)="ValidateItemQty(item.qty)" [(ngModel)]="item.qty" min="1" max="{{item.maxqty}}" />
                                <span class="input-group-btn input-group-append border">
                                    <button type="button" class="btn btn-default btn-number"
                                        [disabled]="item.qty >= item.maxqty" (click)="ValidateItemQty(1)">
                                        <i class="fas fa-plus" aria-hidden="true"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div class="col-1 hidden-xs-up not-visible" style="font-size: 9px;">&nbsp;</div>
                        <div *ngIf="!item.item86" class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" style="align-self: center;">
                            <button *ngIf="!isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1" data-dismiss="modal" autofocus>
                                Add {{item.qty}} {{item.title}}{{(item.qty != 1 ? 's' : '')}} to Order -
                                ${{(item.qty*(item.price+sidesPrice+optionsPrice)).toFixed(2)}}
                            </button>
                            <button *ngIf="isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1" data-dismiss="modal" autofocus>
                                Update {{item.title}} from Order -
                                ${{(item.qty*(item.price+sidesPrice+optionsPrice)).toFixed(2)}}
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>