import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) {

  }

  public async getIPAddress(): Promise<string> {
    return new Promise((resolve, reject) => {

      const httpOptionsPlain = {
        headers: new HttpHeaders({
          Accept: 'text/plain',
          'Content-Type': 'text/plain'
        }),
        responseType: "text" as "json"
      };

      this.http.get("http://checkip.amazonaws.com/", httpOptionsPlain ).subscribe((res: any) => {
        resolve(res);
      }, (error: any) => {
        resolve('');
      });
    });
  }

}
