import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaterialDesignModule } from 'projects/common/src/lib/material-design/material-design.module';
import { StoreComponent } from './components/store/store.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { Http404Component } from './components/http404/http404.component';
import { StoreCheckoutComponent } from './components/store-checkout/store-checkout.component';
import { ReceiptsModule } from 'projects/receipts/src/app/app.module';
import { ApplePayVerificationComponent } from './components/apple-pay-verification/apple-pay-verification.component';
import { ScanToPayComponent } from './components/scan-to-pay/scan-to-pay.component';
import { EgiftComponent } from './components/egift/egift.component';
import { MerchantSettingsComponent } from './components/merchant-settings/merchant-settings.component';
import { environment } from '../environments/environment';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { ConfirmedComponent } from './components/confirmed/confirmed.component';

let routes: Routes = [];

/* .well-known file is handled by nginx now */

routes = [
  { path: 'admin/:merchantnumber', pathMatch: 'full', loadChildren: () => import('./components/merchant-settings/merchant-settings.module').then(m => m.MerchantSettingsModule) },
  { path: 'egift/activate/:activationcode', pathMatch: 'full', component: EgiftComponent },
  {
    path: 'registration', component: RegistrationComponent,
  },
  {
    path: ':storename', component: StoreComponent
  },
  { path: ':storename/merchant-settings', loadChildren: () => import('./components/merchant-settings/merchant-settings.module').then(m => m.MerchantSettingsModule) },
  { path: 'passwordreset/:token', loadChildren: () => import('./components/password-reset-confirmation/password-reset-confirmation.module').then(m => m.PasswordResetConfirmationModule) },
  { path: ':storename/checkout', component: StoreCheckoutComponent, pathMatch: 'full' },
  { path: 'receipt/:receiptno', loadChildren: () => import('projects/receipts/src/app/app.module').then(m => m.ReceiptsModule) },
  { path: 'scantopay/:mid/:sid', component: ScanToPayComponent },
 /*  { path: '.well-known/apple-developer-merchantid-domain-association.txt', component: ApplePayVerificationComponent }, */
  { path: 'healthcheck', component: HealthcheckComponent },
  { path: 'confirmed/:email', pathMatch: 'full', component: ConfirmedComponent },
  { path: 'pagenotfound', component: Http404Component, pathMatch: 'full' },
];

if (!environment.production) {
    routes.push({ path: '**', redirectTo: 'jackspizza' });
}

@NgModule({
  imports: [
    MaterialDesignModule,
    RouterModule.forRoot(routes),
    ReceiptsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
