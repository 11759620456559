import { Injectable, Output, EventEmitter } from '@angular/core';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Banner } from 'projects/common/src/lib/banner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    public http: HttpClient,
    public storage: StorageMap,
    public globalService: GlobalService) {
  }

  public getAllBanners(merchant: Merchant, pageno: number, lastObjectId: string): Observable<any> {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.get<Banner[]>('/api/banner/' + pageno + '/' + lastObjectId, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getAllBannersByMerchantNumber(merchant: Merchant, pageno: number, lastObjectId: string): Observable<any> {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      if (!lastObjectId) {
        return this.http.get<Banner[]>('/api/banners/m/' + merchant.merchantNumber1 + '/' + pageno, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      } else {
        return this.http.get<Banner[]>('/api/banners/m/' + merchant.merchantNumber1 + '/' + pageno + '/' + lastObjectId, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      }
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getBanners(merchant: Merchant, pageno: number, lastObjectId: string): Observable<any> {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      if (!lastObjectId) {
        return this.http.get<Banner[]>('/api/banner/' + merchant.businessName + '/' + pageno, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      } else {
        return this.http.get<Banner[]>('/api/banner/' + merchant.businessName + '/' + pageno + '/' + lastObjectId, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      }
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getGlobalBanners(merchant: Merchant, pageno: number, lastObjectId: string): Observable<any> {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.get<Banner[]>('/api/banners/global', httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }


  public getActiveBanners(merchant: Merchant, pageno: number, lastObjectId: string): Observable<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      if (!lastObjectId) {
        return this.http.get<Banner[]>('/api/banners/ma/' + merchant.merchantNumber1 + '/' + pageno, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      } else {
        return this.http.get<Banner[]>('/api/banners/ma/' + merchant.merchantNumber1 + '/' + pageno + '/' + lastObjectId, httpOptions)
          .pipe(
            catchError(this.handleError)
          );
      }
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getBannerById(merchant: Merchant, id: string): Observable<Banner> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.get<Banner>('/api/banner/' + id, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public addBanner(banner: Banner, merchant: Merchant): Observable<Banner> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.post<Banner>('/api/banner', banner, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public deleteBanner(bannerid: string, merchant: Merchant): Observable<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.delete<any>('/api/banner/' + bannerid, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public updateBanner(banner: Banner, merchant: Merchant): Observable<Banner> {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'applicationkey': merchant.applicationKey,
          'merchantnumber': merchant.merchantNumber1
        })
      };

      return this.http.put<Banner>('/api/banner', banner, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
