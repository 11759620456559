import { GiftCardOffer } from './giftcard-offer';
import { OperationHours } from './operation-hours';
import { DeliveryZone } from './delivery-zone';
import "reflect-metadata";

import { PROPERTY_METADATA_KEY } from "./constants";
import { PropertyMetadata } from "./PropertyMetadata";


export class MerchantSettings {
  @PropertyMetadata({ description: 'Is 3PDS Enabled' })
  public Is3PDSEnabled: boolean;
  @PropertyMetadata({ description: 'Active' })
  public Active: boolean;
  @PropertyMetadata({ description: 'Customer Service Fee Is Dollar' })
  public CSFIsDollar: boolean;
  @PropertyMetadata({ description: 'Customer Service Fee', shortdescription: 'Fee Amount' })
  public CustomerServiceFee: number;
  @PropertyMetadata({ description: 'Enable Customer Service' })
  public CustomerServiceFeeOn: boolean;
  @PropertyMetadata({ description: 'Convenience Fee', shortdescription: 'Fee Amount' })
  public ConvenienceFee: number;
  @PropertyMetadata({ description: 'Is Convenience Fee A Dollar Amount' })
  public FeeDollar: boolean;
  @PropertyMetadata({ description: 'Show Miles' })
  public ShowMiles: boolean;
  @PropertyMetadata({ description: 'With Fee' })
  public WithFee: boolean;
  @PropertyMetadata({ description: 'With Tax' })
  public WithTax: boolean;
  @PropertyMetadata({ description: 'Email Receipts' })
  public EmailReceipt: boolean;
  @PropertyMetadata({ description: 'Email Print Receipts' })
  public EmailPrintReceipt: boolean;
  // public Fee: number; // Not used
  @PropertyMetadata({ description: 'Driver Fee' })
  public FeeToDriver: number;
  // public MinOrder: number;
  @PropertyMetadata({ description: 'Delivery Zones' })
  public DeliveryZones: DeliveryZone[];
  @PropertyMetadata({ description: 'Allow Instructions' })
  public instructionAllowed: boolean;
  @PropertyMetadata({ description: 'Instructions' })
  public instruction: string;
  @PropertyMetadata({ description: 'Tax Rate' })
  public taxrate = 0.00;
  @PropertyMetadata({ description: 'Chargeback Type' })
  public chargeback_type: string;
  @PropertyMetadata({ description: 'Chargeback Amount', shortdescription: 'Amount' })
  public chargeback: number;
  @PropertyMetadata({ description: 'Cashback Type' })
  public cashback_type: string;
  @PropertyMetadata({ description: 'Cashback Amount', shortdescription: 'Amount' })
  public cashback: number;
  @PropertyMetadata({ description: 'Service Fee Type', shortdescription: 'Fee Type' })
  public servicefee_type: string;
  @PropertyMetadata({ description: 'Service Fee Amount', shortdescription: 'Fee Amount' })
  public servicefee: number;
  @PropertyMetadata({ description: 'Delivery Fee Type', shortdescription: 'Fee Type' })
  public deliveryfee_type: string;
  @PropertyMetadata({ description: 'Display Amounts?' })
  public displayExtraAmounts: boolean;

  @PropertyMetadata({ description: 'Delivery Fee' })
  public deliveryfee = {
    fee: 0,
    isPercentage: false
  };

  @PropertyMetadata({ description: 'Minimum Order Amount', shortdescription: 'Min Amount' })
  public minorderamt: number;
  @PropertyMetadata({ description: 'Delivery Wait Time', shortdescription: 'Wait Time' })
  public delWaittime: number;
  @PropertyMetadata({ description: 'Pickup Wait Time', shortdescription: 'Wait Time' })
  public pickupWaittime: number;
  @PropertyMetadata({ description: 'Delivery Hours' })
  public deliveryHours: OperationHours[];
  @PropertyMetadata({ description: 'Pickup Hours' })
  public pickupHours: OperationHours[];
  public cart_cross_sell: number;
  public cart_cross_sell_label: string;
  public cart_cross_sell_prefix: string;
  public delTracking: boolean;
  public gridview: boolean;
  public giftcard_offerings: GiftCardOffer[];
  public customErrorMessage = '';
  @PropertyMetadata({ description: 'Tips' })
  public tipSettings = new TipSettings();

  public constructor() {
    this.Is3PDSEnabled = false;
    this.Active = true;
    this.CSFIsDollar = false;
    this.CustomerServiceFee = 0;
    this.CustomerServiceFeeOn = false;
    this.ConvenienceFee = 0;
    this.FeeDollar = false;
    this.ShowMiles = false;
    this.WithFee = false;
    this.WithTax = false;
    this.EmailReceipt = false;
    this.EmailPrintReceipt = false;
    this.FeeToDriver = 0;
    this.instructionAllowed = true;
    this.instruction = '';

    this.cart_cross_sell = 0;
    this.cart_cross_sell_label = '';
    this.cart_cross_sell_prefix = '';
    this.taxrate = 0.00;
    // this.hoursOfOperation = new Array<OperationHours>(7); // 0-7 -> Sunday-Saturday
    this.deliveryHours = [];

    for (let i = 0; i < 7; i++) {
      let oh = new OperationHours();
      oh.openHour = '8:00 AM';
      oh.closeHour = '11:59 PM';
      this.deliveryHours.push(new OperationHours());
    }

    this.pickupHours = [];

    for (let i = 0; i < 7; i++) {
      let oh = new OperationHours();
      oh.openHour = '8:00 AM';
      oh.closeHour = '11:59 PM';
      this.pickupHours.push(new OperationHours());
    }

    this.gridview = true;
    this.delTracking = false;
    this.delWaittime = 20;
    this.pickupWaittime = 20;
    this.chargeback_type = '';
    this.chargeback = 0.00;
    this.cashback_type = '';
    this.cashback = 0.00;
    this.servicefee_type = '';
    this.servicefee = 0.00;
    this.deliveryfee_type = '';
    this.deliveryfee = {
      fee: 0,
      isPercentage: false
    };
    this.DeliveryZones = [];
    this.minorderamt = 0.00;
    this.giftcard_offerings = [];
    this.tipSettings = new TipSettings();
    this.customErrorMessage = '';
    this.displayExtraAmounts = false;
  }
}

export class TipSettings {
  public enable: boolean;
  public isPercentage: boolean;
  public defaultIndex: number;
  public maxAmount: string;
  public tipAmounts: TipAmount[];

  constructor() {
    this.enable = true;
    this.isPercentage = false;
    this.defaultIndex = 0;
    this.maxAmount = '200.00';
    this.tipAmounts = [];
    for (let i = 0; i < 6; i++) {
      this.tipAmounts.push(new TipAmount());
    }
    this.tipAmounts[5].amount = "Custom";
  }
}

export class TipAmount {
  public enable: boolean;
  public amount: string;

  constructor() {
    this.enable = true;
    this.amount = '0.00';
  }
}

/* export function displayName(name: string) {
  return (target: Object | any, key: PropertyKey): any => {
    const descriptor = {
      get(this: any) {
        const propertyName = key;

        if (!this[propertyName]) {
          this[propertyName] = name;
        }

        return this[propertyName];
      },
      set() {
        const propertyName = key;
        this[propertyName] = name;
      },
      enumerable: true,
      configurable: true,
    };

    Object.defineProperty(target, name, descriptor);
  };
}

function displayName2(name: string) {
  return function (target: Object, propertyKey: string) {
    let value = name;
    const getter = function () {
      return value;
    };
    const setter = function (newVal: string) {
      value = name;
    };
    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true
    });
  }
}
 */
