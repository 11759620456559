export class MetaLogEntry {
  public application: string;
  public merchantNumber: string;
  public client_ip: string;
  public file: string;
  public method: string;
  public error: string;
  public text: string;

  constructor() {
    this.application = '';
    this.merchantNumber = '';
    this.client_ip = '';
    this.file = '';
    this.method = '';
    this.error = '';
    this.text = ''
  }
}

export class LogEntry {
  public entryType: string = 'Info';
  public entry: MetaLogEntry;

  constructor() {
    this.entryType = 'Info';
    this.entry = new MetaLogEntry();
  }
}
