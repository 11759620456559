import { EventEmitter, Injectable, Output, OnInit } from '@angular/core';
import { Resolve } from '@angular/router';
import { CrossStorageClient } from 'cross-storage';

@Injectable({
  providedIn: 'root'
})
export class CrossStorageService {

  storage: CrossStorageClient;
  connected: boolean;

  constructor() {

  }

  async connect(storageUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.storage = new CrossStorageClient(storageUrl, { timeout: 20000, frameId: 'storageFrame' });

        this.storage.onConnect().
          then(() => {
            this.connected = true;
            resolve(true);
          }).catch((error: any) => {
            console.log('onConnect() error', error);
            this.connected = false;
            resolve(false);
          });
      } catch (error) {
        console.log(error);
        this.connected = false;
        return reject(false);
      }
    });
  }

  getKey(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        // let storage = new CrossStorageClient('http://localhost:3000/index.html', { timeout: 5000 });

        if(this.connected) {
          resolve(this.storage.get(key));
        } else {
          reject({"message": "not connected to storage"});
        }

      } catch (error) {
        reject(error);
      }
    });
  }

  setKey(key: string, value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        // let storage = new CrossStorageClient(storageUrl, { timeout: 10000 });

        if(this.connected) {
          this.storage.set(key, value);
          resolve(true);
        } else {
          reject({"message": "not connected to storage"});
        }

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  deleteKey(key: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        // let storage = new CrossStorageClient(storageUrl, { timeout: 10000 });

        if(this.connected) {
          this.storage.del(key);
          resolve(true);
        } else {
          reject({"message": "not connected to storage"});
        }

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  close(): void {
    if(this.storage) {
      this.storage.close();
    }
  }
}

