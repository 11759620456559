import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { StorageMap } from '@ngx-pwa/local-storage';
// import { GlobalService } from './global.service';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(public http: HttpClient) { }

  public sendMessage(message: any): Observable<any> {
    try {
      return this.http.post<any>('/api/sms/message', message, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public saveTemplate(template: any): Observable<any> {
    try {
      return this.http.post<any>('/api/sms/templates', template, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getTemplates(merchantid: string): Observable<any> {
    try {
      return this.http.get<any>('/api/sms/templates/' + merchantid, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
