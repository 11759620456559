import { Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Regex } from 'projects/common/src/lib/regex';
import { Register } from 'projects/common/src/lib/register';
import { Merchant } from 'projects/common/src/lib/merchant';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { SmsService } from 'projects/services/src/lib/sms.service';
import { User } from 'projects/common/src/lib/user';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss']
})
export class RegistrationDialogComponent implements OnInit, OnDestroy {

  @ViewChild('registrationEmail') registrationEmail: ElementRef;
  public regexList = new Regex();
  public searchTimeout: any;
  public submitted = false;

  public streetMatches: any[];
  public disableSubmit = true;
  public registration: Register = new Register();
  public us_phone_regex = this.regexList.us_phone_regex;
  public loginError: string;
  storename = '';
  modalElement: any;
  merchant: Merchant;
  enableRecaptcha = this.data && this.data.config ? this.data.config.enableRecaptcha: true;

  public states: string[] = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
  ];

  public formRegistration = new UntypedFormGroup({
    firstname: new UntypedFormControl(this.registration.firstname, [Validators.required]),
    lastname: new UntypedFormControl(this.registration.lastname, [Validators.required]),
    street1: new UntypedFormControl(this.registration.addresses[0].street1, [Validators.required]),
    street2: new UntypedFormControl(this.registration.addresses[0].street2, []),
    city: new UntypedFormControl(this.registration.addresses[0].city, [Validators.required]),
    state: new UntypedFormControl(this.registration.addresses[0].state, [Validators.required]),
    postalcode: new UntypedFormControl(this.registration.addresses[0].postalCode, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(10)
    ]),
    email: new UntypedFormControl(this.registration.email, [Validators.required, Validators.pattern(this.regexList.email)]),
    phone: new UntypedFormControl(this.registration.phone, [Validators.required, Validators.pattern(this.regexList.us_phone_regex)]),
    fax: new UntypedFormControl(this.registration.fax, []),
    password: new UntypedFormControl(this.registration.password, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15)
    ]),
    isAutoNotification: new UntypedFormControl(this.registration.isAutoNotification, []),
    isAutoSubcribe: new UntypedFormControl(this.registration.isAutoSubcribe, []),
    passwordconfirm: new UntypedFormControl(this.registration.passwordconfirm, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15),
    ]),
    recaptcha: new UntypedFormControl(null, this.enableRecaptcha === true ? [Validators.required] : [])
  });

  get firstname(): any { return this.formRegistration.get('firstname'); }
  get lastname(): any { return this.formRegistration.get('lastname'); }
  get street1(): any { return this.formRegistration.get('street1'); }
  get street2(): any { return this.formRegistration.get('street2'); }
  get city(): any { return this.formRegistration.get('city'); }
  get state(): any { return this.formRegistration.get('state'); }
  get postalcode(): any { return this.formRegistration.get('postalcode'); }
  get email(): any { return this.formRegistration.get('email'); }
  get phone(): any { return this.formRegistration.get('phone'); }
  get fax(): any { return this.formRegistration.get('fax'); }
  get password(): any { return this.formRegistration.get('password'); }
  get passwordconfirm(): any { return this.formRegistration.get('passwordconfirm'); }
  get recaptcha(): any { return this.formRegistration.get('recaptcha'); }

  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorage: StorageMap,
    private google: GoogleApiService,
    private authSvc: AuthenticationService,
    private smsService: SmsService,
    private sb: MatSnackBar,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.localStorage.get('merchant').subscribe(async (merchant: Merchant) => {
      this.merchant = merchant;
      this.storename = merchant.businessName;
    });

/*     this.localStorage.get('storename').subscribe((store: string) => {
      if (store) {
        this.storename = store;
      } else {
        this.storename = this.route.snapshot.params['storename'];
        this.localStorage.set('storename', this.storename).subscribe(() => { });
      }
    }); */

    // do not place disableBodyScroll in constructor, it intereferes with loading the reactive form
    this.modalElement = document.querySelector('#body-content');
    disableBodyScroll(this.modalElement);

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    enableBodyScroll(this.modalElement);
  }

  public addressLookUp(): void {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.google.search(this.formRegistration.controls.street1.value).subscribe(d => {
        this.streetMatches = d;
      }, error => {
        console.log('error:', error);
      });
    }, 500);
  }

  selectedAddress(addr: any): void {

    if (!this.registration) {
      this.registration = new Register();
    }

    this.registration.addresses[0].street2 = '';

    for (let i = 0; i < addr.address_components.length; i++) {
      switch (addr.address_components[i].types[0]) {
        case 'street_number':
          this.registration.addresses[0].street1 = addr.address_components[i].short_name;
          break;
        case 'route':

          this.registration.addresses[0].street1 += ' ' + addr.address_components[i].short_name;

          this.formRegistration.patchValue({
            street1: this.registration.addresses[0].street1.trim()
          });
          /*           (this.formRegistration.controls.street).setValue(addr.address_components[i].short_name); */

          break;
        case 'locality':
          (this.formRegistration.controls.city).setValue(addr.address_components[i].short_name);
          this.registration.addresses[0].city = addr.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          (this.formRegistration.controls.state).setValue(addr.address_components[i].short_name);
          this.registration.addresses[0].state = addr.address_components[i].short_name;
          break;
        case 'country':

          break;
        case 'postal_code':
          (this.formRegistration.controls.postalcode).setValue(addr.address_components[i].short_name);
          this.registration.addresses[0].postalCode = addr.address_components[i].short_name;
          break;
      }
    }
  }

  public formChange(form: any): void {
    try {
      // this.registration.businessName = form.controls.businessName.value;
      this.registration.firstname = form.controls.firstname.value;
      this.registration.lastname = form.controls.lastname.value;
      this.registration.addresses[0].street1 = form.controls.street1.value;
      this.registration.addresses[0].street2 = form.controls.street2.value;
      this.registration.addresses[0].city = form.controls.city.value;
      this.registration.addresses[0].state = form.controls.state.value;
      this.registration.addresses[0].postalCode = form.controls.postalcode.value;
      this.registration.email = form.controls.email.value;
      this.registration.phone = form.controls.phone.value;
      this.registration.isAutoNotification = form.controls.isAutoNotification.value;
      this.registration.isAutoSubcribe = form.controls.isAutoSubcribe.value;
      this.registration.fax = form.controls.fax.value;
      this.registration.password = form.controls.password.value;
      this.registration.passwordconfirm = form.controls.passwordconfirm.value;
    } catch (error) {
      console.log(error);
    }
  }

  public Cancel() {
    enableBodyScroll(this.modalElement);
    this.dialogRef.close();
  }

  public register(frm?: UntypedFormGroup) {

    this.submitted = true;

    if (this.formRegistration.invalid || this.password.value !== this.passwordconfirm.value) {
      return;
    }

    this.disableSubmit = true;
    this.loginError = '';

    this.registration.businessName = this.storename;
    this.registration.merchantNumber = this.merchant.merchantNumber1;

    // register method returns a user, but we only need to check if the user was created successfully:
    this.authSvc.register(this.registration).subscribe((u: User) => {
      // check if the user was created and authenticated:
      if (this.authSvc.Authenticated) {

        // notify user they have been registered and subscribed:
        const message = {
          to_numbers: [u.phone],
          storename: 'deliverme.com',
          type: 1,
          userid: u._id
        };

        if (u.phone && u.isAutoSubcribe) {
          this.smsService.sendMessage(message).subscribe();
        }

        if (u.isAutoNotification) {
          message.type = 2;
          this.smsService.sendMessage(message).subscribe();
        }

        enableBodyScroll(this.modalElement);
        this.dialogRef.close();
      } else {
        // something went wrong and the user was unable to be verified/authenticated.
        this.sb.open('An error has occurred, please try again later', '', { duration: 2000 });
      }

    }, (err) => {
      console.log(err);
      this.handleError(err);
    }, () => {
      this.disableSubmit = false;
    });
  }

  public resolved(event: any): void {
    this.disableSubmit = false;
    if (event) {
      // this.formRegistration.controls.recaptcha.reset();
    }
  }

  public MerchantRegistration() {
    this.dialogRef.close();
    this.router.navigate(['/registration']);
  }

  private handleError(err: HttpErrorResponse) {
    if (err.status) {
      if (err.error) {
        // console.log(err.error);

        if (err.error.lde_message === 'Entry Already Exists') {
          this.loginError = 'User account already exists for email';
          this.email.setErrors({ required: true });
          this.registrationEmail.nativeElement.focus();
        } else {
          this.loginError = err.error.message;
        }
      } else {
        this.loginError = err.message;
      }
    } else {
      this.loginError = err.message;
    }
    // console.log('An error has occurred:', this.loginError);
    this.formRegistration.controls.recaptcha.reset();
  }

}
