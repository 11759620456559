import { Component, Inject, OnInit, OnDestroy, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTimeFormatOptions } from 'luxon';
import { Merchant } from 'projects/common/src/lib/merchant';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { ISchedule, ScheduleType } from 'projects/common/src/lib/store-cart.model';
import { StoreCartService } from 'projects/services/src/lib/store-cart.service';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class DayObject {
  public date: number; // 1-28/30/31 for day of the month
  public month: number;
  public year: number;
  public day: number; // 0-6 for day of the week
  public disabled = false;
  public times: TimeSlot[] = [];
}

class TimeSlot {
  public time: string;
  public value: number;
}

export interface IScheduleDialogData {
  orderType: OrderTypeEnum;
  orderTypeDesc: string;
  schedule: ISchedule;
  merchant: Merchant;
  isCheckout: boolean;
}

const TIMEINTERVAL = 5 * 60 * 1000;

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  private timeFormatOptions = { hour: 'numeric', hour12: true, minute: '2-digit' } as DateTimeFormatOptions;
  private dateFormatOptions = { dateStyle: 'medium' } as DateTimeFormatOptions;
  private merchantInfo: Merchant;
  public isCheckout: boolean;

  public Schedule: ISchedule;
  public sType = '0';
  public dtype = 0;
  public datePicker: DayObject[];
  public selectedDate: DayObject;
  public selectedTime: TimeSlot;
  public availableTimeSlots: TimeSlot[];
  public noTimesAvailable = false;
  public closedToday = false;
  public deliverySuspended = false;
  public pickupSuspended = false;

  public days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  public monthNames = [];
  public deliveryTimeText = '';
  public orderTypes = [];

  public defaultOperationTime = [];
  public waittime = 0;

  modalElement: any;

  constructor(
    private dialogRef: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IScheduleDialogData,
    private _snackbar: MatSnackBar,
    private _cartSvc: StoreCartService
  ) {

  }

  ngOnInit() {

    // do not place disableBodyScroll in constructor, it intereferes with loading the reactive forms
    this.modalElement = document.querySelector('#body-content');
    disableBodyScroll(this.modalElement);

    /*     this.merchantInfo = this.data.merchant;

        this.orderTypes = this.merchantInfo.orderOptions.filter(o => {
          if (o.enabled) {
            if (o.value === OrderTypeEnum.Delivery) {
              this.dtype = o.value;
              this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
              this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
            }
            return o;
          }
        });

        if (this.data.orderType) {
          this.dtype = this.data.orderType;
          if (this.dtype === OrderTypeEnum.Delivery) {
            this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
            this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
          } else {
            this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
            this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
          }
        } else {
          if (this.dtype !== OrderTypeEnum.Delivery) {
            this.dtype = this.orderTypes[0].value;
            this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
            this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
          }
        }

        this.Schedule = this.data.schedule;
        this.checkClosedToday();
        this._cartSvc.UpdateDeliveryType(this.dtype);

        this.SetupDatePicker();
        this.SetupTimePicker();
        this.SetupSelectedDate();
        this.SetDeliveryType(this.data.orderType); */
  }

  ngAfterViewInit() {
    this.merchantInfo = this.data.merchant;
    this.isCheckout = this.data.isCheckout;

    this.orderTypes = this.merchantInfo.orderOptions.filter(o => {
      if (o.enabled) {
        if (o.value === OrderTypeEnum.Delivery) {
          this.dtype = o.value;
          this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
          this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
          this.deliverySuspended = o.suspended;
        } else {
          this.pickupSuspended = o.suspended;
        }
        return o;
      }
    });

    if (this.data.orderType) {
      this.dtype = this.data.orderType;
      if (this.dtype === OrderTypeEnum.Delivery) {
        this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
        this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
      } else {
        this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
        this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
      }
    } else {
      if (this.dtype !== OrderTypeEnum.Delivery) {
        this.dtype = this.orderTypes[0].value;
        this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
        this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
      }
    }

    this.Schedule = this.data.schedule;
    this.checkClosedToday();
    // this._cartSvc.UpdateDeliveryType(this.dtype);

    this.SetupDatePicker();
    this.SetupTimePicker();
    this.SetupSelectedDate();
    this.SetDeliveryType(this.data.orderType);
  }

  ngOnDestroy() {
    enableBodyScroll(this.modalElement);
  }

  public Cancel() {
    enableBodyScroll(this.modalElement);
    // return original data
    this.dialogRef.close(null);
  }

  private checkClosedToday() {
    const d = new Date();

    const closeTime = (this.defaultOperationTime[d.getDay()].closeHour) ? this.defaultOperationTime[d.getDay()].closeHour.split(':') : [];
    let close: number;

    if ((this.dtype === OrderTypeEnum.Delivery && this.deliverySuspended) || (this.dtype === OrderTypeEnum.PickUp && this.pickupSuspended) ||
        this.defaultOperationTime[d.getDay()].available === false || !this.defaultOperationTime[d.getDay()].openHour || this.defaultOperationTime[d.getDay()].openHour.trim() === '' || closeTime.length <= 1) {
      this.sType = '1';
      this.closedToday = true;
      // this.noTimesAvailable = true;
    } else {
      if (closeTime.length > 1) {
        const t = new Date(d);
        if (closeTime[1].indexOf('PM') !== -1 && closeTime[0].trim() !== '12') {
          close = t.setHours(parseInt(closeTime[0].trim(), 10) + 12, parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
        } else {
          close = t.setHours(parseInt(closeTime[0].trim(), 10), parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
        }
      }

      if (d.getTime() + this.waittime * 60 * 1000 > close) {
        this.sType = '1';
        this.closedToday = true;
      } else {
        this.sType = `${this.data.schedule.text}`;
        this.closedToday = false;
      }
      // this.noTimesAvailable = false;
    }
  }

  public Confirm() {
    this.Schedule.text = this.sType === '0' ? ScheduleType.ASAP : ScheduleType.SCHEDULED;
    this._cartSvc.UpdateDeliveryType(this.dtype);

    const now = new Date().getDate();
    const selected = new Date(this.Schedule.value).getDate();
    if (now === selected) {
      if (this.Schedule.value === this.availableTimeSlots[0].value) {
        this.Schedule.asap = true;
      } else {
        this.Schedule.asap = false;
      }
    } else {
      this.Schedule.asap = false;
    }

    enableBodyScroll(this.modalElement);
    console.log(new Date(this.Schedule.value));
    this.dialogRef.close(this.Schedule);
  }

  public SetDeliveryType(type) {
    this.dtype = type;
    this._cartSvc.UpdateDeliveryType(this.dtype);

    if (this.dtype === OrderTypeEnum.Delivery) {
      this.defaultOperationTime = this.merchantInfo.settings.deliveryHours;
      this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
    } else {
      this.defaultOperationTime = this.merchantInfo.settings.pickupHours;
      this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
    }

    this.checkClosedToday();
    this.SetupDatePicker();
    this.SetupTimePicker();

    this.SetupSelectedDate();
    // this.SetupSelectedTime();
  }

  public SetScheduleType(type: string) {
    this.sType = type;
    if (this.sType === ScheduleType.SCHEDULED) {
      this.Schedule.value = 0;
      // this.SetupSelectedDate();
    }

    this.SetupSelectedDate();
  }

  private SetupDatePicker() {
    this.datePicker = [];
    const NOW = new Date();
    // get today:
    const today = new Date();
    const dayofweek = today.getDay();

    try {
      // reset today to the beginning of the week:
      today.setDate(today.getDate() - dayofweek);
      for (let i = 0; i < 14; i++) {
        const month = today.toLocaleDateString('default', { month: 'long' });
        if (this.monthNames.indexOf(month) === -1) { this.monthNames.push(month); }
        const dayobj = new DayObject();
        dayobj.date = today.getDate();
        dayobj.day = today.getDay();
        dayobj.month = today.getMonth();
        dayobj.year = today.getFullYear();


        if (this.dtype === OrderTypeEnum.PickUp) {
          // disable each day that's less than NOW

          /*        dayobj.disabled = today.getDate() < NOW.getDate() ||
                   !this.merchantInfo.settings.pickupHours[dayobj.day].openHour ||
                   this.merchantInfo.settings.pickupHours[dayobj.day].openHour.trim() === '' ||
                   !this.merchantInfo.settings.pickupHours[dayobj.day].closeHour ||
                   this.merchantInfo.settings.pickupHours[dayobj.day].closeHour.trim() === ''; */

          dayobj.disabled = today < NOW ||
            (!this.merchantInfo.settings.pickupHours[dayobj.day].openHour ||
              !this.merchantInfo.settings.pickupHours[dayobj.day].closeHour ||
              this.merchantInfo.settings.pickupHours[dayobj.day].available === false ||
              this.pickupSuspended);
        }

        if (this.dtype === OrderTypeEnum.Delivery) {
          // disable each day that's less than NOW
          dayobj.disabled = today < NOW ||
            (!this.merchantInfo.settings.deliveryHours[dayobj.day].openHour ||
              !this.merchantInfo.settings.deliveryHours[dayobj.day].closeHour ||
              this.merchantInfo.settings.deliveryHours[dayobj.day].available === false ||
              this.deliverySuspended);
        }

        if (today.getDate() === NOW.getDate() && (this.closedToday || !this.merchantInfo.settings.Active)) {
          dayobj.disabled = true;
        }
        dayobj.times = [];
        this.datePicker.push(dayobj);
        today.setDate(today.getDate() + 1);
      }
    } catch (error) {
      console.log(error);
    }

  }

  private SetupTimePicker() {
    try {
      this.datePicker.forEach(dp => {
        // for (let i = 0; i < this.datePicker.length; i++) {
        const dObj = dp;
        // only need times if it's not disabled:
        if (!dObj.disabled) {
          const d = new Date(dObj.year, dObj.month, dObj.date);
          // d.setDate(dObj.date);

          let open: any;
          let close: any;
          // if (this.selectedDate) {
          //   const openTime = this.merchantInfo.settings.hoursOfOperation[this.selectedDate.day].openHour.split(':');

          //   if (openTime[1].indexOf('PM') !== -1) {
          //     open = d.setHours(parseInt(openTime[0].trim(), 10) + 12, parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
          //   } else {
          //     open = d.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
          //   }

          //   const closeTime = this.merchantInfo.settings.hoursOfOperation[this.selectedDate.day].closeHour.split(':');

          //   if (closeTime[1].indexOf('PM') !== -1) {
          //     close = d.setHours(parseInt(closeTime[0].trim(), 10) + 12, parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
          //   } else {
          //     close = d.setHours(parseInt(closeTime[0].trim(), 10), parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
          //   }
          // } else {
          open = d.setHours(0, 0, 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
          close = d.setHours(23, 59, 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
          // }

          const dtAsap = new Date();
          const minutes = Math.ceil((new Date().getMinutes() + 1) / 5) * 5;
          const asap = dtAsap.setMinutes(minutes);

          const tsAsap = new TimeSlot();
          tsAsap.time = 'ASAP';
          // tsAsap.value = asap;
          tsAsap.value = 0;
          dObj.times.push(tsAsap);
          // this.SetScheduleType(0);

          while (open < close) {
            const time = new Date(open);
            const ts = new TimeSlot();
            ts.time = time.toLocaleTimeString('default', this.timeFormatOptions);
            ts.value = open;
            dObj.times.push(ts);
            open += TIMEINTERVAL;
          }
        }
      });

      if (this.selectedDate) {
        this.SetupSelectedTime();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private SetupSelectedDate() {
    if (this.sType === '0') {
      this.Schedule.value = 0;
    }

    if (this.Schedule.value === 0) {
      const matchDate = this.datePicker.filter(d => !d.disabled);
      this.selectedDate = matchDate[0];
    } else {
      const sd = new Date(this.Schedule.value);
      let matchDate = this.datePicker.filter(d => d.date === sd.getDate() && !d.disabled);
      if (matchDate.length === 0) {
        matchDate = this.datePicker.filter(d => !d.disabled);
      }
      this.selectedDate = matchDate[0];
    }
    this.SetupSelectedTime();
  }

  private SetupSelectedTime() {
    let d = new Date();

    try {


      if (this.selectedDate && (this.selectedDate.date !== d.getDate() || this.selectedDate.month !== d.getMonth() || this.selectedDate.year !== d.getFullYear())) {
        d = new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.date);
      }

      let open: any;
      let close: any;
      let invalidDay = true;

      while (invalidDay) {
        const t = new Date(d);
        const openTime = this.defaultOperationTime[t.getDay()].openHour.split(':');
        const closeTime = this.defaultOperationTime[t.getDay()].closeHour.split(':');

/*         if (openTime.length > 1) {
          if (openTime[1].indexOf('PM') !== -1) {
            open = t.setHours(parseInt(openTime[0].trim(), 10) + 12, parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
          } else {
            open = t.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
          }
        } */

          open = t.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0);

        if (closeTime.length > 1) {
          if (closeTime[1].indexOf('PM') !== -1) {
            close = t.setHours(parseInt(closeTime[0].trim(), 10) + 12, parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
          } else {
            close = t.setHours(parseInt(closeTime[0].trim(), 10), parseInt(closeTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.closeTime - needs to be a 24 hour digit (0-23)
          }
        }

        if (openTime.length === 1 || closeTime.length === 1 || (d.getTime() + ((this.waittime) * 60 * 1000)) >= (close - ((this.waittime + 5) * 60 * 1000))) {
          this.closedToday = true;
          d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
        } else {
          invalidDay = false;
        }
      }

      this.Schedule.close = close;

      // d = new Date();
      let firstValidTime = (open > d.getTime()) ? open : d.getTime();
      firstValidTime += ((this.waittime + 1) * 60 * 1000);

      console.log(new Date(firstValidTime));

      // if (this.dtype === OrderTypeEnum.Delivery) {
      //   firstValidTime += (20 * 60 * 1000);
      // }

      this.availableTimeSlots = [];
      this.availableTimeSlots = this.selectedDate.times.filter(t => t.value >= firstValidTime && t.value < close - (this.waittime * 60 * 1000));

      if (!this.closedToday && d.getDate() === this.selectedDate.date) {
        const asap = this.availableTimeSlots.filter(t => t.time.indexOf('ASAP') >= 0);
        if (asap.length > 1) {
          this.availableTimeSlots.splice(0, 1);
        } else if (asap.length < 1 && this.sType === '0') {
          this.availableTimeSlots[0].time = 'ASAP - Approximately ' + this.availableTimeSlots[0].time;
        }
        // const asap: any = this.selectedDate.times.filter(t => t.time === 'ASAP')[0];
        // if (asap && this.availableTimeSlots.length > 0) {
        //   // console.log(this.availableTimeSlots[0].time);
        //   const minutes = Math.ceil((new Date(firstValidTime).getMinutes() + 1) / 5) * 5;
        //   asap.value = new Date(new Date(firstValidTime).setMinutes(minutes));
        //   asap.time += ' - Approximately ' + this.availableTimeSlots[0].time;
        //   this.availableTimeSlots[0].time = asap.time;
        // }
      }

      // if the store is still open but the first valid time isn't available
      // because you are ordering too close to closing time:
      this.noTimesAvailable = this.availableTimeSlots.length === 0;

      if (this.noTimesAvailable) {
        this.sType = ScheduleType.SCHEDULED.toString();
        this.selectedDate.disabled = true;
        this.SetupSelectedDate();
      } else {
        if (this.Schedule.value === 0) {
          // otherwise, times are available, choose the first available slot:
          this.selectedTime = this.availableTimeSlots[0];
          this.Schedule.value = this.selectedTime.value;
        } else {
          this.selectedTime = this.availableTimeSlots.filter(ats => ats.value === this.Schedule.value)[0];
          if (!this.selectedTime) {
            this.selectedTime = this.availableTimeSlots[0];
            this.Schedule.value = this.selectedTime.value;
          }
        }
      }

    } catch (error) {
      console.log(error);
    }

  }

  public SelectDate(d: DayObject) {
    if (!d.disabled) {
      this.selectedDate = d;
      // this.SetupTimePicker();
      this.SetupSelectedTime();
    }
  }

  public SelectTime(e) {
    try {
      this.Schedule.value = this.selectedTime.value;
    } catch (error) {
      console.log(error);
    }
    // const ts = e.value as TimeSlot;
    // this.Schedule.value = ts.value;

    // this.selectedTime = ts;

  }

  AsapText(): string {
    if (this.selectedTime) {
      const d = new Date(this.selectedTime.value);
      d.setMonth(this.selectedDate.month);
      this.selectedTime.value = d.getTime();

      const ot = this.data.merchant.orderOptions.find((oo: any) => oo.desc === this.data.orderTypeDesc);

      const orderType = this.data.orderTypeDesc || 'Delivery';

 /*      switch (this.dtype) {
        case 0:
          orderType = 'Dine In';
          break;
        case 1:
          orderType = 'To Go';
          break;
        case 2:
          orderType = 'Pickup';
          break;
        case 3:
          orderType = 'Delivery';
          break;
      } */

      if (this.selectedTime.time === 'ASAP') {
        return `${orderType}, ${this.selectedTime.time}`;
      } else {
        return `${orderType}, ${d.toLocaleDateString('default', this.dateFormatOptions)} at ${d.toLocaleTimeString('default', this.timeFormatOptions)}`;
      }

    }
    return '';
  }

}
