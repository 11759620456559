<div id="divScheduleDialog" mat-dialog-content style="overflow: hidden;">

    <div>
        <div class="row" *ngIf="data">
            <h3 class="col-10" style="margin-bottom: 20px;">{{data.orderTypeDesc}}</h3>
            <div class="col-2">
                <a class="nav-link float-right" style="color: black;" data-dismiss="modal" [routerLink]="[]" (click)="Cancel()">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
        </div>

        <div fxLayout="row" *ngIf="isCheckout">
            <div *ngFor="let o of orderTypes; let i = index" style="width: 100%;">
                <button fxFlex="100%" [class.left-radius]="i===0" [class.right-radius]="i===orderTypes.length-1" [class.button-radius]="orderTypes.length===1" [class.btn-primary]="dtype===o.value" [class.btn-outline-primary]="dtype!==o.value" (click)="SetDeliveryType(o.value)" data-test="Schedule-DialogSetDeliveryTypeButton">{{o.desc}}</button>
            </div>
        </div>
    </div>

    <div *ngIf="!closedToday">
        <div><b>When would you like your order?</b></div>
        <div style="padding:15px 0;width: 100%;">
            <button fxFlex="100%" class="btn left-radius" [class.btn-outline-primary]="sType!='0'" [class.btn-primary]="sType=='0'" [disabled]="noTimesAvailable || closedToday" (click)="SetScheduleType('0')" data-test="Schedule-DialogTodayButton">Today</button>
            <button fxFlex="100%" class="btn right-radius" [class.button-radius]="closedToday" [class.btn-outline-primary]="sType!='1'" [class.btn-primary]="sType=='1'" (click)="SetScheduleType('1')" data-test="Schedule-DialogLaterButton">Later
        Date</button>
        </div>
    </div>

    <div *ngIf="closedToday" style="margin-top: 20px;">
        <h5 style="font-weight: 800;">Online ordering is currently not available. Would you like to order for a future Date and Time?</h5>
    </div>
    <!-- <mat-error *ngIf="closedToday">
        Online Order Is Currently Not Available For Today
    </mat-error> -->

    <div *ngIf="sType == '1'">

        <table class="datepicker">
            <thead>
                <tr>
                    <th colspan="7">{{monthNames.join(' - ')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th *ngFor="let d of days">{{d}}</th>
                </tr>
                <tr>
                    <td *ngFor="let d of [0,1,2,3,4,5,6]" [class.disabled]="datePicker[d].disabled" [class.active]="selectedDate == datePicker[d]" (click)="SelectDate(datePicker[d])">
                        {{datePicker[d].date}}
                    </td>
                </tr>
                <tr>
                    <td *ngFor="let d of [7,8,9,10,11,12,13]" [class.disabled]="datePicker[d].disabled" [class.active]="selectedDate == datePicker[d]" (click)="SelectDate(datePicker[d])">
                        {{datePicker[d].date}}
                    </td>
                </tr>
            </tbody>
        </table>

        <ng-template #NoTimesAvailable>
            <div>There are no more times available!</div>
        </ng-template>

    </div>

    <div id="timePicker">
        <mat-form-field style="width:100%;" *ngIf="!noTimesAvailable; else noTimesAvailable" appearance="none">
            <select matNativeControl id="registration-state" [(ngModel)]="selectedTime" (change)="SelectTime($event)" class="timePickerSelect" data-test="Schedule-DialogSelecTimeSelect">
                <option *ngFor="let ts of availableTimeSlots" [ngValue]="ts" style="font-weight: bold;" data-test="Schedule-DialogSelectTimeOption">
                    {{ts.time}}
                </option>
            </select>
        </mat-form-field>
    </div>

</div>

<div mat-dialog-actions style="padding-bottom: 24px;">
    <!--<button mat-raised-button color="accent" (click)="Cancel()">Cancel</button>-->
    <button class="btn btn-success button-radius" style="width: 100%" (click)="Confirm()" [disabled]="noTimesAvailable" data-test="Schedule-DialogTimeButton">{{AsapText()}}</button>
</div>
