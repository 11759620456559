import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LogIn } from 'projects/common/src/lib/login';
import { Register } from 'projects/common/src/lib/register';
import { User } from 'projects/common/src/lib/user';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, tap, map, first } from 'rxjs/operators';
import { ConfigService } from 'projects/services/src/lib/config.service';
import { UserService } from 'projects/services/src/lib/user.service';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { CrossStorageService } from 'projects/services/src/lib/cross-storage-service';
import { TokenService } from 'projects/services/src/lib/token.service';
import { AnyNaptrRecord } from 'dns';
import { Merchant } from 'projects/common/src/lib/merchant';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

const guestEmail = 'guest@electronicpayments.com';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _user: User = new User();
  private _isAuthenticated = false;
  private _isGuestUser = false;
  private _userStorageKey = 'user';
  public ssoConfig: any;
  public cognitoUser: any;
  private merchant: Merchant;
  public progressBarShow = false;
  public _progressBarShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.progressBarShow);
  public progressBarStatus = this._progressBarShow.asObservable();

  @Output() CurrentUserEmitter: EventEmitter<User> = new EventEmitter<User>();

  // authenticationEvent$ = this.authenticationDelegate.asObservable();
  constructor(
    private http: HttpClient,
    private localStorage: StorageMap,
    private configService: ConfigService,
    private userService: UserService,
    private crossStorageService: CrossStorageService,
    private tokenService: TokenService
  ) {
    // check localstorage to see if user exists already:
    this.localStorage.get(this._userStorageKey).subscribe(async (u: User) => {
      await this.SetAuthStateAsync(u);
    });
  }

  public updatedProgressBarStatus(show: boolean) {
    this._progressBarShow.next(show);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  public SetAuthState(u: User) {
    // prevent crashes when null user passed in:
    u = u ?? new User();

    this.localStorage.get(this._userStorageKey).subscribe((user: User) => {

      if (!u.email) {
        if (user && user.email) {
          u = user;
        }
      }

      this.localStorage.get('merchant').subscribe((merchant: Merchant) => {
        u.merchantid = merchant._id;
        u.merchantNumber = merchant.merchantNumber1;
        u.iso360ID = merchant.isoID || '';

        if (u.access_token) {
          localStorage.setItem('token', u.access_token);
          this.localStorage.set('token', u.access_token);
          this._user = u;
          this._isAuthenticated = (!this._isGuestUser) ? true : false;
          this.localStorage.set(this._userStorageKey, u).subscribe();
        } else {
          this._user = new User();
          this._isGuestUser = false;
          this._isAuthenticated = false;
          this.localStorage.delete(this._userStorageKey).subscribe();
          localStorage.removeItem('token');
        }
        // notify components that the user has changed:
        this.CurrentUserEmitter.emit(this._user);
      });
    });
  }

  public async SetAuthStateAsync(u: User): Promise<void> {
    return new Promise((resolve, reject) => {
      // prevent crashes when null user passed in:
      u = u ?? new User();

      if (!u.access_token) {
        this.localStorage.get(this._userStorageKey).subscribe((user: User) => {
          if (user && user.email) {
            u = user;

            if (this.merchant) {
              u.merchantid = this.merchant._id;
              u.merchantNumber = this.merchant.merchantNumber1;
              u.iso360ID = this.merchant.isoID || '';

              localStorage.setItem('token', u.access_token);
              this.localStorage.set('token', u.access_token);
              this._user = u;
              this._isAuthenticated = (!this._isGuestUser) ? true : false;
              this.localStorage.set(this._userStorageKey, u).subscribe();

              if (this._user.firstname !== 'Guest') {
                this.CurrentUserEmitter.emit(this._user);
              }
              resolve();
            } else {
              this.localStorage.get('merchant').subscribe((merchant: Merchant) => {
                if (!merchant) {
                  console.log('no merchant in stoorage');
                  return resolve();
                } else {
                  this.merchant = merchant;
                }

                u.merchantid = merchant._id;
                u.merchantNumber = merchant.merchantNumber1;
                u.iso360ID = merchant.isoID || '';

                if (u.access_token) {
                  localStorage.setItem('token', u.access_token);
                  this.localStorage.set('token', u.access_token);
                  this._user = u;
                  this._isAuthenticated = (!this._isGuestUser) ? true : false;
                  this.localStorage.set(this._userStorageKey, u).subscribe();
                } else {
                  this._user = new User();
                  this._isGuestUser = false;
                  this._isAuthenticated = false;
                  this.localStorage.delete(this._userStorageKey).subscribe();
                  localStorage.removeItem('token');
                }
                // notify components that the user has changed:
                if (this._user.firstname !== 'Guest') {
                  this.CurrentUserEmitter.emit(this._user);
                }
                resolve();
              });
            }
          } else {
            this._user = new User();
            this._isGuestUser = false;
            this._isAuthenticated = false;
            this.localStorage.delete(this._userStorageKey).subscribe();
            localStorage.removeItem('token');
            this.localStorage.delete('token').subscribe();
            this.CurrentUserEmitter.emit(this._user);
          }
        });
      } else {

        if (this.merchant) {
          u.merchantid = this.merchant._id;
          u.merchantNumber = this.merchant.merchantNumber1;
          u.iso360ID = this.merchant.isoID || '';

          localStorage.setItem('token', u.access_token);
          this.localStorage.set('token', u.access_token);
          this._user = u;
          this._isAuthenticated = (!this._isGuestUser) ? true : false;
          this.localStorage.set(this._userStorageKey, u).subscribe();

          if (this._user.firstname !== 'Guest') {
            this.CurrentUserEmitter.emit(this._user);
          }
          resolve();
        } else {
          this.localStorage.get('merchant').subscribe((merchant: Merchant) => {
            if (!merchant) {
              console.log('no merchant in stoorage');
              return resolve();
            } else {
              this.merchant = merchant;
            }

            u.merchantid = merchant._id;
            u.merchantNumber = merchant.merchantNumber1;
            u.iso360ID = merchant.isoID || '';

            if (u.access_token) {
              localStorage.setItem('token', u.access_token);
              this.localStorage.set('token', u.access_token);
              this._user = u;
              this._isAuthenticated = (!this._isGuestUser) ? true : false;
              this.localStorage.set(this._userStorageKey, u).subscribe();
            } else {
              this._user = new User();
              this._isGuestUser = false;
              this._isAuthenticated = false;
              this.localStorage.delete(this._userStorageKey).subscribe();
              localStorage.removeItem('token');
            }
            // notify components that the user has changed:
            if (this._user.firstname !== 'Guest') {
              this.CurrentUserEmitter.emit(this._user);
            }
            resolve();
          });
        }
      }

    });
  }

  public async setCognitoAuthState(cognitoUser: any): Promise<void> {
    // prevent crashes when null user passed in:
    let u: User = cognitoUser.user || new User();

    if (cognitoUser) {
      this.cognitoUser = cognitoUser;

      localStorage.setItem('accessToken', cognitoUser.tokens.accessToken);
      this.localStorage.set('accessToken', cognitoUser.tokens.accessToken).subscribe();
      this.localStorage.set('idToken', cognitoUser.tokens.idToken).subscribe();
      this.localStorage.set('refreshToken', cognitoUser.tokens.refreshToken).subscribe();
      this._user = u;

      if (u.groups.includes('merchant')) {
        this._user.isMerchant = true;
      }

      if (u.groups.includes('administrator')) {
        this._user.isAdmin = true;
      }

      this._isAuthenticated = true;

      this.localStorage.set(this._userStorageKey, this._user).subscribe();

      let connect = await this.crossStorageService.connect(this.ssoConfig.storageUrl).catch((error: any) => {
        console.log(error);
      });

      let at = await this.crossStorageService.setKey('accessToken', cognitoUser.tokens.accessToken).catch((error: any) => {
        console.log(error);
      });

      let it = await this.crossStorageService.setKey('idToken', cognitoUser.tokens.idToken).catch((error: any) => {
        console.log(error);
      });

      let rt = await this.crossStorageService.setKey('refreshToken', cognitoUser.tokens.refreshToken).catch((error: any) => {
        console.log(error);
      });

    } else {
      this.cognitoUser = undefined;
      this._user = new User();
      this._isGuestUser = false;
      this._isAuthenticated = false;
      this.localStorage.delete(this._userStorageKey).subscribe();
      this.localStorage.delete('accessToken').subscribe();
      this.localStorage.delete('idToken').subscribe();
      this.localStorage.delete('refreshToken').subscribe();
      localStorage.removeItem('accessToken');
      this.localStorage.delete('token').subscribe();
    }
    // notify components that the user has changed:
    this.CurrentUserEmitter.emit(this._user);
  }

  // use this for route guards:
  get AuthGuardCheck(): Observable<boolean> {
    if (!this._isAuthenticated) {
      return this.localStorage.get(this._userStorageKey)
        .pipe(
          tap(async (u: User) => {
            await this.SetAuthStateAsync(u);
          }),
          map((u: User) => {
            return this._isAuthenticated;
          })
        );
    }

    return of(this._isAuthenticated);
  }

  public get Authenticated() {
    if (!this._user || !this._user.email) {
      this.localStorage.get(this._userStorageKey).subscribe(async (u: User) => {
        if(u) {
          await this.SetAuthStateAsync(u);
        }
        return this._isAuthenticated;
      });
    } else {
      return this._isAuthenticated;
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this._user || !this._user.email) {
        this.localStorage.get(this._userStorageKey).subscribe(async (u: User) => {
          if(u) {
            await this.SetAuthStateAsync(u);
          }
          return resolve(this._isAuthenticated);
        });
      } else {
        return resolve(this._isAuthenticated);
      }
    });
  }

  public get AuthToken() {
    return this._user.access_token;
  }

  public get CurrentUser() {
    return this._user;
  }

  public get GuestUser() {
    return this._isGuestUser;
  }

  public login(logindata: LogIn): Observable<User> {
    return this.http.post<User>('/api/authentication/login', logindata, httpOptions)
      .pipe(
        tap(async (u: User) => {
          await this.SetAuthStateAsync(u);
        }),
        catchError(this.handleError)
      );
  }

  public logout() {
    try {

      setTimeout(async () => {

        this.localStorage.delete('user').subscribe(() => { });
        this.localStorage.delete('token').subscribe(() => { });

        await this.SetAuthStateAsync(null);

        if (this.cognitoUser) {
          if (this.crossStorageService.connected === false) {
            let connect = await this.crossStorageService.connect(this.ssoConfig.storageUrl).catch((error: any) => {
              console.log(error);
            });
          }

          let at = await this.crossStorageService.deleteKey('accessToken').catch((error: any) => {
            console.log(error);
          });

          let it = await this.crossStorageService.deleteKey('idToken').catch((error: any) => {
            console.log(error);
          });

          let rt = await this.crossStorageService.deleteKey('refreshToken').catch((error: any) => {
            console.log(error);
          });

          this.crossStorageService.close();
        }
      }, 0);
    } catch (error) {
      console.log(error);
    }

  }

  public unlockAccount(user: User): Observable<User> {
    return this.http.post<any>('/api/account/unlock', user, httpOptions)
      .pipe(
        tap((u: User) => {
        }),
        catchError(this.handleError)
      );
  }

  public lockAccount(user: User): Observable<User> {
    return this.http.put<any>('/api/account/lock', user, httpOptions)
      .pipe(
        tap((u: User) => {
        }),
        catchError(this.handleError)
      );
  }

  public socialLogin(socialdata: any): Observable<User> {
    return this.http.post<any>('/api/authentication/socialLogin', socialdata, httpOptions)
      .pipe(
        tap(async (u: User) => {
          await this.SetAuthStateAsync(u);
        }),
        catchError(this.handleError)
      );
  }

  // let login data be optional so it doesn't crash existing components.
  // if logindata is null, set to default guest credentials
  public guestLogin(logindata: LogIn = null): Observable<any> {

    if (!logindata) {
      logindata = new LogIn();
      logindata.application = 'deliverme';
      logindata.email = guestEmail;
      logindata.password = '';
    }

    return this.http.post<User>('/api/authentication/guestlogin', logindata, httpOptions)
      .pipe(
        tap(async (u: User) => {
          await this.SetAuthStateAsync(u);
        }),
        catchError(this.handleError)
      );
  }

  public register(register: Register): Observable<any> {
    return this.http.post<User>('/api/authentication/register', register, httpOptions)
      .pipe(
        tap(async (u: User) => {
          await this.SetAuthStateAsync(u);
        }),
        catchError(this.handleError)
      );
  }

  public passwordReset(data: any): Observable<any> {
    return this.http.post<any>('/api/authentication/reset', data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public validateToken(token: any): Observable<any> {
    return this.http.post<any>('/api/validatetoken', { access_token: token.replace('Bearer ', '').trim() }, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public validateResetToken(resettoken: any): Observable<any> {
    return this.http.get<any>('/api/authentication/reset/token/' + resettoken, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public changePassword(data: any): Observable<any> {
    return this.http.post<any>('/api/authentication/changepassword', data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public resetPassword(data: any): Observable<any> {
    return this.http.post<any>('/api/authentication/resetpassword', data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public async cognitoLogIn(login: LogIn): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {

        this.guestLogin(login).subscribe(async (user: User) => {
          if (user && user.access_token) {
            await this.SetAuthStateAsync(user);

            this.configService.getConfig('cognito_sso').subscribe(async (config: any) => {
              if (config) {
                this.ssoConfig = config;
                let LoginsURL = this.ssoConfig.aws.IdentityLoginsURL;

                let authenticationDetails = new AuthenticationDetails({
                  Username: login.email,
                  Password: login.password,
                });

                let poolData = {
                  UserPoolId: this.ssoConfig.aws.userpool.UserPoolId, // Your user pool id here
                  ClientId: this.ssoConfig.aws.userpool.ClientId // Your client id here
                };

                let userPool = new CognitoUserPool(poolData);

                let userData = {
                  Username: login.email, Pool: userPool, customAttributes: {
                    groups: ["merchant"]
                  },
                  tokens: {},
                  mid: ''
                };

                let cognitoUser = new CognitoUser(userData);

                cognitoUser.authenticateUser(authenticationDetails, {
                  onSuccess: async (session) => {
                    const tokens = {
                      accessToken: session.getAccessToken().getJwtToken(),
                      idToken: session.getIdToken().getJwtToken(),
                      refreshToken: session.getRefreshToken().getToken()
                    }

                    cognitoUser['tokens'] = tokens;

                    let LoginsCred = {};
                    LoginsCred[LoginsURL] = session.getIdToken().getJwtToken();

                    AWS.config.region = this.ssoConfig.aws.region;
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                      IdentityPoolId: this.ssoConfig.aws.IdentityPoolId, // your identity pool id here
                      Logins: LoginsCred
                    });

                    // try and fetch user by email and then set their role groups
                    this.userService.getUserByEmail(login.email).subscribe(async (user: User) => {
                      cognitoUser['user'] = user;
                      let accessTokenDataValidation = await this.validateTokens(tokens.accessToken, user);

                      await this.setCognitoAuthState(cognitoUser);
                      return resolve(cognitoUser);
                    }, async (error: any) => {
                      // couldn't find user by email but the user was authenticated in cognito so resolve anyways
                      await this.setCognitoAuthState(cognitoUser);
                      return resolve(cognitoUser);
                    });
                  }, onFailure: (err) => {
                    reject(err);
                  },
                });
              }
            });
          } else {
            resolve(null);
          }

        }, (error: any) => {
          reject(error);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  decodeTokenHeader(token): any {
    let headerIdEncoded = token.split('.');
    let buff = Buffer.from(headerIdEncoded[0], 'base64');
    let headerIdString = buff.toString('ascii');
    let header = JSON.parse(headerIdString);
    console.log('decodeTokenHeader', header);
    return header;
  }

  getJsonWebKeyWithKID(header): AnyNaptrRecord {
    for (let jwk of this.ssoConfig.aws.jsonWebKeys) {
      if (jwk.kid === header.kid) {
        return jwk;
      }
    }
    return null;
  }

  validateTokens(token: any, user: User): any {
    return new Promise((resolve, reject) => {
      console.log("Received Token for validation: ", token);
      let jwkToPem = require('jwk-to-pem');

      let Decoderesult = { error: "", token: "" };
      let headerId = this.decodeTokenHeader(token);
      let jsonWebIdKey = this.getJsonWebKeyWithKID(headerId);

      if (!jsonWebIdKey) {
        resolve(null);
      } else {
        let Pem = jwkToPem(jsonWebIdKey);

        this.tokenService.validateSSOToken({ "token": token, "cert": Pem, "algo": "RS256", "user": user }).subscribe(async (decodedToken: any) => {
          Decoderesult.token = decodedToken.decoded;

          user.access_token = decodedToken.access_token;
          this.localStorage.set('user', user).subscribe(() => { });
          this.localStorage.set('token', decodedToken.access_token).subscribe(() => { });
          localStorage.setItem('token', decodedToken.access_token);
          await this.SetAuthStateAsync(user);

          resolve(Decoderesult);
        }, (error: any) => {
          if (error.error) {
            console.log('validateTokens error: ' + error.error.message);
            Decoderesult.error = error.error.message;
          } else {
            console.log('validateTokens error: ' + error.message);
            Decoderesult.error = error.message;
          }

          resolve(Decoderesult);
        });
      }
    });
  }


}
