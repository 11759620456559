import { Injectable, Output, EventEmitter } from '@angular/core';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Location } from 'projects/common/src/lib/location';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError, retry } from 'rxjs/operators';

/* const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
}; */

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  @Output() merchantEmitter: EventEmitter<Merchant> = new EventEmitter();

  private merchant: Merchant;
  public merchants: Merchant[] = [];
  public _merchantsObservable: BehaviorSubject<Merchant[]> = new BehaviorSubject(this.merchants);
  public merchantsObservable: Observable<Merchant[]> = this._merchantsObservable.asObservable();

  constructor(public http: HttpClient, public globalservice: GlobalService, public localStorage: StorageMap) {
    this.merchantsObservable = new BehaviorSubject(this.merchants);
  }

  public selectedMerchant(merchant: Merchant): void {
    this.merchantEmitter.emit(merchant);
  }

  public addMerchant(merchant: Merchant): Observable<Merchant> {
    return this.http.post<Merchant>('/api/merchant', merchant)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMerchantByRoute(routeName: string): Observable<Merchant> {
    return this.http.get<Merchant>('/api/merchant/route/' + routeName)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMerchantLocationsByRoute(routeName: string): Observable<Merchant[]> {
    return this.http.get<Merchant[]>('/api/merchant/route/' + routeName)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMerchantByBusinessName(businessName: string): Observable<Merchant> {
    return this.http.get<Merchant>('/api/merchant/business/' + businessName)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMerchantById(id: any): Observable<Merchant> {
    return this.http.get<Merchant>('/api/merchant/' + id)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMerchantByEmail(email: string): Observable<Merchant[]> {
    return this.http.get<Merchant[]>('/api/merchant/email/' + email)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getAllMerchants(): Observable<Merchant[]> {
    return this.http.get<Merchant[]>('/api/merchant')
      .pipe(
        catchError(this.handleError)
      );
  }

  public getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>('/api/locations')
      .pipe(
        catchError(this.handleError)
      );
  }

  public search(parm: string, value: any): Observable<Merchant[]> {
    // "pc" = "procharge merchant id"
    // "r"  = "routeName"
    // "m"  = "MID"
    // "d"  = "device"
    return this.http.get<Merchant[]>('/api/merchant/search/' + parm + '/' + value)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getAllMerchant(): Observable<Merchant[]> {
    return this.http.get<Merchant[]>('/api/merchant')
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateMerchant(data: any): Observable<Merchant> {
    return this.http.put<any>('/api/merchant', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateMerchantInfo(data: any): Observable<Merchant> {
    return this.http.put<any>('/api/merchant', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public suspend(data: any): Observable<any> {
    return this.http.post<any>('/api/merchant/suspend', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateMerchantFee(data: any): Observable<Merchant> {
    return this.http.put<any>('/api/merchant/fee', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public setMerchant(merchant: Merchant) {
    this.merchant = merchant;
  }

  public get CurrentMerchant() {
    return this.merchant;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
