import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/src/lib/authentication.service';

@Injectable()
export class AuthIntercept implements CanActivate {
  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authSvc
      .AuthGuardCheck
      .pipe(tap(auth => {
        if(!auth) this.router.navigate(['/locations']);
      })
    );
  }
}
