<!-- <app-receipt2 [order]="data.order" [merchant]="data.merchant" [brandname]="data.brandname"></app-receipt2> -->

<div id="order-receipt2" fxLayout="row wrap" fxLayout.lt-md="column" class="receipt-content" *ngIf="order">
    <!--     <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" class="deliverme_logo">
        <div fxLayout.lt-md="row" class="deliverme_logo_image">

        </div>
    </div> -->
    <div fxLayout="column" style="width: 100%;">
        <div *ngIf="order" fxLayout="row" fxLayout.lt-md="column" class="paymentid" fxLayoutAlign="start center" style="align-self: center; justify-content: center;">
            <h5>{{order.ordertype}} Order</h5>
        </div>
        <div *ngIf="order" fxLayout="row" fxLayout.lt-md="column" class="paymentid" fxLayoutAlign="start center" style="padding: 8px;padding-bottom: 0px;">
            <h5>Order: {{order.orderxref}}</h5>
        </div>
        <div id="item-table" *ngIf="order" fxLayout.lt-md="column">
            <div id="table" class="item-table">
                <!-- <table>
                    <thead>
                        <tr style="border-bottom: 2px solid;">
                            <th style="padding: 0px 8px 8px 8px;">QTY</th>
                            <th colspan="2" style="padding: 0px 8px 8px 8px;">Desc</th>
                            <th style="padding: 0px 8px 8px 8px;text-align: right;">Price</th>
                        </tr>
                    </thead>
                    <tbody style="max-height: 45vh;">
                        <tr class="service" *ngFor="let item of order.items">
                            <td class="tableitem" style="vertical-align: baseline;">
                                <p class="itemtext">{{item.qty}}</p>
                            </td>
                            <td colspan="2" class="tableitem">
                                <p class="itemtext">{{item.title}}</p>
                                <div *ngIf="item.modifiers.length > 0" class="modifiertitle">
                                    <p *ngFor="let m of item.modifiers" style="margin-bottom: 0;margin-left: 10px;">
                                        {{m.title}}
                                    </p>
                                </div>
                                <div *ngIf="item.sides.length > 0" class="modifiertitle">
                                    <p *ngFor="let s of item.sides" style="margin-bottom: 0;margin-left: 10px;">
                                        {{s.title}}
                                    </p>
                                </div>
                                <div *ngIf="item.options.length > 0" class="modifiertitle">
                                    <p *ngFor="let o of item.options" style="margin-bottom: 0;margin-left: 10px;">
                                        {{o.title}}
                                    </p>
                                </div>
                                <div *ngIf="item.comments && item.comments.trim() !== ''" style="margin-left: 10px;">
                                    <p>
                                        {{item.comments.trim()}}
                                    </p>
                                </div>
                            </td>
                            <td class="tableitem" style="vertical-align: baseline; padding: 0px;">
                                <p class="pricetext">${{item.labeledPrice || item.price.toFixed(2)}}</p>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Subtotal</p>
                            </td>
                            <td class="totals">
                                <p>${{order.subtotal.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr *ngIf="order.serviceFee > 0" height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Checkout Fee</p>
                            </td>
                            <td class="totals">
                                <p>${{order.serviceFee.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr *ngIf="order.convenienceFee > 0" height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Convenience Fee</p>
                            </td>
                            <td class="totals">
                                <p>${{order.convenienceFee.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Tax</p>
                            </td>
                            <td class="totals">
                                <p>${{order.tax.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Gratuity</p>
                            </td>
                            <td class="totals">
                                <p>${{order.tip.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr *ngIf="order.deliveryFee > 0" class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Delivery Fee</p>
                            </td>
                            <td class="totals">
                                <p>${{order.deliveryFee.toFixed(2)}}</p>
                            </td>
                        </tr>
                        <tr class="tabletitle">
                            <td colspan="3" class="totals">
                                <p>Grand Total</p>
                            </td>
                            <td class="totals">
                                <p>${{order.totalAmount.toFixed(2)}}</p>
                            </td>
                        </tr>
                    </tfoot>
                </table> -->
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
                    <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> QTY </th>
                        <td mat-cell *matCellDef="let element"> <label>{{element.qty}}</label> </td>
                        <td mat-footer-cell *matFooterCellDef class="footer"></td>
                    </ng-container>

                    <ng-container matColumnDef="desc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Desc </th>
                        <td mat-cell *matCellDef="let element">
                            <p><label>{{element.title}}</label></p>
                            <div *ngIf="element.ratioGraphic !== 'none'; else noRatio">
                                <p *ngIf="element.wholeMods.length > 0"><label>**Whole**</label></p>
                                <p *ngFor="let s of element.wholeMods"><label>{{s.title}}</label></p>
                                <p *ngIf="element.leftMods.length > 0"><label>**1st Half**</label></p>
                                <p *ngFor="let s of element.leftMods"><label>{{s.title}}</label></p>
                                <p *ngIf="element.rightMods.length > 0"><label>**2nd Half**</label></p>
                                <p *ngFor="let s of element.rightMods"><label>{{s.title}}</label></p>
                            </div>

                            <ng-template #noRatio>
                                <p *ngFor="let s of element.sides"><label>{{s.title}}</label></p>
                                <p *ngFor="let s of element.options"><label>{{s.title}}</label></p>
                                <p *ngFor="let s of element.modifiers"><label>{{s.title}}</label></p>
                            </ng-template>
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="footer">
                            <p><label>Subtotal</label></p>
                            <p><label>Checkout Fee</label></p>
                            <p><label>Convenience Fee</label></p>
                            <p><label>Tax</label></p>
                            <p><label>Gratuity</label></p>
                            <p><label>Delivery Fee</label></p>
                            <p><label>Grand Total</label></p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Price </th>
                        <td class="price" mat-cell *matCellDef="let element"><label>$ {{element.labeledPrice || element.price.toFixed(2)}}</label></td>
                        <td mat-footer-cell *matFooterCellDef class="footer">
                            <p><label>$ {{order.subtotal.toFixed(2)}}</label></p>
                            <p><label>$ {{order.serviceFee.toFixed(2)}}</label></p>
                            <p><label>$ {{order.convenienceFee.toFixed(2)}}</label></p>
                            <p><label>$ {{order.tax.toFixed(2)}}</label></p>
                            <p><label>$ {{order.tip.toFixed(2)}}</label></p>
                            <p><label>$ {{order.deliveryFee.toFixed(2)}}</label></p>
                            <p><label>$ {{order.totalAmount.toFixed(2)}}</label></p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="page-break-after" style="justify-content: center;padding: 10px;">
            <div fxLayout="column" class="delivery-info" fxFlex="50">
                <h3 style="text-align: center;">{{order.customerInformation.name}}</h3>
                <div fxLayout="row" fxLayoutAlign="start center" class="align-delivery-info" style="justify-content: center;align-self: center;">
                    <p>{{order.customerInformation.phone}}</p>
                </div>
                <div fxLayout="row" *ngIf="this.order.ordertype === 3" fxLayoutAlign="start center" class="align-delivery-info" style="justify-content: center;align-self: center;">
                    <p>{{order.customerInformation.street1}}</p>
                    <p>{{order.customerInformation.street2}}</p>
                    <p>{{order.customerInformation.city}} {{this.order.customerInformation.state}}, {{this.order.customerInformation.postalcode}}</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="align-delivery-info" style="justify-content: center;align-self: center;">
                    <p>{{order.ordertype}} Order: {{order.orderxref}}</p>
                </div>
            </div>
        </div>
        <br />
        <div fxLayout="row" class="noprint">
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="start center">
                <button type="button" class="btn merchant-settings-btn-primary" (click)="print(false)" data-test="Payment-DialogPrintButton">Print</button>
            </div>
            <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
                <button type="button" class="btn merchant-settings-btn-primary complete" (click)="complete()" data-test="Payment-DialogCompleteButton">Complete</button>
            </div>
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="end center">
                <button type="button" class="btn merchant-settings-btn-primary" (click)="print(true)" data-test="Payment-DialogOrderTypeButton">{{order.ordertype}} Tkt</button>
            </div>
        </div>
        <div fxLayout="row" class="noprint" style="margin-top: 10px;">
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="start center">
                <button type="button" class="btn merchant-settings-btn-primary" style="background: red; border-color: red;" (click)="void()" data-test="Payment-DialogVoidButton">Void</button>
            </div>
            <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
                <!-- <button type="button" class="btn merchant-settings-btn-primary" (click)="close()">Complete</button> -->
            </div>
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="end center">
                <button type="button" class="btn merchant-settings-btn-primary" (click)="close()" data-test="Payment-DialogCloseButton">Close</button>
            </div>
        </div>
        <!-- <div fxLayout="row" *ngIf="!showPrinter" class="noprint">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
                <button type="button" class="btn receipt-button noprint" (click)="close()">Close</button>
            </div>
        </div> -->
        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="noprint">
            <div fxLayout="column" fxFlex="50">
                <div class="deliverme_logo_image">
                    <h6 class="powered-by">Powered By</h6>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50">
                <p>&copy;{{year}} Electronic Payments, Inc.<br /> 7800 Congress Ave, Suite 108<br /> Boca Raton, FL 33487
                </p>
            </div>
        </div> -->
    </div>
</div>