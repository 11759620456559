import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { GlobalService } from './global.service';
import { catchError, retry } from 'rxjs/operators';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';

declare var google: any;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  public apikey: any = 'AIzaSyCm0W3ypfIK36pAEMCXeejkvdAlxJSUBY4';
  public directionsService: any;
  public timeleft = 0;
  public _time_left: BehaviorSubject<number> = new BehaviorSubject(this.timeleft);
  public time_left: Observable<number> = this._time_left.asObservable();


  constructor(public localStorage: StorageMap,
    public http: HttpClient) {
    const script = document.createElement('script');
    script.id = 'googleMap';

    if (this.apikey) {
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apikey + '&libraries=places';
    } else {
      script.src = 'https://maps.googleapis.com/maps/api/js?key=';
    }
    document.head.appendChild(script);

    this.localStorage.get('delivery_eta').subscribe(async (data: number) => {
      if (data) {
        this.timeleft = data;
      }

      this._time_left.next(this.timeleft);
    });
  }

  public updateTimeLeft(time: number) {
    this.timeleft = time;
    this.localStorage.set('delivery_eta', time).subscribe(() => { });
    // this._time_left.next(this.timeleft);
  }

  ngAfterViewInit() {
    this.directionsService = new google.maps.DirectionsService();
  }

  public trackDelivery(order_id: number, merchantid: string, userid: string): Observable<any> {
    try {
      return this.http.get<any>('/api/delivery/' + order_id + '/' + merchantid + '/' + userid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public updateDelivery(data: any): Observable<any> {
    try {
      return this.http.post<any>('/api/delivery/', data, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public async getDeliveryInfo(StartAddress: string, EndAddress: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.directionsService) {
        this.directionsService = new google.maps.DirectionsService();
      }

      this.getGeocode(StartAddress).subscribe((StartLoc) => {
        this.getGeocode(EndAddress).subscribe(async (EndLoc) => {
          await this.directionsService.route({
            origin: StartLoc,
            destination: EndLoc,

            travelMode: 'DRIVING'
          }, (response, status) => {
            if (status === 'OK') {
              resolve(response);
            } else {
              reject();
            }
          });
        });
      });
    });
  }



  public getGeocode(address: string): Observable<any> {
    try {
      console.log('/api/delivery/geocode/\"' + address + '\"');
      
      return this.http.get<any>('/api/delivery/geocode/\"' + address + '\"')
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
