
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from 'projects/common/src/lib/user';
import { MatDialogRef } from '@angular/material/dialog';
import { Regex } from 'projects/common/src/lib/regex';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
  ResponseResetForm: UntypedFormGroup;
  errorMessage: string;
  successMessage: string;
  resetToken: null;
  CurrentState: any;
  IsResetFormValid = true;
  oldpwd = undefined;
  pwd = undefined;
  pwdconfirm = undefined;
  userEmail = '';
  disableSubmit = true;
  user: User;
  loginError = '';
  public regexList = new Regex();

  public formPassword = new UntypedFormGroup({
    email: new UntypedFormControl(this.userEmail, [Validators.required, Validators.pattern(this.regexList.email)]),
    currentPassword: new UntypedFormControl(this.oldpwd, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15)
    ]),
    newPassword: new UntypedFormControl(this.pwd, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15)
    ]),
    passwordconfirm: new UntypedFormControl(this.pwdconfirm, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15)
    ]),
  });

  get currentPassword(): any { return this.formPassword.get('currentPassword'); }
  get newPassword(): any { return this.formPassword.get('newPassword'); }
  get passwordconfirm(): any { return this.formPassword.get('passwordconfirm'); }

  constructor(
    private dialogRef: MatDialogRef<PasswordChangeComponent>,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: StorageMap,
    private fb: UntypedFormBuilder) {

    this.CurrentState = 'Wait';
  }

  ngOnInit() {

    this.init();
  }

  init() {
    this.formPassword = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(this.oldpwd, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      newPassword: new UntypedFormControl(this.pwd, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      passwordconfirm: new UntypedFormControl(this.pwdconfirm, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      email: new UntypedFormControl(this.userEmail)
    });
  }

  /*   validate(passwordFormGroup: FormGroup) {
      const new_password = passwordFormGroup.controls.newPassword.value;
      const confirm_password = passwordFormGroup.controls.confirmPassword.value;

      if (confirm_password.length <= 0) {
        return null;
      }

      if (confirm_password !== new_password) {
        return {
          doesNotMatch: true
        };
      }

      return null;
    } */

  resetPassword(form) {
    console.log(form.get('confirmPassword'));
    if (form.valid) {
      this.IsResetFormValid = true;
      this.authService.changePassword(this.formPassword.value).subscribe(
        data => {
          this.dialogRef.close(data);
        },
        err => {
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else { this.IsResetFormValid = false; }
  }

  public formChange(form: any): void {
    try {
      // this.registration.businessName = form.controls.businessName.value;
      this.oldpwd = form.controls.currentPassword.value;
      this.pwd = form.controls.newPassword.value;
      this.pwdconfirm = form.controls.passwordconfirm.value;

      if (form.valid) {
        this.disableSubmit = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
