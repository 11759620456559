import { title } from 'process';
import { OptionalItem } from './optional-item';

export class OptionalGroup {
    public _id: any;
    public merchantid: any;
    public title: string;
    public remoteName: string;
    public desc: string;
    public group_id: number;
    public items: OptionalItem[];
    public group_number: number;
    public min_limit: number;
    public max_limit: number;
    public ratio: string;
    public extra: ExtraAmount[];
    public active: boolean;
    public requiredMod: boolean;
    public allowDuplicate: boolean;
    public showLable: boolean;
    public defaultForAllItem: boolean;
    public defaultPrice: number;
    public defaultCost: number;
    public selected: boolean;
    public allowRatio: boolean;

    constructor() {
        // this._id = '';
        this.merchantid = '';
        this.title = '';
        this.remoteName = '';
        this.desc = '';
        this.group_id = 0;
        this.items = [];
        this.group_number = 0;
        this.min_limit = 0;
        this.max_limit = 1;
        this.ratio = "None";
        this.extra = [];
        this.active = true;
        this.requiredMod = false;
        this.allowDuplicate = false;
        this.showLable = false;
        this.defaultForAllItem = false;
        this.defaultPrice = 0;
        this.defaultCost = 0;
        this.selected = false;
        this.allowRatio = false;
    }
}

export class ExtraAmount {
    public enabled: boolean;
    public selected: boolean;
    public title: string;
    public fee: any;

    constructor() {
        this.enabled = false;
        this.selected = false;
        this.title = "Extra";
        this.fee = 0.00;
    }
}
