
import { PayMethod } from './paymethod';
import * as cardValidator from 'card-validator';

export class CardInfo {
    public name: string;
    public firstname: string;
    public lastname: string;
    public cardnumber: string;
    public cvv?: number;
    public expdate: string;
    public cardbrand: string;
    public cardbrandlogo: string;
    public addressF: string;
    public street1: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
    public email: string;
    public phone: string;
    public save: boolean;

    constructor() {
        this.name = '';
        this.firstname = '';
        this.lastname = '';
        this.cardnumber = '';
        this.cvv = undefined;
        this.expdate = '';
        this.cardbrand = '';
        this.cardbrandlogo = '';
        this.addressF = '';
        this.street1 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.country = 'USA';
        this.email = '';
        this.phone = '';
    }

    get paymethod() {
        const pm = new PayMethod();
        const edate = this.expdate;
        const validator = cardValidator.number(this.cardnumber);
        let type = '';
        try {
            type = validator.card.type;
        } catch (e) { type = 'visa'; }

        pm.expm = edate.split('/')[0];
        pm.expy = edate.split('/')[1];
        pm.last4 = this.cardnumber.substr(this.cardnumber.length - 4);
        pm.type = type;

        return pm;
    }


}
