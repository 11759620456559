import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Item } from 'projects/common/src/lib/item';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Modifier } from 'projects/common/src/lib/modifier';
import { GlobalService } from './global.service';
import { Observable, from, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { OptionalItem } from 'projects/common/src/lib/optional-item';
import { GiftCard } from 'projects/common/src/lib/giftcard';

@Injectable({
  providedIn: 'root'
})
export class GiftCardService {

  constructor(public http: HttpClient,
    public storage: StorageMap,
    public globalService: GlobalService) {

  }

  public getGiftCard(flag: string, searchValue: string): Observable<GiftCard> {
    try {
      return this.http.get<GiftCard>('/api/giftcard/' + flag + '/' + searchValue)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public generateGiftCards(merchantid: string, request: GiftCard): Observable<GiftCard> {
    try {
      return this.http.post<GiftCard>('/api//giftcard/generate/' + merchantid, request)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public activateGiftCard(request: GiftCard): Observable<GiftCard> {
    try {
      return this.http.put<GiftCard>('/api/giftcard/', request)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError((error.error) ? error.error : error);
  }
}
