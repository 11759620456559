<div id="divSideBarHeader" class="sidebar-header" style="z-index: 2200">
    <div id="close" *ngIf="screenWidth < 768" style="z-index: 2200">
        <a class="nav-link float-left" id="sidebarCollapse" data-dismiss="modal" class="sidebarCollapse sidebarCollapse2" style="color: black;margin-left: 15px;" (click)="closeShoppingCart()" data-test="Store-CartSidebarCollapseA">
            <i class="fas fa-times fa-lg"></i>
        </a>
    </div>
    <div class="container-fluid px-0" *ngIf="!authCheck || user.firstname === '' || user.firstname === 'Guest'">
        <div class="row">
            <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100" (click)="OpenLoginDialog()" data-test="Store-CartLoginButton">
          Login
        </button>
            </div>
            <div class="col-6">
                <div class="dropdown">
                    <button class="btn btn-primary w-100" type="button" (click)="OpenRegistrationDialog()" data-test="Store-CartSignUp!Button">
            Sign Up!
          </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid px-0" *ngIf="authCheck && user.firstname !== '' && user.firstname !== 'Guest'">
        <div class="row">
            <div class="col-6">
                <!-- <button type="button" class="btn btn-outline-primary w-100">Orders</button> -->
            </div>
            <div class="col-6">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-test="Store-CartDropDownMenuButton">{{user.firstname}}</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="Logout()" data-test="Store-CartLogoutA">Logout</a>
                        <!-- <a *ngIf="authSvc.Authenticated && (user.isAdmin || user.isMerchant)" class="dropdown-item" [routerLink]="settingsLink">Settings</a> -->
                        <a *ngIf="authSvc.Authenticated" class="dropdown-item" (click)="openMerchantAdmin()" data-test="Store-CartSettingsA">Settings</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-header" *ngIf="isCheckout && merchantInfo">
    <div class="container-fluid px-0" style="margin-bottom: 16px;">
        <button fxFlex="100" mat-raised-button class="return-button" (click)="continueShopping()" data-test="Store-CartReturnToMenuButton"> Return to Menu
    </button>
    </div>
</div>

<div class="sidebar-delivery-time" *ngIf="cartModel && !isCheckout && merchantInfo && merchantInfo.settings.Active">
    <!--     <p class="deltime" *ngIf="scheduleText; else NotScheduled">
        Your <span (click)="ScheduleDialog(cartModel.deliverytype)">{{cartModel.deliverytypetext}}</span> order for {{scheduleText}}
    </p> -->
    <mat-button-toggle-group *ngIf="merchantInfo" aria-label="Font Style" style="width: 100%;font-weight: bold;">
        <mat-button-toggle *ngFor="let oo of orderOptions" (click)="ScheduleDialog(oo.value)" style="height: 48px;" [checked]="oo.value === cartModel.deliverytype" data-test="Store-CartScheduleDialogButton">{{oo.desc}}
        </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- <ng-template #NotScheduled> -->
    <!--         <p class="deltime">
            <span (click)="ScheduleDialog()">Set your <u>{{defaultOrderType.text}}</u> Options</span>
        </p> -->
    <!-- <mat-button-toggle-group *ngIf="merchantInfo" aria-label="Font Style" style="width: 100%">
      <mat-button-toggle *ngFor="let oo of orderOptions" [value]="oo.desc" (click)="ScheduleDialog(oo.value)" [checked]="oo.value === cartModel.deliverytype">
        {{oo.desc}}</mat-button-toggle>
    </mat-button-toggle-group> -->
    <!-- </ng-template> -->
</div>

<div class="sidebar-delivery-time" *ngIf="cartModel && !isCheckout && merchantInfo && !merchantInfo.settings.Active">
    <p class="deltime">
        Online Ordering is currently not available.
        <!-- <span style="cursor:pointer;" (click)="ScheduleDialog()">{{scheduleText}}</span> -->
    </p>
</div>

<div class="item-cart">
    <ul class="order-list" *ngIf="cartModel && cartModel.itemList.length > 0; else NoItems">
        <li class="order-item" *ngFor="let item of cartModel.itemList;let idx = index">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-3 item-quantity">
                        <select name="cartitem_{{idx}}" [(ngModel)]="item.qty" (change)="UpdateItem(item, idx)" class="custom-select quantity-select" data-test="Store-CartItemQtySelect">
              <option *ngFor="let i of [].constructor(item.maxqty - item.minqty + 1); let j = index"
                [value]="(j+item.minqty)" data-test="Store-CartItemQtyOption">
                {{j+item.minqty}}
              </option>
            </select>
                    </div>
                    <div class="col-9 item-details">
                        <!-- <p class="item-name"><a href="#item-detail-modal" data-target="#item-detail-modal" data-toggle="modal" (click)="GetItemDetails(item,idx)">{{item.title}}</a></p> -->
                        <div class="row">
                            <div class="col-8">
                                <p class="item-name"><a [routerLink]="[]" (click)="GetItemDetails(item,idx)">{{item.title}}</a></p>
                            </div>
                            <div class="col-4 text-right item-price">
                                <!-- <p>${{item.price.toFixed(2)}}</p> -->
                                <p style="padding-right: 1rem;">${{item.labeledPrice}}</p>
                            </div>
                        </div>
                        <ul class="item-mods">
                            <div *ngIf="item.wholeMods && item.wholeMods.length > 0">
                                <p *ngIf="item.wholeMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px;">
                                    <label style="margin-bottom: 0px;">**Whole**</label></p>
                                <div *ngFor="let s of item.wholeMods" style="margin-bottom: 0px;">
                                    <label class="col-8">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                            </div>
                            <div *ngIf="item.leftMods && item.leftMods.length > 0">
                                <p *ngIf="item.leftMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px;">
                                    <label style="margin-bottom: 0px;">**1st Half**</label></p>
                                <div *ngFor="let s of item.leftMods" style="margin-bottom: 0px;">
                                    <label class="col-8">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                            </div>
                            <div *ngIf="item.rightMods && item.rightMods.length > 0">
                                <p *ngIf="item.rightMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px;">
                                    <label style="margin-bottom: 0px;">**2nd Half**</label></p>
                                <div *ngFor="let s of item.rightMods" style="margin-bottom: 0px;">
                                    <label class="col-8">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                            </div>

                            <div *ngIf="(!item.wholeMods && !item.leftMods && !item.rightMods) || (item.wholeMods.length === 0 && item.leftMods.length === 0 && item.rightMods.length === 0)">
                                <div *ngFor="let s of item.sides">
                                    <label class="col-8" style="color: #999;">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                                <div *ngFor="let s of item.options">
                                    <label class="col-8" style="color: #999;">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                                <div *ngFor="let s of item.modifiers">
                                    <label class="col-8" style="color: #999;">{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label>
                                    <label class="col-4 text-right mods-price" style="padding-right: 1rem;">${{(item.qty * (s.price||0)).toFixed(2)}}</label>
                                </div>
                            </div>
                            <ng-container *ngIf="item.comments && item.comments.trim() !== ''" style="margin-top: 5px;">
                                <li style="margin-top: 0.5rem;">
                                    <div class="row">
                                        <div class="col-8">
                                            {{item.comments}}
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                            <li class="rmv-btn" style="padding-top: 0.5rem;"><a href="javascript:void(0)" (click)="RemoveItem(idx)" data-test="Store-CartRemoveItemA">Remove</a></li>
                        </ul>
                    </div>
                    <!-- <div class="col-3 text-right item-price"> -->
                    <!-- <p>${{item.price.toFixed(2)}}</p> -->
                    <!-- <p>${{item.labeledPrice}}</p> -->
                    <!-- </div> -->
                </div>
            </div>
        </li>

        <li class="order-totals">
            <div class="container-fluid px-0">
                <div class="row" *ngIf="merchantInfo && merchantInfo.settings">
                    <div class="col-12">
                        <ul class="totals">
                            <li class="subtotal">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Subtotal</div>
                                        <div class="col-4 amount text-right">{{cartModel.subtotal.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="delivery-fee" *ngIf="cartModel.deliverytype === 3">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Delivery Fee</div>
                                        <div class="col-4 amount text-right">{{cartModel.deliveryfee.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="service-fee" *ngIf="cartModel.servicefee.Value > 0">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Checkout Fee</div>
                                        <div class="col-4 amount text-right">{{cartModel.servicefee.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="service-fee" *ngIf="cartModel.conveniencefee.Value > 0">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Convenience Fee</div>
                                        <div class="col-4 amount text-right">{{cartModel.conveniencefee.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="tax">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Tax</div>
                                        <div class="col-4 amount text-right">{{cartModel.tax.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="tip" *ngIf="isCheckout && (cartModel.tip.Percent > 0 || cartModel.tip.Flat > 0)">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-8">Tip
                                            <a *ngIf="cartModel.tip.Percent > 0">
                        ({{cartModel.tip.Value === maxAmount ? 'MAX' : (cartModel.tip.Percent * 100) + '%'}})
                      </a>
                                        </div>
                                        <div class="col-4 amount text-right">{{cartModel.tip.Text}}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="grand-total">
                                <div class="container-fluid px-0">
                                    <div class="row">
                                        <div class="col-6">Total</div>
                                        <div *ngIf="isCheckout" class="col-6 amount text-right">{{cartModel.total.Text}}</div>
                                        <div *ngIf="!isCheckout" class="col-6 amount text-right">{{stringTotalWithoutTip | currency}}</div>
                                    </div>
                                </div>
                            </li>
                            <li *ngIf="cartModel.subtotal.Value < merchantInfo.settings.minorderamt">
                                <label class="alert-text">Your order has to be at least
                  ${{merchantInfo.settings.minorderamt.toFixed(2)}} to continue!</label>
                            </li>
                            <li class="checkout" (click)="checkoutClicked()" *ngIf="!isCheckout && merchantInfo">
                                <button type="button" mat-raised-button class="btn btn-success w-100" [disabled]="cartModel.subtotal.Value < merchantInfo.settings.minorderamt || !merchantInfo.settings.Active || disableCheckoutButton" data-test="Store-CartContinueToCheckoutButton">Continue
                  to Checkout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

<!-- No Items template: -->
<ng-template #NoItems>
    <ul class="order-list">
        <li class="order-totals">
            <div style="padding: 1.5rem;">
                Your cart is empty. Select an item from the menu to start your order!
            </div>
        </li>
    </ul>
</ng-template>