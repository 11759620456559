export class Rating {
    public _id = '';
    public merchantid = '';
    public receiptNumber = '';
    public transactionId = '';
    public userid = '';
    public rating = 0;
    public comment = '';
    public datetime = new Date();

    constructor() {
        this._id = '';
        this.merchantid = '';
        this.receiptNumber = '';
        this.transactionId = '';
        this.userid = '';
        this.rating = 0;
        this.comment = '';
        this.datetime = new Date();
    }
}
