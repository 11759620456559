import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Regex } from 'projects/common/src/lib/regex';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { Address } from 'projects/common/src/lib/address';
import { AddressSelectionComponent } from './address-selection/address-selection.component';
import { UserService } from 'projects/services/src/lib/user.service';
import { User } from 'projects/common/src/lib/user';
import { Info } from 'projects/common/src/lib/info';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { getLocaleDateFormat } from '@angular/common';
import { StorageMap } from '@ngx-pwa/local-storage';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  // @ViewChild('adState') adState: MatSelect;
  private _to: any;

  public saved: boolean;
  public info: Info = new Info();
  public regexList: Regex = new Regex();
  public matches: any;
  public user: User;
  public required = true;
  streetSubscription: Subscription;

  public states: string[] = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
    'NL',
    'PE',
    'NS',
    'NB',
    'QC',
    'ON',
    'MB',
    'SK',
    'AB',
    'BC',
    'YT',
    'NT',
    'NU'
  ];

  public userSubscription: Subscription;
  public infoForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, this.invalidName()]),
    firstname: new UntypedFormControl('', []),
    lastname: new UntypedFormControl('', []),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.regexList.email)]),
    phone: new UntypedFormControl('', [Validators.required, Validators.pattern(this.regexList.us_phone_regex)]),
    address: new UntypedFormControl('', []),
    addressF: new UntypedFormControl(this.info.addressF, [Validators.required]),
    street1: new UntypedFormControl('', [Validators.required]),
    street2: new UntypedFormControl('', []),
    city: new UntypedFormControl('', [Validators.required]),
    state: new UntypedFormControl('', [Validators.required]),
    postalCode: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('USA', []),
  });

  get name(): any { return this.infoForm.get('name'); }
  get firstname(): any { return this.infoForm.get('firstname'); }
  get lastname(): any { return this.infoForm.get('lastname'); }
  get phone(): any { return this.infoForm.get('phone'); }
  get email(): any { return this.infoForm.get('email'); }
  get addressF(): any { return this.infoForm.get('addressF'); }
  get street1(): any { return this.infoForm.get('street1'); }
  get street2(): any { return this.infoForm.get('street2'); }
  get city(): any { return this.infoForm.get('city'); }
  get state(): any { return this.infoForm.get('state'); }
  get postalCode(): any { return this.infoForm.get('postalCode'); }
  get country(): any { return this.infoForm.get('country'); }

  modalElement: any;

  constructor(
    public dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Info,
    private _authSvc: AuthenticationService,
    private userService: UserService,
    private _google: GoogleApiService,
    private _addressDialog: MatDialog,
    private localStorage: StorageMap,
  ) {

  }

  ngOnInit() {
    if (this.data) {
      if (this._authSvc.Authenticated) {
        this.user = this._authSvc.CurrentUser;
      }

      for (const prop in this.data) {
        if (prop) {
          this.info[prop] = this.data[prop];
        }
      }

      if (this.info && this.info.name.length === 0 && this.user) {
        const da = JSON.parse(JSON.stringify(this.user.addresses.filter(x => x.isDefault)[0] || new Address())) as Address;
        const a = [da.street1, da.street2, da.city, da.state, da.postalCode];
        this.info = {
          name: this.user.firstname + ' ' + this.user.lastname,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          phone: this.user.phone,
          addressF: a.filter(s => s != null && s !== undefined && s !== '').join(', ') + ` ${this.user.postalCode}`,
          addresses: this.user.addresses,
          address: da,
          orderType: this.data.orderType
        };
      }

      if (this.info.addressF && this.info.addressF.trim() !== '') {
        this.saved = true;
      }

    } else {
      this.info = {
        name: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        addressF: '',
        addresses: [],
        address: new Address(),
        orderType: 3
      };

      this.saved = false;
    }

    this.modalElement = document.querySelector('#body-content');
    disableBodyScroll(this.modalElement);

    this.loadForm();
  }

  public ngAfterViewInit() {
    this.listenForAutoFill();
  }

  public loadForm() {
    this.infoForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.info.name, [Validators.required, this.invalidName()]),
      firstname: new UntypedFormControl(this.info.firstname, []),
      lastname: new UntypedFormControl(this.info.lastname, []),
      email: new UntypedFormControl(this.info.email, [Validators.required, Validators.pattern(this.regexList.email)]),
      phone: new UntypedFormControl(this.info.phone, [Validators.required, Validators.pattern(this.regexList.us_phone_regex)]),
      address: new UntypedFormControl(this.info.address, []),
      addressF: new UntypedFormControl(this.info.addressF, this.info.orderType === 3 ? [Validators.required] : []),
      street1: new UntypedFormControl(this.info.address.street1, this.info.orderType === 3 ? [Validators.required] : []),
      street2: new UntypedFormControl(this.info.address.street2, []),
      city: new UntypedFormControl(this.info.address.city, this.info.orderType === 3 ? [Validators.required] : []),
      state: new UntypedFormControl(this.info.address.state, this.info.orderType === 3 ? [Validators.required] : []),
      postalCode: new UntypedFormControl(this.info.address.postalCode, this.info.orderType === 3 ? [Validators.required, Validators.minLength(5), Validators.maxLength(10)] : []),
      country: new UntypedFormControl('USA', []),
    });

    // this.localStorage.get('deliveryinfo').subscribe((addr: Info) => {
    //   if (addr && addr.name.length > 0) {
    //     this.info = addr;
    //   }

    //   this.infoForm.patchValue({ name: this.info.firstname + ' ' + this.info.lastname });
    //   this.infoForm.patchValue({ firstname: this.info.firstname });
    //   this.infoForm.patchValue({ lastname: this.info.lastname });
    //   this.infoForm.patchValue({ email: this.info.email });
    //   this.infoForm.patchValue({ phone: this.info.phone });
    //   this.infoForm.patchValue({ address: this.info.address });
    //   this.infoForm.patchValue({ addressF: this.info.addressF });

    //   this.infoForm.patchValue({ street1: this.info.address.street1 });
    //   this.infoForm.patchValue({ street2: this.info.address.street2 });
    //   this.infoForm.patchValue({ city: this.info.address.city });
    //   this.infoForm.patchValue({ state: this.info.address.state });
    //   this.infoForm.patchValue({ postalCode: this.info.address.postalCode });
    //   this.infoForm.patchValue({ country: this.info.address.country });

    //   // we need to clear this because it is misleading when the address is not populated.
    //   if (!this.info.address.street1) {

    //     this.infoForm.patchValue({ addressF: '' });

    //     this.localStorage.set('deliveryinfo', this.info).subscribe(() => { });
    //   }
    // });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.streetSubscription) {
      this.streetSubscription.unsubscribe();
    }

    enableBodyScroll(this.modalElement);
  }

  public Update() {

    enableBodyScroll(this.modalElement);

    this.info.firstname = this.infoForm.get('firstname').value;
    this.info.lastname = this.infoForm.get('lastname').value;
    this.info.email = this.infoForm.get('email').value ? this.infoForm.get('email').value : '';
    this.info.phone = this.infoForm.get('phone').value;
    this.info.address = this.infoForm.get('address').value;
    this.info.addressF = this.infoForm.get('addressF').value;

    this.localStorage.set('deliveryinfo', this.info).subscribe(() => { });

    this.dialogRef.close(this.info);
  }

  public addressLookUp(): void {
    clearTimeout(this._to);
    // this.info.addressF = this.infoForm.get('addressF').value;

    this._to = setTimeout(() => {
      this._google.search(this.infoForm.get('street1').value).subscribe(d => {
        this.matches = d;
      }, error => {
        console.log('error:', error);
      });
    }, 1000);
  }

  public selectedAddress(addr) {
    this.info.addressF = addr['formatted_address'];
    this.infoForm.get('address').setValue(this.info.address);
    this.info.address = JSON.parse(JSON.stringify(this.info.address));

    this.info.address.geolocation = addr.geometry.location;
    // console.log(this.info.addresses);

    for (let i = 0; i < addr.address_components.length; i++) {
      switch (addr.address_components[i].types[0]) {
        case 'street_number':
          this.info.address.street1 = addr.address_components[i].short_name;
          break;
        case 'route':
          this.info.address.street1 += ' ' + addr.address_components[i].short_name;
          break;
        case 'locality':
          this.info.address.city = addr.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          this.info.address.state = addr.address_components[i].short_name;
          break;
        case 'postal_code':
          this.info.address.postalCode = addr.address_components[i].short_name;
          break;
        case 'country':
          this.info.address.country = addr.address_components[i].short_name;
          break;
      }
    }

    // this.localStorage.set('deliveryinfo', this.info).subscribe(() => { });

    this.infoForm.patchValue({ address: this.info.address });
    this.infoForm.patchValue({ addressF: this.info.addressF });
    this.infoForm.patchValue({ street1: this.info.address.street1 });
    this.infoForm.patchValue({ street2: this.info.address.street2 });
    this.infoForm.patchValue({ city: this.info.address.city });
    this.infoForm.patchValue({ state: this.info.address.state });
    this.infoForm.patchValue({ postalCode: this.info.address.postalCode });
    this.infoForm.patchValue({ country: this.info.address.country });

    let matched = false;
    this.info.addresses.forEach(a => {
      if (a.street1 && a.street1.trim() === this.info.address.street1.trim() &&
        a.street2 && a.street2.trim() === this.info.address.street2.trim() &&
        a.city && a.city.trim() === this.info.address.city.trim() &&
        a.state && a.state.trim() === this.info.address.state.trim() &&
        a.postalCode && a.postalCode.trim() === this.info.address.postalCode.trim()) {

        matched = true;
      } else {
        return;
      }
    });

    if (matched || this._authSvc.CurrentUser.access_token.trim() === '') {
      this.saved = true;
    } else {
      this.saved = false;
    }

    // console.log(this.info.addresses);
  }

  private invalidName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.infoForm) { return null; }
        if (this.infoForm.pristine && !this.infoForm.dirty && !this.infoForm.touched) { return null; }

        if (control.value.trim().lastIndexOf(' ') < 1) {
          return { invalidName: { value: control.value } };
        }
        // if (a.length < 2) {
        //   return { invalidName: { value: control.value } };
        // }

        /*         if (this.infoForm.controls.name.value !== control.value) {
                  this.infoForm.patchValue({ name: control.value });
                } */
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }


  private invalidAddress(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.infoForm) { return null; }
        if (this.infoForm.pristine && !this.infoForm.dirty && !this.infoForm.touched) { return null; }

        if (control.value.split(',').length < 4) {
          return { invalidAddress: { value: control.value } };
        }

        this.infoForm.patchValue({ addressF: control.value });
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  public formChange(form: any): void {
    try {
      const nameArray = form.controls.name.value ? form.controls.name.value.split(' ') : [];

      // if (form.controls.addressF.value.split(',').length <= 3) {
      this.info.addressF = form.controls.street1.value + ', ' + form.controls.city.value + ', ' + form.controls.state.value + ' ' + form.controls.postalCode.value + ', ' + form.controls.country.value;
      form.controls.addressF.value = this.info.addressF;
      // }

      this.infoForm.patchValue({ name: (form.controls.name.value) ? form.controls.name.value.trim() : '' });
      this.infoForm.patchValue({ firstname: nameArray.length > 0 ? nameArray[0] : '' });
      this.infoForm.patchValue({ lastname: nameArray.length > 1 ? nameArray[1] : '' });
      this.infoForm.patchValue({ email: form.controls.email.value });
      this.infoForm.patchValue({ phone: form.controls.phone.value });
      this.infoForm.patchValue({ address: form.controls.address.value });
      this.infoForm.patchValue({ addressF: form.controls.addressF.value });
      this.infoForm.patchValue({ street1: form.controls.street1.value });
      this.infoForm.patchValue({ street2: form.controls.street2.value });
      this.infoForm.patchValue({ city: form.controls.city.value });
      this.infoForm.patchValue({ state: form.controls.state.value });
      this.infoForm.patchValue({ postalCode: form.controls.postalCode.value });

      this.info.name = this.infoForm.get('name').value;
      this.info.firstname = this.infoForm.get('firstname').value;
      this.info.lastname = this.infoForm.get('lastname').value;
      this.info.email = this.infoForm.get('email').value ? this.infoForm.get('email').value : '';
      this.info.phone = this.infoForm.get('phone').value ? this.infoForm.get('phone').value : '';
      this.info.address = this.infoForm.get('address').value;
      this.info.addressF = this.infoForm.get('addressF').value;
      this.info.address.street1 = this.infoForm.get('street1').value;
      this.info.address.street2 = this.infoForm.get('street2').value;
      this.info.address.city = this.infoForm.get('city').value;
      this.info.address.state = this.infoForm.get('state').value;
      this.info.address.postalCode = this.infoForm.get('postalCode').value;

      // this.localStorage.set('deliveryinfo', this.info).subscribe(() => { });
    } catch (error) {
      console.log(error);
    }
  }

  public openSelection() {
    const dref = this._addressDialog.open(AddressSelectionComponent, {
      data: this.info || new Info(),
      maxWidth: 650
    });

    dref.afterClosed().subscribe(index => {
      if (index >= 0) {
        this.info.address = JSON.parse(JSON.stringify(this.info.addresses[index]));
        this.info.addressF = this.info.address.street1 + this.info.address.street2 + ', ' + this.info.address.city + ', ' + this.info.address.state + ' - ' + this.info.address.postalCode;

        // this.localStorage.set('deliveryinfo', this.info).subscribe(() => { });

        this.infoForm.patchValue({ address: this.info.address });
        this.infoForm.patchValue({ addressF: this.info.addressF });
        this.infoForm.patchValue({ street1: this.info.address.street1 });
        this.infoForm.patchValue({ street2: this.info.address.street2 });
        this.infoForm.patchValue({ city: this.info.address.city });
        this.infoForm.patchValue({ state: this.info.address.state });
        this.infoForm.patchValue({ postalCode: this.info.address.postalCode });
        this.infoForm.patchValue({ country: this.info.address.country });

      }
      return;
    });
  }

  public listenForAutoFill() {
    try {

      const email: any = document.getElementById('adEmail');
      email.addEventListener('blur', () => {
        if (email.value) {
          this.infoForm.patchValue({ email: email.value });
        }
      });

      const phone: any = document.getElementById('adPhone');
      phone.addEventListener('blur', () => {
        if (phone.value) {
          this.infoForm.patchValue({ phone: phone.value });
        }
      });

      const street: any = document.getElementById('adStreet1');
      street.addEventListener('blur', () => {
        if (street.value) {
          this.infoForm.patchValue({ street1: street.value });
        }
      });

      const city: any = document.getElementById('adCity');
      city.addEventListener('blur', () => {
        if (city.value) {
          this.infoForm.patchValue({ city: city.value });
        }
      });

      const state: any = document.getElementById('adState');
      state.addEventListener('change', () => {
        if (state.textContent) {
          this.infoForm.patchValue({ state: state.textContent });
        }
      });

      state.addEventListener('blur', () => {
        if (state.textContent) {
          this.infoForm.patchValue({ state: state.textContent });
        }
      });

      const postalcode: any = document.getElementById('adPostalCode');
      postalcode.addEventListener('blur', () => {
        if (postalcode.value) {
          this.infoForm.patchValue({ postalCode: postalcode.value });
        }
      });

    } catch (error) {
      console.log(error.message);
    }
  }

  public saveAddress(event: any) {
    if (event.currentTarget.checked === true) {

      const addr = this.info.addresses.find(a => a.street1 === this.info.address.street1 && a.postalCode === this.info.address.postalCode);

      if (!addr) {
        this.info.addresses.push(this.info.address);

        if (this.user) {
          this.user.addresses = this.info.addresses;

          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }

          this.userSubscription = this.userService.updateUserInfo(this.user).subscribe();
        }
      }
    }
  }

}
