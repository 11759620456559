import { Injectable } from '@angular/core';
import { User } from 'projects/common/src/lib/user';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError, retry } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public rolesSaved = true;
  public _rolesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.rolesSaved);
  public roleSaveStatus = this._rolesSaved.asObservable();

  constructor(public http: HttpClient, public globalservice: GlobalService, public localStorage: StorageMap) { }

  public updatedRoleSaleStatus(status: boolean) {
    this._rolesSaved.next(status);
  }

  public get RoleSaleStatus() {
    return this._rolesSaved;
  }

  public addMerchant(user: User): Observable<User> {
    try {
      return this.http.post<User>('/api/user', user, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getAllUsers(): Observable<User[]> {
    try {
      return this.http.get<User[]>('/api/users/', httpOptions)
        .pipe(
          catchError(this.handleError),
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public addUser(user: User): Observable<User> {
    try {
      return this.http.post<User>('/api/user', user, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getUserByEmail(email: string): Observable<User> {
    try {
      return this.http.get<User>('/api/user/email/' + email, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getUserById(id: string): Observable<User> {
    try {
      return this.http.get<User>('/api/user/' + id, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getUserContacts(): Observable<any> {
    try {
      return this.http.get<User>('/api/user/contacts', httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public updateUserInfo(user: User): Observable<any> {
    try {
      return this.http.put<User>('/api/user', user)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public updateUserProfile(user: User): Observable<User> {
    try {
      return this.http.put<User>('/api/user/profile', user)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public deleteUser(user: User): Observable<any> {
    try {
      return this.http.post('/api/user/delete', user)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
