import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EmailOrderConfirmation } from 'projects/common/src/lib/email-order-confirmation';

/* const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}; */

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  // public buffer: Buffer;

  constructor(public http: HttpClient) { }

  public sendEmailConfirmation(email: EmailOrderConfirmation): Observable<any> {

    return this.http.post<any>('/api/email', email)
      .pipe(
        catchError(this.handleError)
      );
  }

  public sendEmail(email: any): Observable<any> {
    return this.http.post<any>('/api/email', email)
      .pipe(
        catchError(this.handleError)
      );
  }

  public handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(err);
  }
}
