export class Address {
    public street1 = '';
    public street2 = '';
    public city = '';
    public state = '';
    public postalCode = '';
    public country = 'USA';
    public isDefault = false;
    public geolocation: any;
    public isSelected = false;
    public isBillingAddress = false;
    public addressType: number;         // 0 - Billing Address, 1 - Shipping Address

    constructor() {
        this.street1 = '';
        this.street2 = '';
        this.city = '';
        this.state = '';
        this.postalCode = '';
        this.country = 'USA';
        this.isDefault = false;
        this.isSelected = false;
        this.isBillingAddress = false;
        this.addressType = 0;
    }
}
