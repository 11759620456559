import { EventEmitter, Injectable, Output } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Item } from 'projects/common/src/lib/item';
import { StoreCartModel, ISchedule, TipType, Tip, ScheduleType } from 'projects/common/src/lib/store-cart.model';
import { Merchant } from 'projects/common/src/lib/merchant';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { exists } from 'fs';
import { DeliveryService } from './delivery.service';
import { Observable, of } from 'rxjs';
import { OptionalItem } from 'projects/common/src/lib/optional-item';
import { ExtraAmount } from 'projects/common/src/lib/optional-group';

/*
 * This service will be used in the store component
 * and the store cart component.
 * the cart will load based on whichever merchant page they are viewing
 * that way they can visit multiple merchants in the same browser session and have different carts with each
 * without needing to clear out any previous cart entries in localstorage.
 */

@Injectable({
  providedIn: 'root',
})
export class StoreCartService {

  private _merchant: Merchant;
  private _storeCartModel: StoreCartModel;
  private _resolveTips = false;

  private deliveryPosition = {
    lat: 0,
    lng: 0,
  };

  private delFee = {
    fee: 0,
    isPercentage: false,
  };

  @Output() StoreCartModelEmitter: EventEmitter<StoreCartModel> = new EventEmitter();

  constructor(private _localStorage: StorageMap, private delService: DeliveryService) { }

  private _UpdateCart() {
    if (this._merchant && this._merchant._id) {
      this._localStorage.set(this._merchant._id, this._storeCartModel).subscribe(() => {
        this.GetCart();
      }, (error: any) => {
        console.log('store-cart.service _UpdateCart error: ' + error.message);
      });
    }
  }

  public async CreateCart(m: Merchant, rt: boolean = false): Promise<StoreCartModel> {
    return new Promise(async (resolve, reject) => {
      this._merchant = m;
      this._resolveTips = rt;
      // check storage if this cart already exists:
      this._localStorage.get(m._id).subscribe((cart: StoreCartModel) => {
        // set the cart:
        this._storeCartModel = cart || new StoreCartModel();

        // let nd = new Date().getTime() + ((m.settings.waittime || 20) * 60 * 1000);
        // round nd up to the nearest 5 minute mark:
        // let ndate = new Date(nd);
        // ndate.setMinutes(ndate.getMinutes() + 5 - (ndate.getMinutes() % 5),0,0);
        // nd = ndate.getTime();

        // if (this._storeCartModel.deliverytype == OrderTypeEnum.Delivery) nd += (20 * 60 * 1000);
        // this._storeCartModel.schedule.value = this._storeCartModel.schedule.value > nd ? this._storeCartModel.schedule.value : nd;

        // check if the schedule.value > store's closing time?
        this._storeCartModel.schedule.value = this._storeCartModel.schedule.value !== 0 ? this._storeCartModel.schedule.value : 0;

        // this._storeCartModel.schedule.wait = m.settings.pickupWaittime || 20;

        this._UpdateCart();
        resolve(this._storeCartModel);
      });
    });
  }

  public AddItem(_item: Item) {
    try {
      // TODO: REMOVE DUPLICATED ITEM -> Increse QTY instead
      const item = JSON.parse(JSON.stringify(_item)) as Item;
      const possible_matches = this._storeCartModel.itemList.filter(o => {
        if (item.itemid === o.itemid) {
          return o;
        }
      });

      let matched = false;

      for (const i of possible_matches) {
        // matched = false;
        if (item.beverage && i.beverage && item.size === i.size) {
          matched = true;
          // } else if (!item.beverage && item.options && item.options.length === i.options.length && i.options.length === 0 &&
          //   item.modifiers.length === i.modifiers.length && i.modifiers.length === 0 &&
          //   item.sides.length === i.sides.length && i.sides.length === 0) {
          //   matched = true;
        } else if (!item.beverage && item.options.length === i.options.length &&
          item.modifiers.length === i.modifiers.length && item.sides.length === i.sides.length &&
          ((item.comments && i.comments && item.comments.trim() === i.comments.trim()) || (!item.comments && !i.comments))) {
          matched = true;
        }
        if (matched && item.options.length > 0) {
          for (const option of item.options) {
            // Add Extra -  && option.extra === s.extra
            const found = i.options.findIndex(s => s.title === option.title && option.selected === s.selected && option.ratio === s.ratio)

            if (found < 0) {
              matched = false;
              break;
            } else {
              const extra = option.extra.find((e: ExtraAmount) => e.selected);
              const oldExtra = i.options[found].extra.find((e: ExtraAmount) => e.selected);
              if ((!extra && !oldExtra) || (extra && oldExtra && extra.title === oldExtra.title)) {
                matched = true;
              } else {
                matched = false;
                break;
              }
            }
            // for (let index = 0; index < i.options.length; index++) {
            //   if (option.title === i.options[index].title && option.selected === i.options[index].selected) {
            //     const extra = option.extra.find((e: ExtraAmount) => e.selected);
            //     const oldExtra = i.options[index].extra.find((e: ExtraAmount) => e.selected);
            //     if ((!extra && !oldExtra) || (extra && oldExtra && extra.title === oldExtra.title)) {
            //       matched = true;
            //     } else {
            //       matched = false;
            //     }
            //     break;
            //   } else {
            //     matched = false;
            //   }
            // }
          }
        }

        if (!matched) {
          continue;
        }

        if (matched && item.modifiers.length > 0) {
          for (const modifier of item.modifiers) {
            for (let index = 0; index < i.modifiers.length; index++) {
              // Add Extra -  && option.extra === s.extra
              if (modifier.title === i.modifiers[index].title && modifier.selected === i.modifiers[index].selected && modifier.ratio === i.modifiers[index].ratio) {
                matched = true;
                break;
              } else {
                matched = false;
              }
            }
          }
        }

        if (!matched) {
          continue;
        }

        if (matched && item.sides.length > 0) {
          for (const side of item.sides) {
            // Add Extra -  && option.extra === s.extra
            const found = i.sides.findIndex(s => s.title === side.title && side.selected === s.selected && side.ratio === s.ratio)

            if (found < 0) {
              matched = false;
              break;
            } else {
              const extra = side.extra.find((e: ExtraAmount) => e.selected);
              const oldExtra = i.sides[found].extra.find((e: ExtraAmount) => e.selected);
              if ((!extra && !oldExtra) || (extra && oldExtra && extra.title === oldExtra.title)) {
                matched = true;
              } else {
                matched = false;
                break;
              }
            }
            // for (let index = 0; index < i.sides.length; index++) {
            //   if (side.title === i.sides[index].title && side.selected === i.sides[index].selected) {
            //     matched = true;
            //     break;
            //   } else {
            //     matched = false;
            //   }
            // }
          }
        }
        // }

        // if (matched) {
        //   i.qty += item.qty;
        //   if (modify_index !== null && modify_index >= 0) {
        //     this.cart.items.splice(modify_index, 1);
        //   }
        //   break;
        // }
        // if (!matched) {
        //   if (modify_index !== null && modify_index >= 0) {
        //     this.cart.items[modify_index] = item;
        //   } else {
        //     this.cart.items.push(item);
        //   }
        // }

        if (matched) {
          i.qty += item.qty;
          this._storeCartModel.itemCount += item.qty;
          break;
        }
      }

      if (!matched) {
        // give it a new id, add it to the list
        this._storeCartModel.itemList.push(item);
        this._storeCartModel.itemCount += item.qty;
      }

      console.log('staore-cart.service: this._UpdateCart()');
      // update the cart:
      this._UpdateCart();
    } catch (error) {
      console.log('staore-cart.service error: ' + error.message);
    }
  }

  // OLD - Keep for backup as it's working prior to ratio
  // public AddItem(_item: Item) {
  //   try {
  //     // TODO: REMOVE DUPLICATED ITEM -> Increse QTY instead
  //     const item = JSON.parse(JSON.stringify(_item)) as Item;
  //     const possible_matches = this._storeCartModel.itemList.filter(o => {
  //       if (item.itemid === o.itemid) {
  //         return o;
  //       }
  //     });

  //     let matched = false;

  //     for (const i of possible_matches) {
  //       // matched = false;
  //       if (item.beverage && i.beverage && item.size === i.size) {
  //         matched = true;
  //         // } else if (!item.beverage && item.options && item.options.length === i.options.length && i.options.length === 0 &&
  //         //   item.modifiers.length === i.modifiers.length && i.modifiers.length === 0 &&
  //         //   item.sides.length === i.sides.length && i.sides.length === 0) {
  //         //   matched = true;
  //       } else if (!item.beverage && item.options.length === i.options.length &&
  //         item.modifiers.length === i.modifiers.length && item.sides.length === i.sides.length &&
  //         ((item.comments && i.comments && item.comments.trim() === i.comments.trim()) || (!item.comments && !i.comments))) {
  //         matched = true;
  //       }
  //       if (matched && item.options.length > 0) {
  //         for (const option of item.options) {
  //           for (let index = 0; index < i.options.length; index++) {
  //             if (option.title === i.options[index].title && option.selected === i.options[index].selected) {
  //               matched = true;
  //               break;
  //             } else {
  //               matched = false;
  //             }
  //           }
  //         }
  //       }

  //       if (!matched) {
  //         continue;
  //       }

  //       if (matched && item.modifiers.length > 0) {
  //         for (const modifier of item.modifiers) {
  //           for (let index = 0; index < i.modifiers.length; index++) {
  //             if (modifier.title === i.modifiers[index].title && modifier.selected === i.modifiers[index].selected) {
  //               matched = true;
  //               break;
  //             } else {
  //               matched = false;
  //             }
  //           }
  //         }
  //       }

  //       if (!matched) {
  //         continue;
  //       }

  //       if (matched && item.sides.length > 0) {
  //         for (const side of item.sides) {
  //           const found = i.sides.findIndex(s => s.title === side.title && side.selected === s.selected)

  //           if (found < 0) {
  //             matched = false;
  //             break;
  //           } else {
  //             matched = true;
  //           }
  //           // for (let index = 0; index < i.sides.length; index++) {
  //           //   if (side.title === i.sides[index].title && side.selected === i.sides[index].selected) {
  //           //     matched = true;
  //           //     break;
  //           //   } else {
  //           //     matched = false;
  //           //   }
  //           // }
  //         }
  //       }
  //       // }

  //       // if (matched) {
  //       //   i.qty += item.qty;
  //       //   if (modify_index !== null && modify_index >= 0) {
  //       //     this.cart.items.splice(modify_index, 1);
  //       //   }
  //       //   break;
  //       // }
  //       // if (!matched) {
  //       //   if (modify_index !== null && modify_index >= 0) {
  //       //     this.cart.items[modify_index] = item;
  //       //   } else {
  //       //     this.cart.items.push(item);
  //       //   }
  //       // }

  //       if (matched) {
  //         i.qty += item.qty;
  //         this._storeCartModel.itemCount += item.qty;
  //         break;
  //       }
  //     }

  //     if (!matched) {
  //       // give it a new id, add it to the list
  //       this._storeCartModel.itemList.push(item);
  //       this._storeCartModel.itemCount += item.qty;
  //     }

  //     console.log('staore-cart.service: this._UpdateCart()');
  //     // update the cart:
  //     this._UpdateCart();
  //   } catch (error) {
  //     console.log('staore-cart.service error: ' + error.message);
  //   }
  // }

  public UpdateItem(item, itemIdx) {
    this._localStorage.get(this._merchant._id).subscribe((cart: StoreCartModel) => {
      this._storeCartModel = cart;
      this._storeCartModel.itemList[itemIdx] = item;
      // update cart:
      this._UpdateCart();
    });
  }

  public UpdateSchedule(r: ISchedule) {
    this._storeCartModel.schedule = r;
    this._UpdateCart();
  }

  public UpdateDeliveryType(value: OrderTypeEnum) {
    this._localStorage.get(this._merchant._id).subscribe((cart: StoreCartModel) => {
      this._storeCartModel = cart;
      this._storeCartModel.deliverytype = value;
      switch (value) {
        case OrderTypeEnum.Delivery:
          this._storeCartModel.deliverytypetext = 'Delivery';
          break;
        case OrderTypeEnum.DineIn:
          this._storeCartModel.deliverytypetext = 'Dine In';
          break;
        case OrderTypeEnum.PickUp:
          this._storeCartModel.deliverytypetext = 'Pickup';
          break;
        case OrderTypeEnum.ToGo:
          this._storeCartModel.deliverytypetext = 'To Go';
          break;
        default:
          this._storeCartModel.deliverytypetext = 'Delivery';
          break;
      }
      this._UpdateCart();
    });
  }

  public async setDeliveryPosition(address: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (address) {
        // if (this._merchant.settings.DeliveryZones && this._merchant.settings.DeliveryZones.length > 0) {
        await this.delService.getGeocode(address).subscribe(d => {
          if (d) {
            const R = 3958.8; // Radius of the Earth in miles
            const rlat1 = this._merchant.latitude * (Math.PI / 180); // Convert degrees to radians
            const rlat2 = d.lat * (Math.PI / 180); // Convert degrees to radians

            const difflat = rlat2 - rlat1; // Radian difference (latitudes)
            const difflon = (d.lng - this._merchant.longitude) * (Math.PI / 180); // Radian difference (longitudes)

            const a = Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.sin(difflon / 2) * Math.sin(difflon / 2) * Math.cos(rlat1) * Math.cos(rlat2);

            const distance = 2 * R * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            // console.log(distance);

            let inRange = false;

            if (this._merchant.settings.DeliveryZones !== null && this._merchant.settings.DeliveryZones.length > 0) {
              this._merchant.settings.DeliveryZones.forEach(z => {
                // console.log(z);
                if (z.Enabled && distance >= z.Start && distance <= z.End) {
                  this._merchant.settings.deliveryfee.fee = z.Fee;
                  this._merchant.settings.deliveryfee.isPercentage = z.PercentOn;
                  inRange = true;
                }
              });

              if (!inRange) {
                resolve({ "inRange": inRange, "distance": 0, "lat": d.lat, "lng": d.lng });
              } else {
                this._UpdateCart();
                resolve({ "inRange": inRange, "distance": distance, "lat": d.lat, "lng": d.lng });
              }
            } else {
              this._UpdateCart();
              resolve({ "inRange": true, "distance": distance, "lat": d.lat, "lng": d.lng });
            }
          } else {
            resolve({ "inRange": false, "distance": 0, "lat": 0, "lng": 0 });
          }
        });
        // } else {
        //   this._UpdateCart();
        //   resolve(true);
        // }

        // this.deliveryPosition.lat = position.lat;
        // this.deliveryPosition.lng = position.lng;
        // this._localStorage.set('deliveryPosition', position).subscribe();

        // this._UpdateCart();
      }
      // else {
      //   this._UpdateCart();
      //   resolve(true);
      // }
    });
  }

  public UpdateTip(newTip: Tip) {

    this._localStorage.get(this._merchant._id).subscribe((cart: StoreCartModel) => {
      this._storeCartModel = cart;
      this._storeCartModel.tip = newTip;
      this._UpdateCart();
    });
  }

  public RemoveItem(cartItemIdx: number) {
    // remove the item with this cartItemId from the cart
    this._storeCartModel.itemList.splice(cartItemIdx, 1);

    // update the cart:
    this._UpdateCart();
  }

  public ClearCart() {
    // remove the cart completely:
    this._localStorage.delete(this._merchant._id).subscribe();
    this._storeCartModel.itemList = [];
    this._UpdateCart();
  }

  // calculate totals and stuff, then emit the cart.
  public GetCart() {
    const scm = new StoreCartModel();

    this._localStorage.get(this._merchant._id).subscribe((model: StoreCartModel) => {

      if (model) {
        this._storeCartModel = model;
      } else {
        this._storeCartModel = new StoreCartModel();
      }

      if (this._storeCartModel) {
        const tr = this._merchant.settings.taxrate / 100 || 0;
        let taxAmount = 0;
        let taxText = '';
        let taxVal = 0;
        let subtotalVal = 0;

        const newList = JSON.parse(JSON.stringify(this._storeCartModel.itemList));

        scm.tip = this._storeCartModel.tip;

        // calculate item totals:
        newList.forEach((i: Item) => {
          let extras = 0;
          if (i.modifiers.length > 0) {
            i.modifiers.forEach((s: OptionalItem) => {
              extras += s.price;
              // const extra = s.extra.find((e: ExtraAmount) => e.selected);
              // if (extra) {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += extra.fee;
              //   } else {
              //     extras += extra.fee / 2;
              //   }
              // } else {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += s.price;
              //   } else {
              //     extras += s.price / 2;
              //   }
              // }
            });
            // extras += i.sides.map(s => s.price).reduce((p, c) => p + (c || 0), 0);
          }
          if (i.sides.length > 0) {
            i.sides.forEach((s: OptionalItem) => {
              extras += s.price;
              // const extra = s.extra.find((e: ExtraAmount) => e.selected);
              // if (extra) {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += extra.fee;
              //   } else {
              //     extras += extra.fee / 2;
              //   }
              // } else {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += s.price;
              //   } else {
              //     extras += s.price / 2;
              //   }
              // }
            });
            // extras += i.sides.map(s => s.price).reduce((p, c) => p + (c || 0), 0);
          }
          if (i.options.length > 0) {
            i.options.forEach((s: OptionalItem) => {
              extras += s.price;
              // const extra = s.extra.find((e: ExtraAmount) => e.selected);
              // if (extra) {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += extra.fee;
              //   } else {
              //     extras += extra.fee / 2;
              //   }
              // } else {
              //   if (s.ratio === 0 || s.ratio === -99) {
              //     extras += s.price;
              //   } else {
              //     extras += s.price / 2;
              //   }
              // }
            });
            // extras += i.options.map(s => s.price).reduce((p, c) => p + (c || 0), 0);
          }
          // i.price = parseFloat(((i.orig_price + extras) * i.qty).toFixed(2));
          // i.price = parseFloat(((i.price + extras) * i.qty).toFixed(2)); // XT calculates price * qty so we don't need this
          // i.price = parseFloat((i.price + extras).toFixed(2));    // XT calculates price * qty so we don't need this
          i.taxAmount = i.qty * (i.price + extras) * (i.taxPercent ? i.taxPercent : 0);
          subtotalVal += i.qty * (i.price + extras);
          i.labeledPrice = (i.qty * (i.price + extras)).toFixed(2);

          taxAmount += i.taxAmount;
        });

        // each time this is called, also update the number values:
        // const subtotalVal = newList.map(i => ((i.price + extras) * i.qty)).reduce((p, c) => p + (c || 0), 0);

        scm.subtotal = {
          Value: parseFloat(subtotalVal.toFixed(2)),
          Text: `$${subtotalVal.toFixed(2)}`,
        };

        if (this._merchant.settings.CustomerServiceFeeOn) {
          scm.conveniencefee = {
            Text: `$${(0).toFixed(2)}`,
            Value: 0,
          };

          if (this._merchant.settings.CSFIsDollar) {
            scm.servicefee = {
              Text: `$${(this._merchant.settings.CustomerServiceFee || 0).toFixed(2)}`,
              Value: this._merchant.settings.CustomerServiceFee || 0,
            };
          } else {
            const fee = ((this._merchant.settings.CustomerServiceFee || 0) / 100) * subtotalVal;
            scm.servicefee = {
              Text: `$${fee.toFixed(2)}`,
              Value: fee,
            };
          }
        } else {
          scm.servicefee = {
            Text: `$${(0).toFixed(2)}`,
            Value: 0,
          };

          scm.conveniencefee = {
            Text: `$${(this._merchant.settings.ConvenienceFee || 0).toFixed(2)}`,
            Value: this._merchant.settings.ConvenienceFee || 0,
          };
        }

        // if (this._resolveTips) {
        let tipVal = 0;
        if (this._storeCartModel.tip.Type === TipType.Percent) {
          tipVal = subtotalVal * this._storeCartModel.tip.Percent;
          const maxAmount = parseFloat(this._merchant.settings.tipSettings.maxAmount);

          if (tipVal > maxAmount) {
            tipVal = maxAmount;
          }
        } else {
          tipVal = this._storeCartModel.tip.Flat || 0;
        }

        scm.tip = JSON.parse(JSON.stringify(this._storeCartModel.tip));
        scm.tip.Value = parseFloat(tipVal.toFixed(2));
        scm.tip.Text = `$${tipVal.toFixed(2)}`;
        // }

        if (this._merchant.apiUrl && this._merchant.apiUrl !== '') {
          taxText = taxAmount.toFixed(2);
          taxVal = parseFloat(taxText);
        } else {
          taxText = (scm.subtotal.Value * tr).toFixed(2);
          taxVal = parseFloat(taxText);
        }
        scm.tax = {
          Value: taxVal,
          Text: `$${taxText}`,
        };

        // we need this before calculating the total
        if (this._storeCartModel.deliverytype === OrderTypeEnum.Delivery) {
          if (this._merchant.settings.deliveryfee.isPercentage) {
            const fee = this._merchant.settings.deliveryfee.fee >= 1 ? this._merchant.settings.deliveryfee.fee / 100 : this._merchant.settings.deliveryfee.fee;
            scm.deliveryfee.Value = fee * scm.subtotal.Value;
          } else {
            scm.deliveryfee.Value = this._merchant.settings.deliveryfee.fee;
          }
          scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
        } else {
          scm.deliveryfee.Value = 0;
          scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
        }

        let totalVal = parseFloat(scm.subtotal.Value.toFixed(2)) + parseFloat(scm.tax.Value.toFixed(2)) + parseFloat(scm.deliveryfee.Value.toFixed(2)) + parseFloat(scm.servicefee.Value.toFixed(2)) + parseFloat(scm.conveniencefee.Value.toFixed(2));
        // if (this._resolveTips) { totalVal += scm.tip.Value; }
        totalVal += scm.tip.Value;
        const totalText = (totalVal).toFixed(2);
        scm.total = {
          Text: `$${totalText}`,
          Value: totalVal,
        };

        scm.itemList = newList;
        scm.itemCount = scm.itemList.map(i => i.qty).reduce((p, c) => p + (c || 0), 0);
        scm.schedule = this._storeCartModel.schedule;
        scm.deliverytype = this._storeCartModel.deliverytype;
        scm.deliveryInstructions = this._storeCartModel.deliveryInstructions;

        this._localStorage.get('deliveryPosition').subscribe((p: any) => {
          if (p) {
            this.deliveryPosition.lat = p.lat;
            this.deliveryPosition.lng = p.lng;
          }
        });

        // moved this before total
/*         if (this._storeCartModel.deliverytype === OrderTypeEnum.Delivery) {
          if (this._merchant.settings.deliveryfee.isPercentage) {
            const fee = this._merchant.settings.deliveryfee.fee >= 1 ? this._merchant.settings.deliveryfee.fee / 100 : this._merchant.settings.deliveryfee.fee;
            scm.deliveryfee.Value = fee * scm.subtotal.Value;
          } else {
            scm.deliveryfee.Value = this._merchant.settings.deliveryfee.fee;
          }
          scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
        } else {
          scm.deliveryfee.Value = 0;
          scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
        } */

//        scm.total.Value = totalVal + scm.deliveryfee.Value; // <<-- are we double chanrging the deliver fee since it was already added to the total above
        scm.total.Text = '$' + scm.total.Value.toFixed(2);

        // check if it is a delivery:
        // if (scm.deliverytype == OrderTypeEnum.Delivery) {
        //  //add delivery time to wait time:
        //  //should be found in merchantinfo
        //  //this._merchant.settings.deliverytime
        //  scm.schedule.wait = (parseInt(`${this._merchant.settings.waittime}`) || 20) + 20;

        //  let nd = new Date().getTime() + ((this._merchant.settings.waittime || 20) * 60 * 1000);
        //  nd += 20 * 60 * 1000;
        //  //round nd up to the nearest 5 minute mark:
        //  let ndate = new Date(nd);
        //  ndate.setMinutes(ndate.getMinutes() + 5 - (ndate.getMinutes() % 5), 0, 0);
        //  nd = ndate.getTime();
        //  scm.schedule.value = scm.schedule.text == ScheduleType.ASAP ? nd : scm.schedule.value;
        // } else {
        //  scm.schedule.wait = this._merchant.settings.waittime || 20;
        //  let nd = new Date().getTime() + ((this._merchant.settings.waittime || 20) * 60 * 1000);
        //  //round nd up to the nearest 5 minute mark:
        //  let ndate = new Date(nd);
        //  ndate.setMinutes(ndate.getMinutes() + 5 - (ndate.getMinutes() % 5), 0, 0);
        //  nd = ndate.getTime();
        //  scm.schedule.value = scm.schedule.text == ScheduleType.ASAP ? nd : scm.schedule.value;
        // }

        scm.deliverytypetext = this._storeCartModel.deliverytypetext;
        this._localStorage.set(this._merchant._id, scm).subscribe(() => {
          this.StoreCartModelEmitter.emit(scm);
        });

      }

    }, (error: any) => {
      if (error) {
        console.log('store-cart.service getCart error' + error.message);
      }
    });
  }

  // OLD - Keep for backup as it's working prior to ratio
  // calculate totals and stuff, then emit the cart.
  // public GetCart() {
  //   const scm = new StoreCartModel();

  //   this._localStorage.get(this._merchant._id).subscribe((model: StoreCartModel) => {

  //     if (model) {
  //       this._storeCartModel = model;
  //     } else {
  //       this._storeCartModel = new StoreCartModel();
  //     }

  //     if (this._storeCartModel) {
  //       const tr = this._merchant.settings.taxrate || 0;
  //       let taxAmount = 0;
  //       let taxText = '';
  //       let taxVal = 0;
  //       let subtotalVal = 0;

  //       const newList = JSON.parse(JSON.stringify(this._storeCartModel.itemList));

  //       scm.tip = this._storeCartModel.tip;

  //       // calculate item totals:
  //       newList.forEach(i => {
  //         let extras = 0;
  //         if (i.sides.length > 0) {
  //           extras += i.sides.map(s => s.price).reduce((p, c) => p + (c || 0), 0);
  //         }
  //         if (i.options.length > 0) {
  //           extras += i.options.map(s => s.price).reduce((p, c) => p + (c || 0), 0);
  //         }
  //         // i.price = parseFloat(((i.orig_price + extras) * i.qty).toFixed(2));
  //         // i.price = parseFloat(((i.price + extras) * i.qty).toFixed(2)); // XT calculates price * qty so we don't need this
  //         // i.price = parseFloat((i.price + extras).toFixed(2));    // XT calculates price * qty so we don't need this
  //         i.taxAmount = i.qty * (i.price + extras) * (i.taxPercent ? i.taxPercent : 0);
  //         subtotalVal += i.qty * (i.price + extras);
  //         i.labeledPrice = (i.qty * (i.price + extras)).toFixed(2);

  //         taxAmount += i.taxAmount;
  //       });

  //       // each time this is called, also update the number values:
  //       // const subtotalVal = newList.map(i => ((i.price + extras) * i.qty)).reduce((p, c) => p + (c || 0), 0);

  //       scm.subtotal = {
  //         Value: parseFloat(subtotalVal.toFixed(2)),
  //         Text: `$${subtotalVal.toFixed(2)}`
  //       };

  //       if (this._merchant.settings.CustomerServiceFeeOn) {
  //         scm.conveniencefee = {
  //           Text: `$${(0).toFixed(2)}`,
  //           Value: 0
  //         };

  //         if (this._merchant.settings.CSFIsDollar) {
  //           scm.servicefee = {
  //             Text: `$${(this._merchant.settings.CustomerServiceFee || 0).toFixed(2)}`,
  //             Value: this._merchant.settings.CustomerServiceFee || 0
  //           };
  //         } else {
  //           const fee = (this._merchant.settings.CustomerServiceFee || 0) * subtotalVal;
  //           scm.servicefee = {
  //             Text: `$${fee.toFixed(2)}`,
  //             Value: fee
  //           };
  //         }
  //       } else {
  //         scm.servicefee = {
  //           Text: `$${(0).toFixed(2)}`,
  //           Value: 0
  //         };

  //         scm.conveniencefee = {
  //           Text: `$${(this._merchant.settings.ConvenienceFee || 0).toFixed(2)}`,
  //           Value: this._merchant.settings.ConvenienceFee || 0
  //         };
  //       }

  //       // if (this._resolveTips) {
  //       let tipVal = 0;
  //       if (this._storeCartModel.tip.Type === TipType.Percent) {
  //         tipVal = subtotalVal * this._storeCartModel.tip.Percent;
  //       } else {
  //         tipVal = this._storeCartModel.tip.Flat || 0;
  //       }

  //       scm.tip = JSON.parse(JSON.stringify(this._storeCartModel.tip));
  //       scm.tip.Value = parseFloat(tipVal.toFixed(2));
  //       scm.tip.Text = `$${tipVal.toFixed(2)}`;
  //       // }

  //       if (this._merchant.apiUrl && this._merchant.apiUrl !== '') {
  //         taxText = taxAmount.toFixed(2);
  //         taxVal = parseFloat(taxText);
  //       } else {
  //         taxText = (scm.subtotal.Value * tr).toFixed(2);
  //         taxVal = parseFloat(taxText);
  //       }
  //       scm.tax = {
  //         Value: taxVal,
  //         Text: `$${taxText}`
  //       };

  //       let totalVal = scm.subtotal.Value + scm.tax.Value + scm.deliveryfee.Value + scm.servicefee.Value + scm.conveniencefee.Value;
  //       // if (this._resolveTips) { totalVal += scm.tip.Value; }
  //       totalVal += scm.tip.Value;
  //       const totalText = (totalVal).toFixed(2);
  //       scm.total = {
  //         Value: totalVal,
  //         Text: `$${totalText}`
  //       };

  //       scm.itemList = newList;
  //       scm.itemCount = scm.itemList.map(i => i.qty).reduce((p, c) => p + (c || 0), 0);
  //       scm.schedule = this._storeCartModel.schedule;
  //       scm.deliverytype = this._storeCartModel.deliverytype;
  //       scm.deliveryInstructions = this._storeCartModel.deliveryInstructions;

  //       this._localStorage.get('deliveryPosition').subscribe((p: any) => {
  //         if (p) {
  //           this.deliveryPosition.lat = p.lat;
  //           this.deliveryPosition.lng = p.lng;
  //         }
  //       });

  //       if (this._storeCartModel.deliverytype === OrderTypeEnum.Delivery) {
  //         if (this._merchant.settings.deliveryfee.isPercentage) {
  //           const fee = this._merchant.settings.deliveryfee.fee >= 1 ? this._merchant.settings.deliveryfee.fee / 100 : this._merchant.settings.deliveryfee.fee;
  //           scm.deliveryfee.Value = fee * scm.subtotal.Value;
  //         } else {
  //           scm.deliveryfee.Value = this._merchant.settings.deliveryfee.fee;
  //         }
  //         scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
  //       } else {
  //         scm.deliveryfee.Value = 0;
  //         scm.deliveryfee.Text = '$' + scm.deliveryfee.Value.toFixed(2);
  //       }

  //       scm.total.Value = totalVal + scm.deliveryfee.Value;
  //       scm.total.Text = '$' + scm.total.Value.toFixed(2);

  //       // check if it is a delivery:
  //       // if (scm.deliverytype == OrderTypeEnum.Delivery) {
  //       //  //add delivery time to wait time:
  //       //  //should be found in merchantinfo
  //       //  //this._merchant.settings.deliverytime
  //       //  scm.schedule.wait = (parseInt(`${this._merchant.settings.waittime}`) || 20) + 20;

  //       //  let nd = new Date().getTime() + ((this._merchant.settings.waittime || 20) * 60 * 1000);
  //       //  nd += 20 * 60 * 1000;
  //       //  //round nd up to the nearest 5 minute mark:
  //       //  let ndate = new Date(nd);
  //       //  ndate.setMinutes(ndate.getMinutes() + 5 - (ndate.getMinutes() % 5), 0, 0);
  //       //  nd = ndate.getTime();
  //       //  scm.schedule.value = scm.schedule.text == ScheduleType.ASAP ? nd : scm.schedule.value;
  //       // } else {
  //       //  scm.schedule.wait = this._merchant.settings.waittime || 20;
  //       //  let nd = new Date().getTime() + ((this._merchant.settings.waittime || 20) * 60 * 1000);
  //       //  //round nd up to the nearest 5 minute mark:
  //       //  let ndate = new Date(nd);
  //       //  ndate.setMinutes(ndate.getMinutes() + 5 - (ndate.getMinutes() % 5), 0, 0);
  //       //  nd = ndate.getTime();
  //       //  scm.schedule.value = scm.schedule.text == ScheduleType.ASAP ? nd : scm.schedule.value;
  //       // }

  //       scm.deliverytypetext = this._storeCartModel.deliverytypetext;
  //       this._localStorage.set(this._merchant._id, scm).subscribe(() => {
  //         this.StoreCartModelEmitter.emit(scm);
  //       });

  //     }

  //   }, (error: any) => {
  //     if (error) {
  //       console.log('store-cart.service getCart error' + error.message);
  //     }
  //   });
  // }

  get DeliveryType() {
    return this._storeCartModel.deliverytype !== undefined ? this._storeCartModel.deliverytype : OrderTypeEnum.Delivery;
  }

}
