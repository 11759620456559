<div id="order-receipt">
    <div id="top" fxLayoutAlign="center center">
        <div class="logo"></div>
        <div class="info">
            <h2>Online Orders</h2>
        </div>
    </div>
    <div id="mid" *ngIf="merchant">
        <div fxLayout="column" class="info">
            <div fxLayout="row" fxLayoutAlign="center center">
                <h2>{{merchant.businessName}}</h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a mat-button>
                    {{merchant.address}}<br />
                </a>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a mat-button>
                    {{merchant.city}}, {{merchant.state}} {{merchant.zip}}
                </a>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <span style="color: #459edb">{{merchant.phone}}</span>
            </div>
        </div>
    </div>
    <div id="bot" *ngIf="order">
        <div id="table">
            <table>
                <tr class="tabletitle">
                    <td class="item">
                        <h2>Item</h2>
                    </td>
                    <td class="Hours">
                        <h2>Qty</h2>
                    </td>
                    <td class="Rate">
                        <h2>Price</h2>
                    </td>
                </tr>
                <tr class="service" *ngFor="let item of order.items">
                    <td class="tableitem">
                        <p class="itemtext">{{item.title}}</p>
                    </td>
                    <td class="tableitem">
                        <p class="itemtext">{{item.qty}}</p>
                    </td>
                    <td class="tableitem">
                        <p class="itemtext">${{(item.price * item.qty).toFixed(2)}}</p>
                    </td>
                </tr>
                <tr class="tabletitle">
                    <td></td>
                    <td class="Rate">
                        <h2>Subtotal</h2>
                    </td>
                    <td class="payment">
                        <h2>${{order.subtotal.toFixed(2)}}</h2>
                    </td>
                </tr>
                <tr class="tabletitle">
                    <td></td>
                    <td class="Rate">
                        <h2>Tax</h2>
                    </td>
                    <td class="payment">
                        <h2>${{order.tax.toFixed(2)}}</h2>
                    </td>
                </tr>
                <tr class="tabletitle">
                    <td>

                    </td>
                    <td class="Rate">
                        <h2>Total</h2>
                    </td>
                    <td class="payment">
                        <h2>${{order.totalAmount.toFixed(2)}}</h2>
                    </td>
                </tr>
            </table>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div *ngIf="order && order.paymethods.length > 0" fxLayout="row" fxLayoutAlign="center center">
            <h3>Payment ID: {{order.paymethods[0].paymentId}}</h3>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div *ngIf="order && order.paymethods.length > 0" fxLayout="row" fxLayoutAlign="center center">
            <mat-accordion class="payment-details">
                <mat-expansion-panel (click)="paymentDetailToggle()" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="payment-detail-title">{{paymentDetailsTitle}}</span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="brand_logo">
                                <i [ngClass]="brandname" style="enable-background:new 0 0 47.834 47.834;"></i>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" class="info">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            Card: {{order.paymethods[0].cc_type}} {{order.paymethods[0].cc_last_four}}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            {{order.datetime}}<br />
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            Authorization Code: {{order.paymethods[0].approvalCode}}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            TID: {{order.paymethods[0].transactionIdentifer}}
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div id="legalcopy" *ngIf="merchant">
            <p class="legal"><strong>Thank you for your order!</strong>
            </p>
        </div>
    </div>
</div>
