import { Component, OnDestroy, OnInit, HostListener, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationExtras } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subscription } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { OperationHours } from 'projects/common/src/lib/operation-hours';
import { Category, CatItem } from 'projects/common/src/lib/category';
import { Item } from 'projects/common/src/lib/item';
import { LogIn } from 'projects/common/src/lib/login';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Common } from 'projects/common/src/lib/common';
import { Banner } from 'projects/common/src/lib/banner';
import { Option } from 'projects/common/src/lib/option';
import { ISchedule, ScheduleType, StoreCartModel } from 'projects/common/src/lib/store-cart.model';
import { User } from 'projects/common/src/lib/user';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { CategoryService } from 'projects/services/src/lib/category.service';
import { ItemService } from 'projects/services/src/lib/item.service';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { StoreCartService } from 'projects/services/src/lib/store-cart.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from '../registration-dialog/registration-dialog.component';
import { ScheduleDialogComponent, IScheduleDialogData } from '../schedule-dialog/schedule-dialog.component';
import { DateTimeFormatOptions } from 'luxon';
import { ItemDetailsModalComponent } from '../item-details-modal/item-details-modal.component';
import { StoreInfoDialogComponent } from '../store-info-dialog/store-info-dialog.component';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';
import { RatingsService } from 'projects/services/src/lib/ratings.service';
import { DeviceDetectorService } from 'ngx-device-detector';
/* import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser'; */
import { ConfigService } from 'projects/services/src/lib/config.service';
import { SmsService } from 'projects/services/src/lib/sms.service';
import { EmailOrderConfirmation } from 'projects/common/src/lib/email-order-confirmation';
import { EmailService } from 'projects/services/src/lib/email.service';
import { MenuCategory } from 'projects/common/src/lib/menu-category';
import { BannerService } from 'projects/services/src/lib/banner.service';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import * as moment from 'moment';
import 'moment-timezone';
import * as $ from 'jquery';

const MobileDetect = require('mobile-detect');

interface ItemCacheRecord {
  categoryid: string;
  category: string;
  subCategories: ItemCacheRecord[];
  index: number;
  items: Item[];
}

interface ItemCache {
  list: CatItem;
  expires: number;
}
// const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const WINDOWBREAKPOINT = 960;
const KEYS = {
  CATITEMKEY: 'CATEGORYITEMS'
};

declare var google: any;

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit, OnDestroy {

  mobileDetect: any;
  screenHeight = 0;
  screenWidth = 0;
  hideCart = false;

  sidenavObservable: Observable<boolean>;
  sidenavSubscription: Subscription;
  @Output() sidenavState = false;

  private itemCache: ItemCacheRecord[] = [];
  private itemCountSub: Subscription;
  private userSub: Subscription;
  searchItems: Item[] = [];

  isShown = true;
  common: Common = new Common();

  public sidenavOptions = {
    sidenavMode: 'side',
    disableClose: true,
    backdrop: false
  };
  public DaysOfWeek: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  public user: User;
  public operationHour: OperationHours;
  public openTime: string;
  public isClosed = true;
  public merchantInfo: Merchant;
  public categories: MenuCategory[] = [];
  public selectedCategory: string;
  public selectedSubCategory: Category;
  public categoryItems: ItemCacheRecord[];
  public searchText: string;
  public merchantAddress: string = "";
  public merchantAddresses: string[] = [];
  public CategoryButtonClicked = true;
  public cartModel: StoreCartModel;
  public authCheck: boolean;
  public resizeSidenav = true;
  public showToolbar = false;
  public windowWidth: number;
  public item: Item;
  public itemIdx: number;
  public updateFlag = false;
  public scheduleText: string;
  public modalItem: Item = new Item();
  public deliveryfee = '';
  public pickUpStatusText = '';
  public defaultOperationHours = [];
  public waittime = 0;
  routerEventSubscription: Subscription;
  public itemSubscription: Subscription;
  public smsSubscription: Subscription;
  currentUrl = '';
  businessRating = 0;
  businessRatings = 0;
  public merchantEmailSubscription: Subscription;

  ratingSubscription: Subscription;
  public orderTypes = [];
  public deliveryEnabled = false;

  public clickable = true;

  public locations: Merchant[] = [];
  latitude: any;
  longitude: any;

  public banner: Banner;
  loadingItem = false;
  authConfig: any;
  public dOrderStatus = '';
  public pOrderStatus = '';

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    public sideNavService: SideNavService,
    private merchantSvc: MerchantService,
    private emailService: EmailService,
    private configService: ConfigService,
    private _itemSvc: ItemService,
    private _cartSvc: StoreCartService,
    private _smsSvc: SmsService,
    private authSvc: AuthenticationService,
    private overlay: Overlay,
    private _loginDialog: MatDialog,
    private _registrationDialog: MatDialog,
    private _scheduleDialog: MatDialog,
    private _confirmDialog: MatDialog,
    private localStorage: StorageMap,
    public dialog: MatDialog,
    private storeInfoDialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public ratingsService: RatingsService,
    public sanitizer: DomSanitizer,
    private bannerService: BannerService
  ) {

    const lastNavigation = this.router.getCurrentNavigation();
    let lastRoute = this.router.routerState.snapshot.url;

    this.sidenavSubscription = this.sideNavService.state.subscribe((state: boolean) => {
      if ((lastNavigation.extras.state && lastNavigation.extras.state.url !== 'receipt') && this.screenWidth < 500) {
        this.sidenavState = state;
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url === '/' && (!event.urlAfterRedirects || event.urlAfterRedirects === '/')) {
          window.location.href = 'https://electronicpayments.com/services/deliverme';
        }
      });
  }

  @HostListener('window:load', ['event'])
  onLoad(event) {
    this.localStorage.delete('paymethod').subscribe(() => { });
  }

  @HostListener('window:resize', ['event'])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {

    $.getScript('../../assets/js/jquery.min.js');

    this.setCurrentLocation();

    /*     this.localStorage.delete('token');
        localStorage.removeItem('token'); */

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.mobileDetect = new MobileDetect(window.navigator.userAgent);

    this.sideNavService.open();

    const storename = this.aRoute.snapshot.params.storename;

    if (storename) {
      this.localStorage.set('storename', storename).subscribe(async () => {
        this.localStorage.get('user').subscribe(async (user: User) => {
          this.user = user;

          if (this.user && this.user.access_token) {
            this.authSvc.validateToken(this.user.access_token).subscribe((result: any) => {
              this._LoadMerchant(storename);
            }, async (error: any) => {
              // token isn't valid
              localStorage.removeItem('token');
              this.localStorage.delete('token').subscribe(() => { });
              this.localStorage.delete('user').subscribe(() => { });
              this.user.access_token = '';
              await this.authSvc.SetAuthStateAsync(this.user).catch((error: any) => {
                console.log(error);
              });

              await this.GuestLogin().catch((error: any) => {
                console.log(error);
              }).finally(() => {
                this._LoadMerchant(storename);
              });

            });
          } else {
            await this.GuestLogin().catch((error: any) => {
              console.log(error);
            }).finally(() => {
              this._LoadMerchant(storename);
            });
          }
        });
      });
    } else {
      this.router.navigate(['/pagenotfound']);
    }

    this.userSub = this.authSvc.CurrentUserEmitter.subscribe(async (u: User) => {
      this.user = u;

      if (u.access_token) {
        /* Validate the token and make sure it is still valid */
        this.authSvc.validateToken(u.access_token).subscribe(async (result: any) => {
          this.authCheck = await this.authSvc.isAuthenticated();
        }, async (error: any) => {
          // token isn't valid
          /*          console.log(error);
                   localStorage.removeItem('token');
                   this.localStorage.delete('token').subscribe(() => { });
                   this.localStorage.delete('user').subscribe(() => { });
                   this.user.access_token = ''; */
          // await this.authSvc.SetAuthStateAsync(this.user);

          await this.GuestLogin().catch((error: any) => {
            console.log(error);
          }).finally(() => {
            this._LoadMerchant(storename);
          });

        });
      } else {
        console.log('user', u);

        if(u && !u.email) {
          await this.GuestLogin().catch((error: any) => {
            console.log(error);
          }).finally(() => {
            this._LoadMerchant(storename);
          });
        }
      }

      this.authCheck = await this.authSvc.isAuthenticated();

      if (!this.authCheck) {
        this.sideNavService.close();
      }
    });

    this.itemCountSub = this._cartSvc.StoreCartModelEmitter.subscribe((model: StoreCartModel) => {
      this.cartModel = model;
      this._formatSchedule();
    });

    if (this.authSvc.CurrentUser.email) {
      this.user = this.authSvc.CurrentUser;
      this.authCheck = this.authSvc.Authenticated;

      if (!this.authCheck) {
        // logging in as a guest does not change the authcheck value, but it sets up the checkout component with the guest user
        this.authSvc.guestLogin();
      }
    } else {
      /*      this.localStorage.get('user').subscribe(async (user: User) => {
             if (user) {
               this.user = user;

               if (this.user && this.user.access_token) {
                 this.authSvc.validateToken(this.user.access_token).subscribe(async (result: any) => {
                   await this.authSvc.SetAuthStateAsync(user);
                   this.authCheck = await this.authSvc.isAuthenticated();
                 }, async (error: any) => {
                   // token isn't valid
                   localStorage.removeItem('token');
                   this.localStorage.delete('token').subscribe(() => { });
                   this.localStorage.delete('user').subscribe(() => { });
                   this.user.access_token = '';
                   await this.authSvc.SetAuthStateAsync(this.user);

                   await this.GuestLogin().catch((error: any) => {
                     console.log(error);
                   }).finally(() => {
                     this._LoadMerchant(storename);
                   });
                 });
               } else {
                 await this.GuestLogin().catch((error: any) => {
                   console.log(error);
                 }).finally(() => {
                   this._LoadMerchant(storename);
                 });
               }
             }
           }); */
    }
  }

  ngOnDestroy() {
    this.itemCountSub.unsubscribe();
    this.userSub.unsubscribe();
    this.sidenavSubscription.unsubscribe();
    if (this.smsSubscription) {
      this.smsSubscription.unsubscribe();
    }
  }

  public shoppingCartClicked(): void {
    try {
      this.sidenavState = !this.sidenavState;
    } catch (error) {
      console.log(error);
    }
  }

  public async getBanners(merchant: Merchant, pageno: number, lastObjectID: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let global = false;

        // console.log(merchant);

        /* Global Banners trump merchant banners */
        await this.bannerService.getGlobalBanners(merchant, pageno, '*').subscribe(async (results: any) => {
          if (results) {
            if (results.records && results.records.length > 0) {
              let banners = results.records.filter(b => b.enabled === true);

              if (banners.length > 0) {
                let activeBanners = banners.filter(item => {
                  let start = new Date(item.startDate);
                  let end = new Date(item.endDate);
                  return start <= new Date() && new Date() <= end;
                });

                if (activeBanners.length > 0) {
                  global = true;
                  return resolve(activeBanners[activeBanners.length - 1]);
                }
              }
            }

            if (!global) {
              await this.bannerService.getActiveBanners(merchant, pageno, '*').subscribe(async (result: any) => {
                if (result.records && result.records.length > 0) {
                  let banners = result.records.filter(b => b.enabled === true);

                  if (banners.length > 0) {
                    let activeBanners = banners.filter(item => {
                      let start = new Date(item.startDate);
                      let end = new Date(item.endDate);
                      return start <= new Date() && new Date() <= end;
                    });

                    if (activeBanners.length > 0) {
                      resolve(activeBanners[activeBanners.length - 1]);
                    } else {
                      return resolve(undefined);
                    }
                  }
                } else {
                  return resolve(undefined);
                }
              }, (error: any) => {
                // console.log(error.message);
                reject(undefined);
              });
            }
          } else {
            return resolve(undefined);
          }
        }, (error: any) => {
          // console.log(error.message);
          reject(undefined);
        });
      } catch (error) {
        // console.log(error);
        reject(undefined);
      }
    });
  }

  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {

      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      window.navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        // this.zoom = 15;
      }, (error) => {
        console.log(error);
      });
    }
  }

  public onHideCart(event: any) {
    this.hideCart = event;
  }

  public setActive(): boolean {
    return this.sidenavState;
  }

  reInit() {
    this.isShown = false;
    setTimeout(() => {
      this.isShown = true;
    }, 3000);
  }

  public backgroundImage(): string {
    if (this.merchantInfo) {
      return `url(${this.merchantInfo.backgroundImage})`;
    } else {
      if (this.locations && this.locations.length > 1) {
        return `url(${this.locations[0].backgroundImage})`;
      }
    }
  }

  public getItemImage(item: Item): string {
    if (item.image && item.image !== null) {
      return `url(${this.sanitizer.bypassSecurityTrustStyle(item.image)})`;
    } else {
      return `url()`;
    }
  }

  async getDrivingDistance(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        var directionsService = new google.maps.DirectionsService();
        directionsService.route(request, function (response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            resolve(response); // the distance in metres
          } else {
            // oops, there's no route between these two locations
            // every time this happens, a kitten dies
            // so please, ensure your address is formatted properly
            reject({ "message": "invalid route" });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  private _LoadMerchant(storename: string) {
    this.merchantSvc.getMerchantLocationsByRoute(storename).subscribe(async (locations: Merchant[]) => {

      let isBase = Boolean(locations.filter(b => b.baseRoute === storename).length - 1);

      this.locations = (isBase === false) ? locations.filter(b => b.routeName === storename) : locations;

      for (let i = 0; i < this.locations.length; i++) {
        this.locations[i].merchantAddress = ([
          this.capitalize(this.locations[i].address),
          this.capitalize(this.locations[i].city),
          this.locations[i].state.toUpperCase()].filter(s => {
            return s ? true : false;
          }).join(', ') + ` ${this.locations[i].zip}`);
      }

      if (this.locations.length === 1) {
        this.selectedMerchant(locations[0]);
      }

      this.calculateDistances();

      setTimeout(() => {
        document.querySelectorAll('oembed[url]').forEach(element => {
          // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
          // to discover the media.
          const anchor = document.createElement('a');

          anchor.setAttribute('href', element.getAttribute('url'));
          anchor.className = 'embedly-card';

          element.appendChild(anchor);
        });

      }, 2000);

    }, (e) => {
      console.log(e);
    }, () => {

    });
  }

  calculateDistances(): void {
    try {

      setTimeout(async () => {
        for (let i = 0; i < this.locations.length; i++) {

          try {

            let request = {
              origin: this.latitude + ',' + this.longitude, // a city, full address, landmark etc
              destination: this.locations[i].latitude + ',' + this.locations[i].longitude,
              travelMode: google.maps.DirectionsTravelMode.DRIVING
            };

            let distance = await this.getDrivingDistance(request).catch((error) => {
              console.log(error);
            })

            if (distance) {
              this.locations[i].distance = parseFloat(distance.routes[0].legs[0].distance.text.replace(',', '').replace('mi', '').trim());
            }

          } catch (error) {
            console.log(error);
          }
        }

        let locations = this.locations.sort(({ distance: a }, { distance: b }) => a - b);
        this.locations = locations;

      }, 0);
    } catch (error) {
      console.log(error);
    }
  }

  selectedMerchant(merchant: Merchant) {
    // this.merchantSvc.getMerchantByRoute(storename).pipe(first()).subscribe((r: Merchant) => {
    if (merchant && merchant._id) {

      this.merchantInfo = merchant;

      this.localStorage.set('merchant', merchant).subscribe(async () => {

        // create the cart for this merchant if it doesn't already exist:
        if (merchant.apiUrl && merchant.apiUrl.trim() !== '') {
          merchant.settings.taxrate = 0;
        }

        /* this.merchantInfo = merchant; */

        this._cartSvc.CreateCart(merchant);

        this.backgroundImage();
        this.getBusinessRating();

        merchant.orderOptions.forEach(o => {
          if (o.value === OrderTypeEnum.Delivery && o.enabled === true) {
            this.defaultOperationHours = merchant.settings.deliveryHours;
            this.waittime = merchant.settings.delWaittime;
            this.deliveryEnabled = true;
            if (merchant.apiUrl && merchant.apiUrl.trim() !== '' && merchant.settings.DeliveryZones !== null && merchant.settings.DeliveryZones.length > 0) {
              let min = 0;
              let isPercentage = false;
              let minZone = 0;

              // r.settings.DeliveryZones.forEach(zone => {
              //   const fee = parseFloat(zone.Fee);
              //   if (min === 0 || min > fee) {
              //     min = fee;
              //     isPercentage = zone.PercentOn;
              //   }
              // });

              merchant.settings.DeliveryZones.forEach(zone => {
                if (minZone >= zone.Start) {
                  minZone = zone.Start;
                  min = zone.Fee;
                  isPercentage = zone.PercentOn;
                }
              });

              if (this.deliveryEnabled === true) {
                if (!isPercentage) {
                  this.deliveryfee = '$' + min.toFixed(2) + ' Minimum Delivery Fee';
                } else {
                  this.deliveryfee = min.toFixed(2) + '% Minimum Delivery Fee';
                }
              }
              // } else {
              //   if (r.settings.deliveryfee.isPercentage) {
              //     this.deliveryfee = r.settings.deliveryfee.fee.toFixed(2) + '% Delivery Fee';
              //   } else {
              //     this.deliveryfee = '$' + r.settings.deliveryfee.fee.toFixed(2) + ' Delivery Fee';
              //   }
              // }
            } else {
              if (this.deliveryEnabled === true) {
                if (merchant.settings.deliveryfee.isPercentage) {
                  if (merchant.settings.deliveryfee && merchant.settings.deliveryfee.fee) {
                    this.deliveryfee = merchant.settings.deliveryfee.fee.toFixed(2) + '% Delivery Fee';
                  }
                } else {
                  if (merchant.settings.deliveryfee && merchant.settings.deliveryfee.fee) {
                    this.deliveryfee = '$' + merchant.settings.deliveryfee.fee.toFixed(2) + ' Delivery Fee';
                  }
                }
              }
            }
          }
        });

        this.orderTypes = merchant.orderOptions.filter(o => {
          if (o.enabled) {
            return o;
          }
        });

        // Just In Cases
        if (!this.deliveryEnabled) {
          this.deliveryEnabled = false;
          this.deliveryfee = 'Delivery Not Available';
          this.defaultOperationHours = merchant.settings.pickupHours;
          this.waittime = merchant.settings.pickupWaittime;
        }

        // Getting Operation Hours
        const date = new Date();
        const dayOfWeek = date.getDay(); // 0-6 -> Sunday - Saturday
        const currentTime = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        const current = Number(currentTime.replace(':', ''));

        merchant.orderOptions.forEach(o => {
          if (o.enabled === true) {
            let operationHour: OperationHours;
            if (o.value === OrderTypeEnum.Delivery) {
              operationHour = this.merchantInfo.settings.deliveryHours[dayOfWeek];
            } else {
              operationHour = this.merchantInfo.settings.pickupHours[dayOfWeek];
            }

            let openTime: any;
            let closeTime: any;
            let isClosed = false;
            let openTimeMessage = '';

            if (operationHour.openHour) {
              openTime = Number(operationHour.openHour.replace(':', '').replace('AM', '').replace('PM', ''));
              closeTime = Number(operationHour.closeHour.replace(':', '').replace('AM', '').replace('PM', ''));
            }

            if (operationHour && operationHour.openHour && operationHour.closeHour && operationHour.openHour.trim() !== '' && operationHour.closeHour.trim() !== '') {
              this.openTime = operationHour.openHour.trim() + ' - ' + operationHour.closeHour.trim();
              openTimeMessage = operationHour.openHour.trim() + ' - ' + operationHour.closeHour.trim();

              if ((operationHour.closeHour.indexOf('PM') !== -1 && operationHour.closeHour.substr(0, 2) !== '12')) { // from 1:00 PM to 12:00 AM
                closeTime += 1200; // Convert from 12 hours format to 24 hours format
              }
              if ((operationHour.openHour.indexOf('PM') !== -1 && operationHour.openHour.substr(0, 2) !== '12')) { // from 1:00 PM to 12:00 AM
                openTime += 1200; // Convert from 12 hours format to 24 hours format
              }

              if (operationHour.available === false || openTime >= closeTime || !this.merchantInfo.settings.Active) {
                this.openTime = '';
                openTimeMessage = '';
                this.isClosed = true;
                isClosed = true;
              } else if (current < openTime || current > closeTime) {
                // Closed
                this.isClosed = true;
                isClosed = true;
              } else {
                // Open
                this.isClosed = false;
                isClosed = false;
              }
            } else {
              this.openTime = '';
              openTimeMessage = '';
              this.isClosed = true;
              isClosed = true;
            }

            if (o.value === OrderTypeEnum.Delivery) {
              this.dOrderStatus = 'Delivery: ' + openTimeMessage + ' - ';
              if (isClosed) {
                this.dOrderStatus += 'Not Available at This Time.';
              } else if (o.suspended) {
                this.dOrderStatus += 'Temporary Suspended.';
              } else {
                this.dOrderStatus += merchant.settings.delWaittime + ' Minute Order Wait Time.';
              }
            } else {
              this.pOrderStatus = 'Pickup: ' + openTimeMessage + ' - ';
              if (isClosed) {
                this.pOrderStatus += 'Not Available at This Time.';
              } else if (o.suspended) {
                this.pOrderStatus += 'Temporary Suspended.';
              } else {
                this.pOrderStatus += merchant.settings.pickupWaittime + ' Minute Order Wait Time.';
              }
            }
          }
        });

        // this.operationHour = this.defaultOperationHours[dayOfWeek];

        // const current = Number(current_time.replace(':', ''));
        // let open_time: any;
        // let close_time: any;

        // if (this.operationHour.openHour) {
        //   open_time = Number(this.operationHour.openHour.replace(':', '').replace('AM', '').replace('PM', ''));
        //   close_time = Number(this.operationHour.closeHour.replace(':', '').replace('AM', '').replace('PM', ''));
        // }

        // if (this.operationHour && this.operationHour.openHour && this.operationHour.closeHour && this.operationHour.openHour.trim() !== '' && this.operationHour.closeHour.trim() !== '') {
        //   this.openTime = this.operationHour.openHour.trim() + ' - ' + this.operationHour.closeHour.trim();

        //   if ((this.operationHour.closeHour.indexOf('PM') !== -1 && this.operationHour.closeHour.substr(0, 2) !== '12') || this.operationHour.closeHour.trim() === '12:00 AM') { // from 1:00 PM to 12:00 AM
        //     close_time += 1200; // Convert from 12 hours format to 24 hours format
        //   }
        //   if ((this.operationHour.openHour.indexOf('PM') !== -1 && this.operationHour.openHour.substr(0, 2) !== '12') || this.operationHour.openHour.trim() === '12:00 AM') { // from 1:00 PM to 12:00 AM
        //     open_time += 1200; // Convert from 12 hours format to 24 hours format
        //   }

        //   if (open_time >= close_time || !this.merchantInfo.settings.Active) {
        //     this.openTime = '';
        //     this.isClosed = true;
        //   } else if (current < open_time || current > close_time) {
        //     // Closed
        //     this.isClosed = true;
        //   } else {
        //     // Open
        //     this.isClosed = false;
        //   }
        // } else {
        //   this.openTime = '';
        //   this.isClosed = true;
        // }

        this.merchantSvc.selectedMerchant(merchant); // update store-cart with selected merchant

        // format address:
        this.merchantAddress = ([
          this.capitalize(this.merchantInfo.address),
          this.capitalize(this.merchantInfo.city),
          this.merchantInfo.state.toUpperCase()].filter(s => {
            return s ? true : false;
          }).join(', ') + ` ${this.merchantInfo.zip}`);

        this._cartSvc.GetCart();
        // this._LoadAllItems();
        this._LoadFromServer();

        this.banner = await this.getBanners(merchant, 1, '').catch((error) => { // get banner should run independant of loading the menu.
          console.log(error);
        });
      });

    } else {
      // no merchant found, redirect to... 404?
      this.router.navigate(['/pagenotfound']).catch(e => {

      });
    }
    // }, (e) => {
    // error?
    //   console.log(e);
    // }, () => {
    // completed - do nothing?
    //  });
  }

  // this.selectLocationsByState(this.locations, 'state);

  getLocationStates(): any[] {
    try {
      return [...new Set(this.locations.map(l => l.state))];
    } catch (error) {
      console.log(error);
    }
  }

  public openStoreInfoDialog(): void {

    if (this.storeInfoDialog.openDialogs.length === 0) {
      const dref = this.storeInfoDialog.open(StoreInfoDialogComponent, {
        scrollStrategy: this.overlay.scrollStrategies.block(),
        panelClass: 'infoContainer',
        data: this.merchantInfo,
        disableClose: false,
        height: '85vh',
        minWidth: 'calc(30vw)',
        maxWidth: 600,
        width: '100vw',
        autoFocus: true
      });

      dref.afterClosed().pipe(first()).subscribe(() => {
      });

      dref.backdropClick().subscribe(() => {
        enableBodyScroll(document.querySelector('#body-content'));
      });
    }
  }

  public capitalize(s: string): string {
    let capitalized = '';
    const strings = s.split(' ');
    strings.forEach(w => {
      w = w[0].toUpperCase() + w.substr(1).toLowerCase();
      capitalized += w + ' ';
    });
    return capitalized.trim();
  }

  private _LoadFromServer() {
    this._itemSvc.getAllCategoriesItems(this.merchantInfo._id).subscribe(r => {
      // console.log(r);

      r.categories.forEach((c: MenuCategory) => {
        if (!c.isHidden && c.available && c.available.limitedTime) {
          const now = new Date();
          if (c.available.dateOfWeek[now.getDay()]) {
            const ps = c.available.timeRange.startTime.toUpperCase().replace('AM', '').replace('PM', '').trim().split(':');
            const ds = new Date().setHours(c.available.timeRange.startTime.toUpperCase().search('PM') <= 0 ? parseInt(ps[0], 10) : ps[0] === '12' ? parseInt(ps[0], 10) : parseInt(ps[0], 10) + 12, parseInt(ps[1], 10));

            const pe = c.available.timeRange.endTime.toUpperCase().replace('AM', '').replace('PM', '').trim().split(':');
            const de = new Date().setHours(c.available.timeRange.endTime.toUpperCase().search('PM') <= 0 ? parseInt(pe[0], 10) : pe[0] === '12' ? parseInt(pe[0], 10) : parseInt(pe[0], 10) + 12, parseInt(pe[1], 10));

            if (now.getTime() < ds || now.getTime() > de) {
              c.isHidden = true;

              r.items.forEach(i => {
                if (i.categoryid === c.categoryid) {
                  i.isHidden = true;
                }
              });
            }
          } else {
            c.isHidden = true;

            r.items.forEach(i => {
              if (i.categoryid === c.categoryid) {
                i.isHidden = true;
              }
            });
          }
        }
      });

      const i = {
        categories: r.categories.filter(c => !c.isHidden),
        items: r.items.filter(x => {
          if (x.available && x.available.limitedTime) {
            const now = new Date();
            if (x.available.dateOfWeek[now.getDay()]) {

              const timeStartString = x.available.timeRange.startTime.toUpperCase().replace('AM', '').replace('PM', '').trim().split(':');
              const timeStartDec = new Date().setHours(x.available.timeRange.startTime.toUpperCase().search('PM') <= 0 ? parseInt(timeStartString[0], 10) : timeStartString[0] === '12' ? parseInt(timeStartString[0], 10) : parseInt(timeStartString[0], 10) + 12, parseInt(timeStartString[1], 10));

              const timeEndString = x.available.timeRange.endTime.toUpperCase().replace('AM', '').replace('PM', '').trim().split(':');
              const timeEndDec = new Date().setHours(x.available.timeRange.endTime.toUpperCase().search('PM') <= 0 ? parseInt(timeEndString[0], 10) : timeEndString[0] === '12' ? parseInt(timeEndString[0], 10) : parseInt(timeEndString[0], 10) + 12, parseInt(timeEndString[1], 10));

              if (now.getTime() < timeStartDec && now.getTime() > timeEndDec) {
                x.isHidden = true;
              }
            } else {
              x.isHidden = true;
            }
          }
          if (x.active && !x.isHidden && x.labeledPrice !== '0.00') {
            return x;
          }
        }),
      };

      // let now = new Date().getTime();
      // now = now + 15 * 60 * 1000; // expires in an hour
      // now = now + 2 * 60 * 1000;
      // const iCache = { expires: now, list: i } as ItemCache;
      // this.localStorage.set(`${this.merchantInfo._id}.${KEYS.CATITEMKEY}`, iCache).subscribe();
      this._SetUpCatItems(i);
    });
  }

  private _SetUpCatItems(r: CatItem) {
    let sortByIndex = true;

    const mainCat = r.categories.filter(c => c.parentid === 0);

    mainCat.forEach(c => {
      const subCategories = r.categories.filter(s => s.parentid === c.categoryid).sort(this.common.compareValues('seqnum', 'asc'));
      c.subcategories = [];

      let subCat: ItemCacheRecord[] = [];

      subCategories.forEach(sc => {
        const subCatItem = r.items.filter(i => i.subcategoryid && i.subcategoryid === sc.categoryid && i.categoryid === sc.parentid);

        let newlist = [];
        if (c.sortAlphabetically) {
          newlist = subCatItem.sort(this.common.compareValues('title', 'asc'));
        } else {
          newlist = subCatItem.sort(this.common.compareValues('seqnum', 'asc'));
        }

        if (newlist.length > 0) {
          subCat.push({
            category: sc.title,
            categoryid: sc._id,
            subCategories: [],
            index: sc.Index,
            items: newlist,
          });

          c.subcategories.push(sc);
        }
      });

      if (!c.seqnum) {
        c.seqnum = 0;
      }

      if (sortByIndex && (!c.Index || c.Index === -1)) {
        sortByIndex = false;
      }

      const ilist = r.items.filter(i => i.categoryid === c.categoryid && (!c.subcategories || c.subcategories.length === 0 || !i.subcategoryid || i.subcategoryid === 0));
      let newlist = [];
      if (c.sortAlphabetically) {
        newlist = ilist.sort(this.common.compareValues('title', 'asc'));
      } else {
        newlist = ilist.sort(this.common.compareValues('seqnum', 'asc'));
      }

      if (newlist.length > 0 || subCat.length > 0) {
        this.itemCache.push({
          category: c.title,
          categoryid: c._id,
          subCategories: subCat,
          index: c.Index,
          items: newlist,
        });

        this.categories.push(c);
      }
    });

    // for (const cat of r.categories) {
    //   if (!cat.seqnum) {
    //     cat.seqnum = 0;
    //   }
    // }

    /* Sorting is handled by the api */
    // if (sortByIndex) {
    // this.categories = r.categories; // .sort((a, b) => a.Index > b.Index ? 1 : -1);
    // this.itemCache = this.itemCache; // .sort((a, b) => a.index > b.index ? 1 : -1);
    // } else {
    //  this.categories = r.categories; // .sort((a, b) => a.title > b.title ? 1 : -1);
    //  this.itemCache = this.itemCache; // .sort((a, b) => a.category > b.category ? 1 : -1);
    // }
    this.CategoryClicked('');
  }

  private _formatSchedule() {
    // console.log(this.cartModel.deliverytype);
    const v = new Date(this.cartModel.schedule.value);
    const dateFormatOptions = { dateStyle: 'medium' } as DateTimeFormatOptions;
    const timeFormatOptions = { hour: 'numeric', hour12: true, minute: '2-digit' } as DateTimeFormatOptions;
    this.scheduleText = (this.cartModel.schedule.text === ScheduleType.ASAP)
      ? 'ASAP'
      : `${v.toLocaleDateString('default', dateFormatOptions)} at ${v.toLocaleTimeString('default', timeFormatOptions)}`;
  }

  private _SetItemExtras() {
    // only grab the selected items for the cart to display:
    if (this.item.sides) {
      this.item.sides = this.item.sides.filter(i => i.selected);
    }

    if (this.item.options) {
      this.item.options = this.item.options.filter(i => i.selected);
    }

    if (this.item.modifiers) {
      this.item.modifiers = this.item.modifiers.filter(i => i.selected);
    }
  }

  public CategoryClicked(cid: string) {
    this.CategoryButtonClicked = true;
    this.selectedCategory = cid;
    this.categoryItems = null;
    // this.searchItems = [];
    const catItems = this.itemCache.filter(icr => {
      return cid === '' || icr.categoryid === cid;
    });
    if (catItems.length > 0) {
      this.categoryItems = catItems;
    }

    // console.log(this.categoryItems);
    // this.updateAutoComplete();
  }

  public SubCategoryClicked(mcid: string, cid: string) {
    this.CategoryButtonClicked = true;
    this.selectedCategory = mcid;
    this.categoryItems = null;
    // this.searchItems = [];
    const mainCat = this.itemCache.find(mc => mc.categoryid === mcid);
    const catItems = mainCat.subCategories.filter(icr => {
      return cid === '' || icr.categoryid === cid;
    });
    if (catItems.length > 0) {
      this.categoryItems = catItems;
    }

    // this.selectedSubCategory = c;
    // const catItems = this.itemCache.filter(icr => {
    //   return icr.categoryid === c._id;
    // });
    // if (catItems[0]) {
    //   this.categoryItems = catItems;
    //   // this._ScrollToCategory();
    // } else {
    //   this._itemSvc.getCategoryItems('s', `${c.categoryid}`, this.merchantInfo._id).subscribe((r: Item[]) => {
    //     /* Sorting is handled by api now */
    //     /* r = r.sort((a, b) => a.title > b.title ? 1 : -1); */
    //     const ic = {
    //       categoryid: c._id,
    //       category: c.title || c.desc,
    //       subCategories: [],
    //       index: c.Index,
    //       items: r
    //     };
    //     this.itemCache.push(ic);
    //     this.categoryItems = [ic];
    //     // this._ScrollToCategory();
    //   }, (e) => {
    //     // error
    //     console.warn(e);
    //   }, () => {
    //     // complete - do nothing
    //   });
    // }

    // this.updateAutoComplete();
  }

  public SearchAllItems() {
    this.selectedCategory = '';
    this.CategoryButtonClicked = false;
    const stlower = this.searchText.toLowerCase();
    const results: ItemCacheRecord[] = [];
    this.itemCache.forEach(ic => {
      const catResults = ic.items.filter(i => {
        return i.title.toLowerCase().indexOf(stlower) !== -1 || i.desc.toLowerCase().indexOf(stlower) !== -1;
      });
      if (catResults.length > 0) {
        results.push({
          category: ic.category,
          categoryid: ic.categoryid,
          subCategories: [],
          index: ic.index,
          items: catResults
        });
      }
    });

    this.categoryItems = results;
    this.updateAutoComplete();
  }

  private updateAutoComplete() {
    this.searchItems = [];
    this.categoryItems.forEach(ci => {
      ci.items.forEach(i => {
        this.searchItems.push(i);
      });
    });

    // console.log(this.searchItems);
  }

  public GetItemDetails(item: Item, idx: number = -1) {
    if (this.clickable) {
      this.loadingItem = true;

      this.clickable = !this.clickable;

      if (item.active && !item.item86) {
        if (this.merchantInfo.rmsType === 3 || !this.merchantInfo.apiUrl) {
          if (item.qtyOnHand > 0) {
            item.qty = 1;
            this.itemIdx = idx;
            this.updateFlag = idx !== -1;
            this.item = item;
            this.modalItem = item;

            if (item.ageVerificationRequired === true) {
              const restrictedRef = this._confirmDialog.open(ConfirmDialogComponent, {
                width: '50vw',
                data: {
                  message: item.ageVerificationText && item.ageVerificationText.trim() !== '' ? item.ageVerificationText : `This item is age restricted. I confirm that I am at least ` +
                    item.minimumAgeRequired + ` years old and I understand that a valid government issued id with age is required to be verified at time of Pickup/Delivery.`,
                  text: '', yesno: true, okonly: false,
                }
              });

              restrictedRef.afterClosed().pipe(first()).subscribe(r => {

                this.loadingItem = false;

                if (r && r === true) {
                  this.displayItemDetails(item);
                } else {
                  this.clickable = !this.clickable;
                }
              });
            } else {
              this.displayItemDetails(item);
            }
          } else {
            const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
              // width: '50vw',
              data: { message: `This item is out of stock. Please select another item!`, text: '', yesno: false, okonly: true, }
            });

            confirmRef.afterClosed().pipe(first()).subscribe(r => {
              // if (r && r === true) {
              //   this.displayItemDetails(item);
              // } else {
              this.clickable = !this.clickable;
              // }
            });
          }
        } else {
          this.itemSubscription = this._itemSvc.getItemsInventory(item.itemid.toString(), this.merchantInfo._id.toString()).subscribe((items: Item[]) => {

            this.loadingItem = false;

            if (items.length > 0) {
              item.qtyOnHand = items[0].qtyOnHand;
              item.lowLevelQty = items[0].lowLevelQty;
              item.inventoryTracked = items[0].inventoryTracked;
              item.maxqty = item.maxqty > items[0].qtyOnHand ? items[0].qtyOnHand : item.maxqty;
              item.item86 = items[0].Item86;
              // item.item86 = items[0].item86;
              item.onlyFor86 = items[0].onlyFor86;
              item.active = !items[0].item86 && (!items[0].inventoryTracked || items[0].qtyOnHand > 0);

              if (item.item86) {

                const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
                  // width: '50vw',
                  data: { message: `This item is currently not available. Please select another item!`, text: '', yesno: false, okonly: true, }
                });
              } else if (item.inventoryTracked) {
                if (item.qtyOnHand > 0) {
                  item.qty = 1;
                  this.itemIdx = idx;
                  this.updateFlag = idx !== -1;
                  this.item = item;
                  this.modalItem = item;

                  if (item.ageVerificationRequired === true) {
                    const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
                      width: '50vw',
                      data: {
                        message: item.ageVerificationText && item.ageVerificationText.trim() !== '' ? item.ageVerificationText : `This item is age restricted. I confirm that I am at least ` +
                          item.minimumAgeRequired + ` years old and I understand that a valid government issued id with age is required to be verified at time of Pickup/Delivery.`,
                        text: '', yesno: true, okonly: false,
                      }
                    });

                    confirmRef.afterClosed().pipe(first()).subscribe(r => {
                      if (r && r === true) {
                        this.displayItemDetails(item);
                      } else {
                        this.clickable = !this.clickable;
                      }
                    });
                  } else {
                    this.displayItemDetails(item);
                  }
                } else {
                  const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
                    // width: '50vw',
                    data: { message: `This item is out of stock. Please select another item!`, text: '', yesno: false, okonly: true, }
                  });

                  confirmRef.afterClosed().pipe(first()).subscribe(r => {
                    this.clickable = !this.clickable;
                  });
                }

                if (item.qtyOnHand < item.lowLevelQty) {
                  const message = {
                    userid: this.user._id,
                    merchantid: this.merchantInfo._id,
                    to_numbers: [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.phone) ? this.merchantInfo.contactInfo.phone : this.merchantInfo.phone],
                    storename: this.merchantInfo.businessName,
                    type: 0,
                    message: 'The quantity in stock for ' + item.title + ' are low'
                  };

                  if (!this.merchantInfo.contactInfo ||
                    this.merchantInfo.contactInfo.contactMethod === null ||
                    this.merchantInfo.contactInfo.contactMethod === undefined ||
                    this.merchantInfo.contactInfo.contactMethod === 1 ||
                    this.merchantInfo.contactInfo.contactMethod === 3) {
                    this.smsSubscription = this._smsSvc.sendMessage(message).subscribe();
                  }

                  if (!this.merchantInfo.contactInfo ||
                    this.merchantInfo.contactInfo.contactMethod === null ||
                    this.merchantInfo.contactInfo.contactMethod === undefined ||
                    this.merchantInfo.contactInfo.contactMethod > 1) {

                    const email: EmailOrderConfirmation = new EmailOrderConfirmation();
                    const template: any = {
                      items: []
                    };

                    template.items.push(item);

                    email.Source = 'support@deliverme.awsapps.com';
                    email.Template = 'low_quantity';
                    email.Destination.ToAddresses = [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.email) ? this.merchantInfo.contactInfo.email : this.merchantInfo.email];
                    email.Destination.CcAddresses = [];
                    email.TemplateData = JSON.stringify(template);

                    if (this.merchantEmailSubscription) {
                      this.merchantEmailSubscription.unsubscribe();
                    }

                    this.merchantEmailSubscription = this.emailService.sendEmailConfirmation(email).subscribe((res: any) => {
                    }, (err: any) => {
                      console.log(err);
                    });
                  }
                }
              } else if (items && items.length > 0) {
                this.itemIdx = idx;
                this.updateFlag = idx !== -1;
                this.item = item;
                this.modalItem = item;

                if (item.ageVerificationRequired === true) {
                  const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
                    width: '50vw',
                    data: {
                      message: item.ageVerificationText && item.ageVerificationText.trim() !== '' ? item.ageVerificationText : `This item is age restricted. I confirm that I am at least ` +
                        item.minimumAgeRequired + ` years old and I understand that a valid government issued id with age is required to be verified at time of Pickup/Delivery.`,
                      text: '', yesno: true, okonly: false,
                    }
                  });

                  confirmRef.afterClosed().pipe(first()).subscribe(r => {
                    if (r && r === true) {
                      this.displayItemDetails(item);
                    } else {
                      this.clickable = !this.clickable;
                    }
                  });
                } else {
                  this.displayItemDetails(item);
                }
              } else {
                this.displayItemDetails(item);
              }
            } else {
              item.active = false;
              item.labeledPrice = "Unavailable";
              const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
                // width: '50vw',
                data: { message: `This item is not available. Please select another item!`, text: '', yesno: false, okonly: true, }
              });

              confirmRef.afterClosed().pipe(first()).subscribe(r => {
                this.clickable = !this.clickable;
              });
            }
          }, (error: any) => {
            this.loadingItem = false;
            console.log(error);
            let txt = '';

            if (error.code && error.code === 'ETIMEDOUT') {
              txt = "Connection attempt timed out";
            } else {
              txt = error.code;
            }

            const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
              data: { message: `Error fetching item details from Point Of Sale`, text: txt, yesno: false, okonly: true, }
            });

          });
        }
      } else {
        this.loadingItem = false;

        const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
          // width: '50vw',
          data: { message: `This item is currently not available. Please select another item!`, text: '', yesno: false, okonly: true, }
        });

        confirmRef.afterClosed().pipe(first()).subscribe(r => {
          this.clickable = !this.clickable;
        });
      }
    }
  }

  public displayItemDetails(item: Item): void {
    try {
      // const cat = this.categories.find(c => c.categoryid === item.categoryid);
      item.qty = item.minqty ? item.minqty : 1;
      const category = this.categories.find(c => c.categoryid === item.categoryid);

      /* RMS Types
        0 - XT
        1 - Micros
        2 - Aloha
        3 - None
      */
      if (this.merchantInfo.rmsType === 3) {
        if (this.merchantInfo.printers) {
          const idx = this.merchantInfo.printers.findIndex(p => p.name === 'local_printer');
          item.printer = category.printer !== null && category.printer >= 0 ? category.printer : idx;
        }
      }

      const data = {
        xtMerchant: this.merchantInfo.apiUrl && this.merchantInfo.apiUrl.trim() !== '',
        noneRMS: this.merchantInfo.rmsType === 3,
        isRetail: this.merchantInfo.industryType === '6' || this.merchantInfo.industryType === 'retail',
        instructionEnabled: item.allowSpecialInstructions,
        instruction: item.specialInstruction ? item.specialInstruction : this.merchantInfo.settings.instruction,
        item,
        isUpdate: false,
        merchant: this.merchantInfo
      };

      const dref = this.dialog.open(ItemDetailsModalComponent, {
        data,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '800px',
        maxWidth: 650,
        panelClass: 'item-dialog-container'
      });

      dref.afterClosed().subscribe((i: Item) => {
        if (i) {
          this.item = i;
          this.Add();

          if (this.searchText !== '') {
            this.searchText = '';
            this.SearchAllItems();
          }
        }

        this.item.qty = this.item.minqty ? this.item.minqty : 1;
        this.item.sides = [];
        this.item.options = [];
        this.item.modifier_groups = [];
        this.item.modifiers = [];
        this.item.specialInstruction = '';
        this.item.comments = '';
        this.item = null;

        this.clickable = !this.clickable;

        return;
      });
    } catch (error) {
      console.log('displayItemDetails', error.message);
    }
  }

  public SetDeliveryType(value) {
    this._cartSvc.UpdateDeliveryType(value);
  }

  public UpdateItem(item: Item, idx: number) {
    if (item.qty === 0) {
      this.RemoveItem(idx);
    } else {
      this._SetItemExtras();
      this._cartSvc.UpdateItem(item, idx);
    }
  }

  public RemoveItem(idx: number) {
    const i = this.cartModel.itemList[idx];

    const confirmRef = this._confirmDialog.open(ConfirmDialogComponent, {
      data: { message: `Are you sure you want to remove ${i.title} from your cart?`, text: '' }
    });

    confirmRef.afterClosed().pipe(first()).subscribe(r => {
      if (r) {
        this._cartSvc.RemoveItem(idx);
      }
    });

  }

  public OpenLoginDialog() {
    // open popup with item details?
    const dialogRef = this._loginDialog.open(LoginDialogComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(r => {
      if (r === 'register') {
        this.OpenRegistrationDialog();
      }
    });
  }

  public OpenRegistrationDialog() {
    const dialogRef = this._registrationDialog.open(RegistrationDialogComponent, {
      minWidth: '300px',
      maxWidth: '600px'
    });
  }

  public Logout() {
    this.authSvc.logout();
  }

  public ScheduleDialog() {
    const dRef = this._scheduleDialog.open(ScheduleDialogComponent, {
      data: {
        schedule: this.cartModel.schedule,
        merchant: this.merchantInfo
      } as IScheduleDialogData,
      // minWidth: '300px',
      // maxWidth: '450px'
      width: '300px'
    });

    dRef.afterClosed().pipe(first()).subscribe((r: ISchedule) => {
      if (r) {
        this.cartModel.schedule = r;
        this._cartSvc.UpdateSchedule(this.cartModel.schedule);
        this._formatSchedule();
      }
    });
  }

  public ValidateItemQty(change: number = 0) {
    if (change !== 0) { this.item.qty += change; }
    this.item.qty = this.item.qty > this.item.maxqty ? this.item.maxqty : this.item.qty;
    this.item.qty = this.item.qty < 1 ? 1 : this.item.qty;
  }

  public Add() {
    try {
      this._SetItemExtras();
      // add the item:
      if (!this.item.taxPercent) {
        this.item.taxAmount = 0;
        this.item.taxPercent = 0;
      }

      // CAUSING DUPLICATE PRICE FOR MODIFERS DRIVEN ITEMS
      // if (this.item.price <= 0) {
      //   if (parseFloat(this.item.labeledPrice) > 0) {
      //     this.item.price = parseFloat(this.item.labeledPrice);
      //   }
      // }
      let category = this.categories.find((cat: MenuCategory) => cat.categoryid === this.item.categoryid);

      if (category) {
        this.item.categoryName = category.title;
      }

      this._cartSvc.AddItem(this.item);
    } catch (error) {
      console.log('store.component.ts error: ' + error.message);
    }
  }

  get sidesChosen() {
    return this.modalItem.sides.filter(s => s.selected).length;
  }

  get sidesPrice() {
    // i.sides.map(s => s.price).reduce((p, c) => { return p + (c || 0); }, 0);
    return this.modalItem.sides.filter((s) => s.selected).map(s => s.price).reduce((p, c) => p + (c || 0), 0);
  }

  getBusinessRating(): void {
    try {
      if (this.ratingSubscription) {
        this.ratingSubscription.unsubscribe();
      }

      this.ratingSubscription = this.ratingsService.getAverageRating(this.merchantInfo._id).subscribe((result: any) => {
        if (result && result.avgRating) {
          this.businessRating = parseFloat(result.avgRating.toFixed(2));
          this.businessRatings = result.ratings;
        }
      }, (error => {
        console.log(error.message);
      }));

    } catch (error) {
      console.log(error.message);
    }
  }

  public async GuestLogin(): Promise<User> {
    return new Promise((resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authSvc.guestLogin(credentials).subscribe(async (data) => {

          if (data && data.access_token) {
            this.user = data;
            // this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            this.localStorage.set('token', data.access_token).subscribe(() => { });
            this.localStorage.set('user', data).subscribe();
            await this.authSvc.SetAuthStateAsync(this.user);
            resolve(this.user);
          } else {
            // this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
