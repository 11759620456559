export class AgmMarker {
    public latitude = 0;
    public longitude = 0;
    public alpha = 0.4;

    constructor(latitude: number, longitude: number, alpha: number) {
        this.latitude = 0;
        this.longitude = 0;
        this.alpha = 0.4;
    }
}